import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(); // Hook para las traducciones

  return (
    <footer className="bg-back text-white py-10">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        {/* Sección de contenido principal */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          
          {/* Columna 1: Logo, nombre y slogan */}
          <div className="flex flex-col items-center md:items-start">
            <img src="/solologo.png" alt="Logo Finanbid" className="h-14 mb-3" />
            <h3 className="text-xl font-semibold">Finanbid</h3>
            <p className="text-sm text-gray-300">{t('footer.slogan')}</p>
          </div>

          {/* Columna 2 */}
          <div>
            <h3 className="text-lg font-semibold">{t('footer.about')}</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="/about" className="hover:underline">{t('footer.aboutUs')}</a></li>
              <li><a href="/contact" className="hover:underline">{t('footer.contact')}</a></li>
              <li><a href="/funds" className="hover:underline">{t('footer.fondos')}</a></li>
              <li><a href="/privacidad" className="hover:underline">{t('footer.privacidad')}</a></li>
            </ul>
          </div>

          {/* Columna 3 */}
          <div>
            <h3 className="text-lg font-semibold">{t('footer.resources')}</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="/prestamopromotor" className="hover:underline">{t('footer.promotor')}</a></li>
              <li><a href="/prestamopuente" className="hover:underline">{t('footer.puente')}</a></li>
              <li><a href="/prestamoflipping" className="hover:underline">{t('footer.flipping')}</a></li>
            </ul>
          </div>

          {/* Columna 4 */}
          <div>
            <h3 className="text-lg font-semibold">{t('footer.followUs')}</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="https://twitter.com/finanbid" target="_blank" rel="noopener noreferrer" className="hover:underline">Twitter</a></li>
              <li><a href="https://linkedin.com/company/finanbid" target="_blank" rel="noopener noreferrer" className="hover:underline">LinkedIn</a></li>
             
            </ul>
          </div>
        </div>

        {/* Línea divisoria */}
        <div className="border-t border-gray-400 my-6"></div>

        {/* Información legal y copyright */}
        <div className="text-center text-sm text-gray-300">
          <p>&copy; {new Date().getFullYear()} Finanbid. {t('footer.allRightsReserved')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
