import React, { useState, useEffect } from "react";
import AssetList from "./AssetList"; // Componente para listar activos
import AssetForm from "./AssetForm"; // Componente para crear/editar activos
import LTVCards from "./LTVCards"; // Componente para mostrar LTV
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Step3AssetDetails = ({ assets, projectId, ltv, onUpdate, errors }) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false); // Mostrar el formulario de activos
  const [editingAsset, setEditingAsset] = useState(null); // Activo que se está editando

  // Guardar un activo nuevo o editado
  const handleSaveAsset = (asset) => {
    const updatedAssets = editingAsset
      ? assets.map((a) => (a.id === asset.id ? asset : a))
      : [...assets, asset];

    // Llamar a la función `onUpdate` pasada desde el padre para actualizar los datos
    onUpdate({ assets: updatedAssets });
    setEditingAsset(null);
    setShowForm(false);
  };

  // Eliminar un activo
  const handleDeleteAsset = async (assetId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://api.finanbid.com/api/assets/${assetId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Error al eliminar el activo");
      }
  
      // Eliminar localmente si la solicitud fue exitosa
      const updatedAssets = assets.filter((asset) => asset.id !== assetId);
      onUpdate({ assets: updatedAssets });
    } catch (error) {
      console.error("Error al eliminar el activo:", error);
      alert("No se pudo eliminar el activo. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Contenido principal */}
      <div className="w-full lg:w-2/3 space-y-6">
        {/* LTV Cards */}
        {!showForm && <LTVCards ltv={ltv} />}

        {/* Lista de activos o formulario */}
        {showForm ? (
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 max-h-[80vh] overflow-y-auto">
            <AssetForm
              asset={editingAsset}
              projectId={projectId} // Pasar el project_id al AssetForm
              onSave={handleSaveAsset}
              onCancel={() => {
                setEditingAsset(null);
                setShowForm(false);
              }}
            />
          </div>
        ) : (
          <AssetList
            assets={assets || []} // Asegúrate de pasar un array vacío si no hay activos
            onEdit={(asset) => {
              setEditingAsset(asset);
              setShowForm(true);
            }}
            onDelete={handleDeleteAsset}
            onAddNew={() => setShowForm(true)}
          />
        )}

        {/* Errores */}
        {errors?.assets && <p className="text-red-500 text-sm mt-2">{errors.assets}</p>}
      </div>

      {/* Consejos */}
      <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
        <h4 className="text-md font-medium text-gray-700 flex items-center">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
          {t("tipTitle")}
        </h4>
        <p className="text-sm text-gray-600 mt-2">
          {t("step3Tip")
            .split("\n")
            .map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </p>
      </div>
    </div>
  );
};

export default Step3AssetDetails;


