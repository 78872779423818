import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const Tesis = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        ltv_max: '',
        amount_min: '',
        amount_max: '',
        products: [],
        sectors: [],
        subsectors: [],
        countries: [],
    });
    const [availableProducts, setAvailableProducts] = useState([]);
    const [availableSectors, setAvailableSectors] = useState([]);
    const [availableSubsectors, setAvailableSubsectors] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([
        { value: 'España', label: 'España' },
        { value: 'Portugal', label: 'Portugal' },
        { value: 'Alemania', label: 'Germany' },
        { value: 'UK', label: 'UK' },

    ]); // Países predefinidos o cargados dinámicamente
    const [loading, setLoading] = useState(false);

    // Cargar datos desde la API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('Token not found');
    
                const userId = parseInt(localStorage.getItem('userId'), 10);
                if (!userId) throw new Error('User ID not found');
    
                // Inicializar el formData vacío por defecto
                let thesisData = {
                    ltv_max: '',
                    amount_min: '',
                    amount_max: '',
                    products: [],
                    sectors: [],
                    subsectors: [],
                    countries: [],
                };
    
                try {
                    // Intentar obtener la tesis del fondo
                    const thesisResponse = await axios.get(`https://api.finanbid.com/api/investment-thesis/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
    
                    thesisData = thesisResponse.data;
    
                    // Actualizar el estado del formulario con los datos de la tesis si existe
                    setFormData({
                        ltv_max: thesisData.ltv_max || '',
                        amount_min: thesisData.amount_min || '',
                        amount_max: thesisData.amount_max || '',
                        products: thesisData.products.map((product) => ({
                            value: product.id,
                            label: product.name,
                        })),
                        sectors: thesisData.sectors.map((sector) => ({
                            value: sector.id,
                            label: sector.name,
                        })),
                        subsectors: thesisData.subsectors.map((subsector) => ({
                            value: subsector.id,
                            label: subsector.name,
                        })),
                        countries: thesisData.countries.map((country) => ({
                            value: country,
                            label: country,
                        })),
                    });
                } catch (error) {
                    // Si no hay tesis (404), continuar y cargar las opciones por defecto
                    if (error.response?.status === 404) {
                        console.warn('No thesis found, loading default options.');
                    } else {
                        throw error; // Lanzar otros errores
                    }
                }
    
                // Cargar productos, sectores y subsectores
                const [productsRes, sectorsRes, subsectorsRes] = await Promise.all([
                    axios.get('https://api.finanbid.com/api/allproducts', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get('https://api.finanbid.com/api/sectors', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get('https://api.finanbid.com/api/subsectors', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);
    
                setAvailableProducts(productsRes.data.map((product) => ({
                    value: product.id,
                    label: product.name,
                })));
                setAvailableSectors(sectorsRes.data.map((sector) => ({
                    value: sector.id,
                    label: sector.name,
                })));
                setAvailableSubsectors(subsectorsRes.data.map((subsector) => ({
                    value: subsector.id,
                    label: subsector.name,
                })));
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (selected, { name }) => {
        setFormData((prev) => ({
            ...prev,
            [name]: selected.map((option) => option.value), // Extraer valores seleccionados
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('Token not found');
    
            // Obtener el fund_id del fondo asociado al usuario
            const fundResponse = await axios.get('https://api.finanbid.com/api/fund-users/fund', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const fundId = fundResponse.data.fund_id;
    
            if (!fundId) throw new Error('Fund ID not found.');
    
            // Formatear los datos seleccionados
            const formattedData = {
                ...formData,
                products: formData.products.map((product) => product.value), // Extraer IDs de productos
                sectors: formData.sectors.map((sector) => sector.value), // Extraer IDs de sectores
                subsectors: formData.subsectors.map((subsector) => subsector.value), // Extraer IDs de subsectores
                countries: formData.countries.map((country) => country.value || country), // Manejar países como strings o valores
                fund_id: fundId, // Agregar fund_id
            };
    
            // Realizar la solicitud para guardar la tesis de inversión
            await axios.post(
                'https://api.finanbid.com/api/investment-thesis',
                formattedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            alert(t('investmentThesis.saved'));
        } catch (error) {
            console.error('Error saving investment thesis:', error);
            alert(t('investmentThesis.error'));
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="p-4">
            <h1 className="text-xl font-semibold mb-4">{t('investmentThesis.title')}</h1>
            <form onSubmit={handleSubmit} className="bg-white shadow-md p-4 rounded">
                <div className="mb-4">
                    <label className="block text-sm font-medium">{t('investmentThesis.ltvMax')}</label>
                    <input
                        type="number"
                        name="ltv_max"
                        value={formData.ltv_max}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded"
                    />
                </div>

                <div className="mb-4 grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium">{t('investmentThesis.amountMin')}</label>
                        <input
                            type="number"
                            name="amount_min"
                            value={formData.amount_min}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">{t('investmentThesis.amountMax')}</label>
                        <input
                            type="number"
                            name="amount_max"
                            value={formData.amount_max}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">{t('investmentThesis.products')}</label>
                    <Select
                        isMulti
                        options={availableProducts}
                        value={formData.products}
                        onChange={(selected) => setFormData((prev) => ({ ...prev, products: selected }))}
                        className="basic-multi-select"
                        placeholder={t('Seleccionar productos')}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">{t('investmentThesis.sectors')}</label>
                    <Select
                        isMulti
                        options={availableSectors}
                        value={formData.sectors}
                        onChange={(selected) => setFormData((prev) => ({ ...prev, sectors: selected }))}
                        className="basic-multi-select"
                        placeholder={t('Seleccionar sectores')}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">{t('investmentThesis.subsectors')}</label>
                    <Select
                        isMulti
                        options={availableSubsectors}
                        value={formData.subsectors}
                        onChange={(selected) => setFormData((prev) => ({ ...prev, subsectors: selected }))}
                        className="basic-multi-select"
                        placeholder={t('Seleccionar subsectores')}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">{t('investmentThesis.countries')}</label>
                    <Select
                        isMulti
                        options={availableCountries}
                        value={formData.countries}
                        onChange={(selected) => setFormData((prev) => ({ ...prev, countries: selected }))}
                        className="basic-multi-select"
                        placeholder={t('Seleccionar países')}
                    />
                </div>

                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                    {t('buttons.save')}
                </button>
            </form>
        </div>
    );
};

export default Tesis;
