import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.finanbid.com/api/userlist', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching user list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleRoleChange = (e) => {
    setRoleFilter(e.target.value);
  };

  const filteredUsers = users.filter((user) => {
    const matchesSearch =
      user.name.toLowerCase().includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm);
    const matchesRole = roleFilter ? user.role === roleFilter : true;
    return matchesSearch && matchesRole;
  });

  const handleUserClick = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const getRoleStyle = (role) => {
    switch (role) {
      case 'admin':
        return 'bg-blue-100';
      case 'promotor':
        return 'bg-green-100';
      case 'fondo':
        return 'bg-yellow-100';
      case 'colaborador':
        return 'bg-purple-100';
      default:
        return '';
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">{t('admin.Users.title')}</h2>

      {/* Filtros */}
      <div className="flex gap-4 mb-6">
        <input
          type="text"
          placeholder={t('admin.Users.searchPlaceholder')}
          value={searchTerm}
          onChange={handleSearchChange}
          className="border p-2 rounded w-1/2 text-sm"
        />
        <select
          value={roleFilter}
          onChange={handleRoleChange}
          className="border p-2 rounded w-1/4 text-sm"
        >
          <option value="">{t('admin.Users.allRoles')}</option>
          <option value="admin">{t('admin.Users.adminRole')}</option>
          <option value="promotor">{t('admin.Users.userRole')}</option>
          <option value="fondo">{t('admin.Users.fundRole')}</option>
          <option value="colaborador">{t('admin.Users.collaboratorRole')}</option>
        </select>
      </div>

      {/* Listado de usuarios */}
      {loading ? (
        <p>{t('admin.Users.loading')}</p>
      ) : (
        <table className="w-full border-collapse border border-gray-300 text-sm">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 p-2">Id.</th>
              <th className="border border-gray-300 p-2 w-24">{t('admin.Users.creationDate')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.name')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.email')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.role')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.projects')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.offers')}</th>
              <th className="border border-gray-300 p-2">{t('admin.Users.collaborator')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr
                key={user.id}
                className={`hover:bg-gray-200 cursor-pointer ${user.collaborator_name ? 'bg-purple-50' : ''}`}
                onClick={() => handleUserClick(user)}
              >
                <td className="border border-gray-300 p-2">{user.id}</td>
                <td className="border border-gray-300 p-2">
                  {new Date(user.created_at).toLocaleDateString('es-ES')}
                </td>
                <td className="border border-gray-300 p-2">{user.name}</td>
                <td className="border border-gray-300 p-2">{user.email}</td>
                <td className={`border border-gray-300 p-2 ${getRoleStyle(user.role)}`}>{user.role}</td>
                <td className="border border-gray-300 p-2">{user.projects_count}</td>
                <td className="border border-gray-300 p-2">{user.offers_count ?? '-'}</td>
                <td className="border border-gray-300 p-2">{user.collaborator_name || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Users;
