import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const HelpWidget = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleSendHelpMessage = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'admin@finanbid.com',
          subject: 'Contacto dashboard',
          html: `<p><strong>Consulta:</strong> ${helpMessage}</p>`,
        }),
      });

      if (response.ok) {
        setHelpMessage('');
        setIsPopupVisible(true);
      } else {
        console.error('Error al enviar el mensaje de ayuda.');
      }
    } catch (error) {
      console.error('Error al enviar el mensaje de ayuda:', error);
    }
  };

  const handleSendPhoneNumber = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'admin@finanbid.com',
          subject: 'Contacto dashboard',
          html: `<p><strong>Teléfono:</strong> ${phoneNumber}</p>`,
        }),
      });

      if (response.ok) {
        setPhoneNumber('');
        alert('Te contactaremos pronto.');
      } else {
        console.error('Error al enviar el teléfono.');
      }
    } catch (error) {
      console.error('Error al enviar el teléfono:', error);
    }
  };

  return (
    <>
      {/* Etiqueta de Ayuda */}
      <button
  onClick={() => setIsModalOpen(true)} // Agrega tu lógica para abrir un modal u otra acción
  className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-secondary text-white w-auto px-4 py-2 shadow-lg rounded-b-lg z-50 flex items-center space-x-2"
>
  {/* Icono de ayuda */}
  <span className="text-xl">
  <FontAwesomeIcon icon={faCircleQuestion} />{/* Usa Font Awesome o cualquier otra librería */}
  </span>

  {/* Texto */}
  <span className="text-sm font-medium">Ayuda</span>
</button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 shadow-xl relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">¿Cómo podemos ayudarte?</h2>

            {/* Input para consulta */}
            <textarea
              value={helpMessage}
              onChange={(e) => setHelpMessage(e.target.value)}
              rows="4"
              className="w-full border border-gray-300 rounded-lg p-2 mb-4"
              placeholder="Escribe tu consulta aquí..."
            ></textarea>
            <button
              onClick={handleSendHelpMessage}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 float-right"
            >
              Enviar
            </button>

            {/* Popup */}
            {isPopupVisible && (
              <div className="mt-4 p-4 bg-green-100 border border-green-300 rounded">
                <p>Tu mensaje se ha enviado correctamente.</p>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                  onClick={() => (window.location.href = '/dashboard')}
                >
                  Ok
                </button>
              </div>
            )}

            {/* Sección para el teléfono */}
            <h3 className="text-xl font-bold mt-6">¿Prefieres que te llamemos?</h3>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2 my-2"
              placeholder="Escribe tu número de teléfono..."
            />
            <button
              onClick={handleSendPhoneNumber}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpWidget;
