import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LandPurchaseDetails from './LandPurchaseDetails'; // Detalles para compra de suelo
import BridgeLoanDetails from './BridgeLoanDetails'; // Detalles para préstamo puente
import DeveloperLoanDetails from './DeveloperLoanDetails';
import FlippingLoanDetails from './FlippingLoanDetails';

const Step3Details = ({ projectId, nextStep }) => {
  const { t } = useTranslation();
  const [productType, setProductType] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Llamada a la API para obtener los detalles del proyecto
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(`https://api.finanbid.com/api/project/${projectId}`);
        const data = await response.json();
        
        if (response.ok) {
          setProductType(data.product_type);
        } else {
          setError(data.message || 'Error al obtener los detalles del proyecto');
        }
      } catch (err) {
        setError('Error en la llamada a la API');
        console.error('Error al obtener los detalles del proyecto:', err);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  // Determinar qué formulario cargar según el tipo de producto
  const renderDetailsForm = () => {
    switch (productType) {
      case 1: // Compra de Suelo
        return <LandPurchaseDetails projectId={projectId} onNextStep={nextStep} />;
      case 4: // Préstamo Puente
        return <BridgeLoanDetails projectId={projectId} onNextStep={nextStep} />;
        case 5: // Préstamo Puente Empresarial
        return <BridgeLoanDetails projectId={projectId} onNextStep={nextStep} />;
        case 6: // Préstamo Promotor
        return <DeveloperLoanDetails projectId={projectId} onNextStep={nextStep} />;
        case 7: // Préstamo Flipping
        return <FlippingLoanDetails projectId={projectId} onNextStep={nextStep} />;
      // Otros casos para diferentes tipos de proyecto
      default:
        return <p>{t('projectDetails.unknownProjectType')}</p>;
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="flex flex-col md:flex-row justify-between bg-back">
      

      {/* Columna derecha: Formulario */}
      <div className="w-full">
        {productType ? renderDetailsForm() : <p>{t('projectDetails.loading')}</p>}
      </div>
    </div>
  );
};

export default Step3Details;
