import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DocumentsViewer = ({ documents, onUpdateDocumentStatus }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [documentList, setDocumentList] = useState(documents || []);

  useEffect(() => {
    setDocumentList(documents);
  }, [documents]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleStatusChange = async (documentId, newStatus) => {
    try {
      await onUpdateDocumentStatus(documentId, newStatus);

      // Actualizar el estado local para reflejar el nuevo status
      setDocumentList((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc.id === documentId ? { ...doc, verification_status: newStatus } : doc
        )
      );
    } catch (error) {
      console.error(`Error updating status for document ${documentId}:`, error);
    }
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex justify-between items-center cursor-pointer" onClick={handleToggle}>
        <h2 className="text-lg font-semibold">{t('documentsViewer.title')}</h2>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </div>

      {expanded && (
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4 mb-2 font-semibold text-gray-600">
            <span>{t('documentsViewer.documentType')}</span>
            <span>{t('documentsViewer.viewDocument')}</span>
            <span>{t('documentsViewer.verificationStatus')}</span>
          </div>

          {documentList.map((doc) => (
            <div key={doc.id} className="grid grid-cols-3 gap-4 items-center py-2 border-b border-gray-200">
              <span>{doc.document_type}</span>
              <a
                href={doc.document_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-bold"
              >
                {t('documentsViewer.view')}
              </a>
              <select
                value={doc.verification_status}
                onChange={(e) => handleStatusChange(doc.id, e.target.value)}
                className={`border rounded p-1 text-sm ${
                  doc.verification_status === 'Pending'
                    ? 'text-orange-500'
                    : doc.verification_status === 'Approved'
                    ? 'text-green-500'
                    : 'text-red-500'
                }`}
              >
                <option value="Pending">{t('documentsViewer.statuses.Pending')}</option>
                <option value="Approved">{t('documentsViewer.statuses.Approved')}</option>
                <option value="Failed">{t('documentsViewer.statuses.Failed')}</option>
              </select>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DocumentsViewer;

