import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const ProjectOverview = ({ projectData, onUpdateField }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subsectors, setSubsectors] = useState([]);
  const [formData, setFormData] = useState({
    ...projectData,
  });
const projectId = formData.id;
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/admin/products');
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchSectors = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/sectors');
        const data = await response.json();
        setSectors(data);
      } catch (error) {
        console.error('Error fetching sectors:', error);
      }
    };

    const fetchSubsectors = async (sectorId) => {
      try {
        const response = await fetch(`https://api.finanbid.com/api/subsectors?sector_id=${sectorId}`);
        const data = await response.json();
        setSubsectors(data);
      } catch (error) {
        console.error('Error fetching subsectors:', error);
      }
    };

    fetchProducts();
    fetchSectors();

    if (formData.sector_id) {
      fetchSubsectors(formData.sector_id);
    }
  }, [formData.sector_id]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));

    // Si cambia el sector, limpiar subsector y cargar los subsectores del nuevo sector
    if (field === 'sector_id') {
      setFormData((prevData) => ({ ...prevData, subsector_id: '' }));
      fetch(`https://api.finanbid.com/api/subsectors?sector_id=${value}`)
        .then((response) => response.json())
        .then((data) => setSubsectors(data))
        .catch((error) => console.error('Error fetching subsectors:', error));
    }
  };
  const updateProjectStatus = async (projectId, status) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Token no encontrado.');
  
      await axios.patch(
        `https://api.finanbid.com/api/projects/${projectId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log(`Estado del proyecto ${projectId} actualizado a ${status}`);
    } catch (error) {
      console.error(`Error al actualizar el estado del proyecto ${projectId}:`, error);
      throw error;
    }
  };
  const updateProjectField = async (projectId, field, value) => {
    const token = localStorage.getItem('token');
    await axios.patch(
      `https://api.finanbid.com/api/projects/${projectId}`,
      { [field]: value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const handleBlur = async (field, value) => {
    console.log(`handleBlur disparado: campo=${field}, valor=${value}`);
    try {
        if (field === 'status') {
            console.log(`Actualizando estado del proyecto a: ${value}`);

            // Cambiar el estado del proyecto en la base de datos
            await handleBlurOtros(field, value);

            // Si el nuevo estado es 'Visible', realiza la lógica adicional
            if (value === 'Visible') {
                console.log('Estado cambiado a Visible, comprobando tesis...');
                
                // Añadir un pequeño retraso para asegurar que el cambio de estado se refleja en la base de datos
                await new Promise((resolve) => setTimeout(resolve, 500)); // 500 ms de retraso

                const token = localStorage.getItem('token');
                const response = await axios.get(`https://api.finanbid.com/api/thesis-matches/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const matchedFunds = response.data;

                // Enviar emails a fondos coincidentes
                await Promise.all(
                    matchedFunds.map(async (fund) => {
                        await axios.post(
                            'https://api.finanbid.com/api/projects/send-email-with-template',
                            {
                                to: fund.email,
                                templateId: 5, // ID del template que creamos
                                variables: {
                                    projectId: projectId,
                                    productType: t(`projectUpload.products.${formData.product_type}`),
                                    provincia: formData.provincia,
                                    amountRequested: formData.amount_requested,
                                    sector: t(`projectUpload.sectors.${formData.sector_id}`),
                                    subsector: t(`projectUpload.subsectors.${formData.subsector_id}`),
                                },
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                    })
                );

                alert(t('emails.newProject.success'));
            }
        } else {
            // Si el campo no es 'status', guardar otros cambios
            await handleBlurOtros(field, value);
        }
    } catch (error) {
        console.error(`Error al manejar el campo ${field}:`, error);
        alert(t('common.error'));
    }
};

  const handleBlurOtros = (field, value) => {
    if (onUpdateField) {
      onUpdateField(field, value);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">{t('projectOverview.title')}</h2>
        <button onClick={toggleOpen} className="focus:outline-none">
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
        </button>
      </div>

      {isOpen && (
        <>
          {/* Project ID and User ID - read-only */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.id')}</label>
              <p className="text-gray-800 text-sm">{formData.id}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.user_id')}</label>
              <p className="text-gray-800 text-sm">{formData.user_id}</p>
            </div>
          </div>

          {/* Product Type and Status - dropdowns */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.product_type')}</label>
              <select
                value={formData.product_type}
                onChange={(e) => handleChange('product_type', e.target.value)}
                onBlur={() => handleBlur('product_type', formData.product_type)}
                className="w-full border rounded-sm p-2 text-sm"
              >
                {products.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.status')}</label>
              <select
  value={formData.status}
  onChange={(e) => handleChange('status', e.target.value)}
  onBlur={() => handleBlur('status', formData.status)} // Asegúrate de que aquí se pase correctamente
  className={`w-full border rounded-sm p-2 text-sm ${
    formData.status === 'Visible'
      ? 'text-green-600'
      : formData.status === 'Finalizado'
      ? 'text-blue-600'
      : formData.status === 'Cancelado'
      ? 'text-red-600'
      : 'text-yellow-600'
  }`}
>
  {['Incompleto', 'En Revision', 'En Modificación', 'Visible', 'Finalizado', 'Cancelado'].map((status) => (
    <option key={status} value={status}>
      {t(`projectOverview.statuses.${status}`)}
    </option>
  ))}
</select>
            </div>
          </div>

          {/* Sector and Subsector Dropdowns */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.sector')}</label>
              <select
                value={formData.sector_id || ''}
                onChange={(e) => handleChange('sector_id', e.target.value)}
                onBlur={() => handleBlur('sector_id', formData.sector_id)}
                className="w-full border rounded-sm p-2 text-sm"
              >
                <option value="">{t('projectOverview.selectSector')}</option>
                {sectors.map((sector) => (
                  <option key={sector.id} value={sector.id}>
                    {sector.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.subsector')}</label>
              <select
                value={formData.subsector_id || ''}
                onChange={(e) => handleChange('subsector_id', e.target.value)}
                onBlur={() => handleBlur('subsector_id', formData.subsector_id)}
                className="w-full border rounded-sm p-2 text-sm"
                disabled={!formData.sector_id}
              >
                <option value="">{t('projectOverview.selectSubsector')}</option>
                {subsectors.map((subsector) => (
                  <option key={subsector.id} value={subsector.id}>
                    {subsector.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Amount Requested and Invested Capital */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.amount_requested')}</label>
              <input
                type="number"
                value={formData.amount_requested}
                onChange={(e) => handleChange('amount_requested', e.target.value)}
                onBlur={() => handleBlur('amount_requested', formData.amount_requested)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.invested_capital')}</label>
              <input
                type="number"
                value={formData.invested_capital}
                onChange={(e) => handleChange('invested_capital', e.target.value)}
                onBlur={() => handleBlur('invested_capital', formData.invested_capital)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.url_imagen_principal')}</label>
              <input
                type="text"
                value={formData.url_imagen_principal}
                onChange={(e) => handleChange('url_imagen_principal', e.target.value)}
                onBlur={() => handleBlur('url_imagen_principal', formData.url_imagen_principal)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
          </div>

          {/* Location Section */}
          <div className="mb-4 border p-4 rounded-lg shadow-sm bg-gray-50">
            <h3 className="text-md font-semibold mb-2">{t('projectOverview.location')}</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-600">{t('projectOverview.direccion')}</label>
                <input
                  type="text"
                  value={formData.direccion}
                  onChange={(e) => handleChange('direccion', e.target.value)}
                  onBlur={() => handleBlur('direccion', formData.direccion)}
                  className="w-full border rounded-sm p-2 text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">{t('projectOverview.provincia')}</label>
                <input
                  type="text"
                  value={formData.provincia}
                  onChange={(e) => handleChange('provincia', e.target.value)}
                  onBlur={() => handleBlur('provincia', formData.provincia)}
                  className="w-full border rounded-sm p-2 text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">{t('projectOverview.localidad')}</label>
                <input
                  type="text"
                  value={formData.localidad}
                  onChange={(e) => handleChange('localidad', e.target.value)}
                  onBlur={() => handleBlur('localidad', formData.localidad)}
                  className="w-full border rounded-sm p-2 text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">{t('projectOverview.pais')}</label>
                <input
                  type="text"
                  value={formData.pais}
                  onChange={(e) => handleChange('pais', e.target.value)}
                  onBlur={() => handleBlur('pais', formData.pais)}
                  className="w-full border rounded-sm p-2 text-sm"
                />
              </div>
            </div>
          </div>

          {/* Latitude and Longitude */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.latitud')}</label>
              <input
                type="number"
                value={formData.latitud}
                onChange={(e) => handleChange('latitud', e.target.value)}
                onBlur={() => handleBlur('latitud', formData.latitud)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">{t('projectOverview.longitud')}</label>
              <input
                type="number"
                value={formData.longitud}
                onChange={(e) => handleChange('longitud', e.target.value)}
                onBlur={() => handleBlur('longitud', formData.longitud)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
          </div>

          {/* Description, Surroundings, and Summary */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('projectOverview.ubicacion_descripcion')}</label>
            <textarea
              value={formData.ubicacion_descripcion}
              onChange={(e) => handleChange('ubicacion_descripcion', e.target.value)}
              onBlur={() => handleBlur('ubicacion_descripcion', formData.ubicacion_descripcion)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('projectOverview.ubicacion_entorno')}</label>
            <textarea
              value={formData.ubicacion_entorno}
              onChange={(e) => handleChange('ubicacion_entorno', e.target.value)}
              onBlur={() => handleBlur('ubicacion_entorno', formData.ubicacion_entorno)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('projectOverview.resumen')}</label>
            <textarea
              value={formData.resumen}
              onChange={(e) => handleChange('resumen', e.target.value)}
              onBlur={() => handleBlur('resumen', formData.resumen)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectOverview;



