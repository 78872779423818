// LegalEntity.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import EditLegalEntityModal from './EditLegalEntityModal';

const LegalEntity = ({ legalEntity = {}, projectId, onSave }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  // Verifica si la entidad tiene todos los datos necesarios
  useEffect(() => {
    const entity = legalEntity.entity || {}; // Asegúrate de que `entity` esté definido
    const requiredFields = [
      'business_name', 'business_cif', 'business_address', 'incorporation_pdf',
      'business_manager_name', 'last_tax_pdf', 'year_of_incorporation'
    ];

    const missingFields = requiredFields.some(field => !entity[field]);
    const hasAdmins = legalEntity.administrators && legalEntity.administrators.length > 0;
    const hasShareholders = legalEntity.shareholders && legalEntity.shareholders.length > 0;

    // Completa solo si todos los campos requeridos están y tiene administradores y accionistas
    setIsComplete(!missingFields && hasAdmins && hasShareholders);
  }, [legalEntity]);
  
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSave = (updatedEntity) => {
    if (onSave) {
      onSave(updatedEntity);
    }
    handleCloseModal();
  };

  const entity = legalEntity.entity || {}; // Asegúrate de que `entity` esté definido

  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-4">{t('Entidad asociada al proyecto')}</h3>

      {entity.business_name && entity.business_cif ? (
        <div className="mb-4">
          <div className="text-sm text-gray-600">
            <strong>{t('Nombre de la empresa')}:</strong> {entity.business_name}
          </div>
          <div className="text-sm text-gray-600">
            <strong>{t('CIF')}:</strong> {entity.business_cif}
          </div>
        </div>
      ) : (
        <p>{t('No hay una empresa asociada a este proyecto.')}</p>
      )}

      <button
        onClick={handleOpenModal}
        className="bg-secondary text-white rounded-lg px-4 py-2 mt-4 hover:bg-back"
      >
        <FontAwesomeIcon icon={isComplete ? faEdit : faPlus} className="mr-2" />
        {isComplete ? t('Editar datos de la empresa') : t('Completar datos de la empresa')}
      </button>

      {isModalOpen && (
        <EditLegalEntityModal
          legalEntity={legalEntity}
          projectId={projectId}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default LegalEntity;
