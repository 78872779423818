import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faCheck, faTimes, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const Documents = ({ documents, onUploadDocument, projectId }) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [loading, setLoading] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      return format(new Date(dateString), 'dd/MM/yy');
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return "";
    }
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    setSelectedFile(null);
    setDocumentType("");
    setLoading(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile || !documentType || !projectId) {
        alert("Selecciona un documento, especifica el tipo y verifica el ID del proyecto.");
        return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('project_id', projectId);
    formData.append('document_type', documentType);

    try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.finanbid.com/api/upload-document', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Error al subir el documento');
        }

        const data = await response.json();
        onUploadDocument(data); // Envía el documento completo al callback
        closeUploadModal();
    } catch (error) {
        console.error('Error al subir el documento:', error);
        setLoading(false);
    }
  };

  const getStatusIcon = (status) => {
    if (status === 'ok') return <FontAwesomeIcon icon={faCheck} className="text-green-500" />;
    if (status === 'no valido') return <FontAwesomeIcon icon={faTimes} className="text-red-500" />;
    return <FontAwesomeIcon icon={faClock} className="text-orange-500" />;
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">Documentos del Proyecto</h3>
        <button
          onClick={openUploadModal}
          className="text-blue-500 bg-white rounded-full p-1 hover:bg-blue-500 hover:text-white"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-x-4">
        {documents.map((doc, index) => (
          <div
            key={doc.id}
            className={`flex items-center py-2 text-sm space-x-4 ${
              index % 2 === 0 && index + 2 < documents.length ? 'border-b border-gray-300' : ''
            }`}
          >
            <span className="w-20 text-gray-600">{formatDate(doc.uploaded_at)}</span>
            <span className="flex-1 font-medium text-gray-800 truncate">{doc.document_type}</span>
            <button onClick={() => window.open(doc.document_url, '_blank')} className="mr-2 text-blue-500">
              <FontAwesomeIcon icon={faEye} />
            </button>
            {getStatusIcon(doc.verification_status)}
          </div>
        ))}
      </div>

      {uploadModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Subir Documento</h3>
            <input type="file" onChange={handleFileChange} className="mb-4" />
            <label className="block text-sm font-medium mb-2">Tipo de Documento</label>
            <input
              type="text"
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value)}
              className="w-full p-2 border rounded mb-4"
              placeholder="Especifica el tipo de documento"
            />
            <div className="mt-4 flex justify-end items-center">
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin className="text-secondary mr-4 text-xl" />
              ) : (
                <>
                  <button onClick={closeUploadModal} className="text-gray-600 mr-4">Cancelar</button>
                  <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded">Subir</button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;
