import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";

const CollateralForm = ({ collateral, projectId, onSave, onCancel }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    tipo_garantia: "",
    tipo_activo: "",
    valor_tasacion: "",
    valor_compraventa: "",
    libre_de_cargas: true,
    embargos: "",
    valor_embargos: "",
    numero_hipotecas: "",
    valor_hipotecas: "",
    numero_acciones: "",
    valor_acciones: "",
    porcentaje_participacion: "",
    tipo_pignoracion: "",
    pignoracion: "",
    derecho_cobro: "",
    tipo_derecho_cobro: "",
    entidad_pagadora: "",
    fecha_estimada_pago: "",
    valor_total_garantia: "",
    description: "",
    registro_propiedad: "",
    referencia_catastral: "",
    titular: "",
    finca: "",
    tomo: "",
    folio: "",
    alta: "",
    documentos: [],
  });

  const [newDocument, setNewDocument] = useState({ type: "", file: null });

  useEffect(() => {
    if (collateral) {
      setFormData({
        ...collateral,
        alta: collateral.alta ? collateral.alta.split("T")[0] : "", // Extraer solo la fecha
        documentos: collateral.documents || [],
      });
    }
  }, [collateral]);

  const calculateTotalGuaranteeValue = () => {
    if (["Pignoración", "Derechos de Cobro"].includes(formData.tipo_garantia)) {
      return parseFloat(formData.valor_total_garantia) || 0;
    }
    const valorTasacion = parseFloat(formData.valor_tasacion) || 0;
    const valorAcciones = parseFloat(formData.valor_acciones) || 0;
    const valorEmbargos = parseFloat(formData.valor_embargos) || 0;
    const valorHipotecas = parseFloat(formData.valor_hipotecas) || 0;

    return valorTasacion + valorAcciones - valorEmbargos - valorHipotecas;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
      valor_total_garantia: calculateTotalGuaranteeValue(),
    }));
  };

  const handleSave = async () => {
    try {
      if (!collateral) {
        // Crear un nuevo colateral
        const requestBody = {
          ...formData,
          project_id: projectId,
        };
  
        const response = await fetch("https://api.finanbid.com/api/add_collateral", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error("Error al guardar la garantía");
        }
  
        const savedCollateral = await response.json();
  
        // Subir documentos asociados si hay documentos nuevos
        if (formData.documentos && formData.documentos.some((doc) => doc.file)) {
          await uploadDocuments(savedCollateral.id);
        }
  
        // Pasar el colateral guardado al componente padre
        onSave(savedCollateral);
      } else {
        // Actualizar colateral existente
        const fieldsToUpdate = Object.keys(formData).filter(
          (key) => key !== "documentos" && formData[key] !== collateral[key]
        );
  
        for (const field of fieldsToUpdate) {
          const response = await fetch(
            `https://api.finanbid.com/api/admin/projects/${projectId}/collateral/${collateral.id}/field`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ field, value: formData[field] }),
            }
          );
  
          if (!response.ok) {
            throw new Error(`Error al actualizar el campo ${field}`);
          }
        }
  
        // Subir documentos asociados si hay documentos nuevos
        if (formData.documentos && formData.documentos.some((doc) => doc.file)) {
          await uploadDocuments(collateral.id);
        }
  
        // Pasar el colateral actualizado al componente padre
        onSave({ ...collateral, ...formData });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  
  
  // Función para subir documentos
  const uploadDocuments = async (collateralId) => {
    try {
      for (const doc of formData.documentos) {
        if (doc.file) {
          const formDataDoc = new FormData();
          formDataDoc.append("document", doc.file);
          formDataDoc.append("document_type", doc.type);
          formDataDoc.append("collateral_id", collateralId);
          formDataDoc.append("uploaded_by", localStorage.getItem("userId")); // Cambiar si es necesario
  
          const docResponse = await fetch("https://api.finanbid.com/api/upload-collateral-document", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formDataDoc,
          });
  
          if (!docResponse.ok) {
            throw new Error("Error al subir un documento");
          }
        }
      }
    } catch (error) {
      console.error("Error al subir documentos:", error);
    }
  };
  
  

  const addDocument = () => {
    if (newDocument.file && newDocument.type) {
      setFormData((prev) => ({
        ...prev,
        documentos: [...prev.documentos, newDocument],
      }));
      setNewDocument({ type: "", file: null });
    }
  };

  const removeDocument = (index) => {
    setFormData((prev) => ({
      ...prev,
      documentos: prev.documentos.filter((_, i) => i !== index),
    }));
  };

  const renderFloatingLabelInput = (name, label, type = "text", additionalProps = {}) => (
    <div className="relative z-0 w-full group">
      <input
        type={type}
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        className="block py-2.5 px-0 w-full text-sm text-gray-900 border-0 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer focus:bg-gray-50"
        placeholder=" "
        {...additionalProps}
      />
      <label
        htmlFor={name}
        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:text-blue-600 peer-focus:translate-y-[6px] peer-focus:scale-75"
      >
        {label}
      </label>
    </div>
  );

  return (
    <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 shadow-md text-sm">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">
        {collateral ? t("editCollateralTitle") : t("addCollateralTitle")}
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Dropdown para Tipo de Garantía */}
        <div>
          <label className="block text-sm font-medium mb-1">{t("collateralType")}</label>
          <select
            name="tipo_garantia"
            value={formData.tipo_garantia}
            onChange={handleChange}
            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
          >
            <option value="">{t("selectOption")}</option>
            <option value="Garantía Hipotecaria">{t("mortgageGuarantee")}</option>
            <option value="Acciones">{t("shares")}</option>
            <option value="Pignoración">{t("pledge")}</option>
            <option value="Derechos de Cobro">{t("receivables")}</option>
          </select>
        </div>

{/* Renderizar campos según el tipo de garantía */}
{formData.tipo_garantia === "Garantía Hipotecaria" && (
  <>
    {/* Dropdown para tipo_activo */}
    <div className="col-span-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {t("assetType")}
      </label>
      <select
        name="tipo_activo"
        value={formData.tipo_activo}
        onChange={handleChange}
        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
      >
        <option value="">{t("selectOption")}</option>
        <option value="Vivienda">Vivienda</option>
        <option value="Unifamiliar">Unifamiliar</option>
        <option value="Pareado/Adosado">Pareado/Adosado</option>
        <option value="Villa">Villa</option>
        <option value="Edificio">Edificio</option>
        <option value="Terreno">Terreno</option>
        <option value="Nave">Nave</option>
        <option value="Local Comercial">Local Comercial</option>
        <option value="Otros">Otros</option>
      </select>
    </div>
    
    {/* Campo description ocupando toda la línea */}
    <div className="col-span-2">
      {renderFloatingLabelInput(
        "descripcion",
        t("assetDescription"),
        "text",
        { maxLength: 255 }
      )}
    </div>
    {renderFloatingLabelInput("valor_tasacion", t("currentValuation"), "number")}
    {renderFloatingLabelInput("valor_compraventa", t("purchaseValue"), "number")}
        {renderFloatingLabelInput("registro_propiedad", t("propertyRegistry"))}
        {renderFloatingLabelInput("referencia_catastral", t("cadastralReference"))}
        {renderFloatingLabelInput("titular", t("owner"))}
        {renderFloatingLabelInput("finca", t("finca"))}
        {renderFloatingLabelInput("tomo", t("tome"))}
        {renderFloatingLabelInput("folio", t("folio"))}
        {renderFloatingLabelInput("alta", t("high"), "date")}

    
    
            {/* Checkbox con espacio reservado a la derecha */}
            <div className="col-span-2 flex items-center">
              <input
                type="checkbox"
                name="libre_de_cargas"
                checked={formData.libre_de_cargas}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-sm font-medium">{t("mortgageFree")}</label>
            </div>

            {/* Resto de los campos en la siguiente línea */}
            {!formData.libre_de_cargas && (
              <>
              {renderFloatingLabelInput("embargos", t("embargo"))}
                {renderFloatingLabelInput("valor_embargos", t("embargoValue"), "number")}
                {renderFloatingLabelInput("numero_hipotecas", t("hipotecas"), "number")}
                {renderFloatingLabelInput("valor_hipotecas", t("hipotecasValue"))}
              </>
            )}
            
          </>
          
        )}


        {formData.tipo_garantia === "Acciones" && (
          <>
            {renderFloatingLabelInput("numero_acciones", t("numberOfShares"), "number")}
            {renderFloatingLabelInput("valor_acciones", t("shareValue"), "number")}
            {renderFloatingLabelInput("porcentaje_participacion", t("participationPercentage"), "number")}
          </>
        )}

        {formData.tipo_garantia === "Pignoración" && (
          <>
            {renderFloatingLabelInput("tipo_pignoracion", t("pledgeType"))}
            {renderFloatingLabelInput("pignoracion", t("pledgedAsset"))}
            {renderFloatingLabelInput("valor_total_garantia", t("totalValorPignoracion"), "number")}
          </>
        )}

        {formData.tipo_garantia === "Derechos de Cobro" && (
          <>
            {renderFloatingLabelInput("tipo_derecho_cobro", t("paymentRightType"))}
            {renderFloatingLabelInput("entidad_pagadora", t("paymentEntity"))}
            {renderFloatingLabelInput("fecha_estimada_pago", t("paymentDate"), "date")}
            {renderFloatingLabelInput("valor_total_garantia", t("totalPagoDerecho"), "number")}
          </>
        )}
      </div>

      {/* Documentos */}
      <h4 className="text-sm font-semibold text-gray-800 mb-2 mt-4">{t("documents")}</h4>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-2 py-1">{t("documentType")}</th>
            <th className="border px-2 py-1">{t("file")}</th>
            <th className="border px-2 py-1">{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {formData.documentos.map((doc, index) => (
            <tr key={index}>
              <td className="border px-2 py-1">{doc.type}</td>
              <td className="border px-2 py-1">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              </td>
              <td className="border px-2 py-1 text-center">
                <button
                  onClick={() => removeDocument(index)}
                  className="text-red-500 hover:underline"
                >
                  {t("delete")}
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td className="border px-2 py-1">
              {renderFloatingLabelInput(
                "documentType",
                t("documentType"),
                "text",
                {
                  value: newDocument.type,
                  onChange: (e) =>
                    setNewDocument((prev) => ({
                      ...prev,
                      type: e.target.value,
                    })),
                }
              )}
            </td>
            <td className="border px-2 py-1">
              <input
                type="file"
                onChange={(e) =>
                  setNewDocument((prev) => ({
                    ...prev,
                    file: e.target.files[0],
                  }))
                }
                className="block w-full text-sm text-gray-700"
              />
            </td>
            <td className="border px-2 py-1 text-center">
              <button
                onClick={addDocument}
                className="px-2 py-1 bg-blue-500 text-white text-xs rounded hover:bg-blue-600"
              >
                {t("add")}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Botones de acción */}
      <div className="flex justify-end mt-4 gap-4">
        <button
          onClick={onCancel}
          className="flex items-center gap-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
        >
          <FontAwesomeIcon icon={faTimes} />
          {t("cancel")}
        </button>
        <button
          onClick={handleSave}
          className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          <FontAwesomeIcon icon={faSave} />
          {t("save")}
        </button>
      </div>
    </div>
  );
};

export default CollateralForm;
