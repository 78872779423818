export const refreshUserToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken'); // Guardas el refreshToken en localStorage

    try {
        const response = await fetch('https://api.finanbid.com/api/auth/refresh-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });

        if (!response.ok) {
            throw new Error('Error al renovar el token.');
        }

        const data = await response.json();
        localStorage.setItem('accessToken', data.accessToken); // Actualiza el accessToken
        return data.accessToken; // Devuelve el nuevo token
    } catch (error) {
        console.error('Error al renovar el token:', error);
        // Opcional: redirige al usuario a la pantalla de inicio de sesión si falla
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login'; // Redirige al login si falla
    }
};
