import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ children, isAuthenticated, allowedRoles }) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  // Validar si el usuario está autenticado (si se usa `isAuthenticated`)
  if (isAuthenticated !== undefined && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Validar si el usuario tiene un rol permitido (si se usa `allowedRoles`)
  if (allowedRoles && (!token || !allowedRoles.includes(role))) {
    return <Navigate to="/login" replace />;
  }

  // Si cumple cualquiera de las validaciones, renderiza el contenido
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool, // Verificación basada en autenticación
  allowedRoles: PropTypes.arrayOf(PropTypes.string), // Verificación basada en roles
};

export default ProtectedRoute;
