import React, { useState } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExpand, faPlus } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProyImages = ({ images, onDeleteImage, onImageUpload, projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageType, setImageType] = useState("Imagen");
  const [localImages, setLocalImages] = useState(images.filter(image => 
    image.image_type !== "localizacion" && image.image_type !== "comparable"
  ));
  const [sliderKey, setSliderKey] = useState(0); // Estado para forzar actualización del slider

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  const handleDeleteClick = (imageId) => {
    setImageToDelete(imageId);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (imageToDelete) {
      onDeleteImage(imageToDelete);
      setLocalImages(localImages.filter(image => image.id !== imageToDelete)); 
      setSliderKey(sliderKey + 1); // Actualiza la clave para forzar el renderizado del slider
    }
    setIsModalOpen(false);
    setImageToDelete(null);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setImageToDelete(null);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    setSelectedFile(null);
    setImageType("Imagen");
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Selecciona una imagen para subir.");
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('project_id', projectId);
    formData.append('image_type', imageType);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.finanbid.com/api/upload-image', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Error al subir la imagen');
      }

      const data = await response.json();
      const newImage = { id: data.id, image_url: data.imageUrl };
      setLocalImages((prevImages) => [...prevImages, newImage]); 
      setSliderKey(sliderKey + 1); // Cambia la clave para forzar el renderizado del slider
      onImageUpload(newImage.image_url); 
      closeUploadModal();
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    }
  };

  return (
    <div className="z-10">
      <div className="flex justify-between items-center mb-4 z-11">
        <h3 className="text-lg font-semibold">Imágenes del Proyecto</h3>
        <button
          onClick={openUploadModal}
          className="text-blue-500 bg-white rounded-full p-2 hover:bg-blue-500 hover:text-white"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <Slider {...settings} key={sliderKey} className="max-h-[300px] z-12">
        {localImages.map((image) => (
          <div key={image.id} className="p-2 z-12">
            <img
              src={image.image_url}
              alt="Project Image"
              className="rounded-lg w-full h-[200px] object-cover cursor-pointer hover:opacity-90"
              onClick={() => window.open(image.image_url, '_blank')}
            />
            <button
              className="top-2 right-2 text-red-500 bg-white rounded-full p-1 hover:bg-red-500 hover:text-white z-13"
              onClick={() => handleDeleteClick(image.id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </Slider>

      {/* Modal de Confirmación de Eliminación */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Confirmar eliminación</h3>
            <p>¿Estás seguro de que deseas eliminar esta imagen?</p>
            <div className="mt-4 flex justify-end">
              <button onClick={cancelDelete} className="text-gray-600 mr-4">Cancelar</button>
              <button onClick={confirmDelete} className="bg-red-500 text-white px-4 py-2 rounded">Eliminar</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Subida de Imagen */}
      {uploadModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-700">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Subir Imagen</h3>
            <input type="file" onChange={handleFileChange} className="mb-4" />
            <label className="block text-sm font-medium mb-2">Tipo de Imagen</label>
            <select
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            >
              <option value="Imagen">Imagen</option>
              <option value="Render">Render</option>
            </select>
            <div className="mt-4 flex justify-end">
              <button onClick={closeUploadModal} className="text-gray-600 mr-4">Cancelar</button>
              <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded">Subir</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProyImages;
