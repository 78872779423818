import React, { useState, useEffect } from "react";
import LTVCards from "./steps/LTVCards"; // Componente para mostrar LTV y LTC Cards

const FinancialDetails = ({
    amountRequested,
    setAmountRequested,
    capitalInvertido,
    setCapitalInvertido,
    duracionPrestamo,
    setDuracionPrestamo,
    repagoPrestamo,
    setRepagoPrestamo,
    colaterales,
    setvalorTasacion,
    valorTasacion,
    assets,
    ltv, // Asegúrate de pasar esta prop desde el componente padre
}) => {
    const [localAmountRequested, setLocalAmountRequested] = useState(amountRequested || "");
    const [localCapitalInvertido, setLocalCapitalInvertido] = useState(capitalInvertido || "");
    const [localDuracionPrestamo, setLocalDuracionPrestamo] = useState(duracionPrestamo || "");
    const [localRepagoPrestamo, setLocalRepagoPrestamo] = useState(repagoPrestamo || "");
    const [localvalorTasacion, setLocalvalorTasacion] = useState(valorTasacion || "");

    // Sincronización inicial cuando las props cambien
    useEffect(() => {
        setLocalAmountRequested(amountRequested || "");
        setLocalCapitalInvertido(capitalInvertido || "");
        setLocalDuracionPrestamo(duracionPrestamo || "");
        setLocalRepagoPrestamo(repagoPrestamo || "");
        setLocalvalorTasacion(valorTasacion || "");
    }, [amountRequested, capitalInvertido, duracionPrestamo, repagoPrestamo, valorTasacion]);

    return (
        <div className="p-4 space-y-4">
            <div className="text-lg font-semibold mb-2">Detalles de la operación</div>

            {/* Componente LTVCards */}
            <LTVCards ltv={ltv || { current: 0, final: 0 }} />

            {/* Cantidad Solicitada */}
            <div>
                <label className="block text-sm font-medium mb-1">Cantidad Solicitada</label>
                <input
                    type="number"
                    className="w-full p-2 border rounded"
                    value={localAmountRequested}
                    onChange={(e) => setLocalAmountRequested(e.target.value)}
                    onBlur={() => setAmountRequested(parseFloat(localAmountRequested) || 0)}
                />
            </div>

            {/* Capital Invertido y Plazo */}
            <div className="flex space-x-4">
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1">Capital Invertido</label>
                    <input
                        type="number"
                        className="w-full p-2 border rounded"
                        value={localCapitalInvertido}
                        onChange={(e) => setLocalCapitalInvertido(e.target.value)}
                        onBlur={() => setCapitalInvertido(parseFloat(localCapitalInvertido) || 0)}
                    />
                </div>
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1">Plazo requerido (meses)</label>
                    <input
                        type="number"
                        className="w-full p-2 border rounded"
                        value={localDuracionPrestamo}
                        onChange={(e) => setLocalDuracionPrestamo(e.target.value)}
                        onBlur={() => setDuracionPrestamo(parseInt(localDuracionPrestamo) || 0)}
                    />
                </div>
            </div>

            {/* Devolución */}
            <div>
                <label className="block text-sm font-medium mb-1">¿Cómo se va a realizar la devolución?</label>
                <textarea
                    className="w-full p-2 border rounded"
                    rows="3"
                    value={localRepagoPrestamo}
                    onChange={(e) => setLocalRepagoPrestamo(e.target.value)}
                    onBlur={() => setRepagoPrestamo(localRepagoPrestamo)}
                />
            </div>

            {/* Valor de Venta Final */}
            <div>
                <label className="block text-sm font-medium mb-1">Valor de venta final</label>
                <input
                    type="number"
                    className="w-full p-2 border rounded"
                    value={localvalorTasacion}
                    onChange={(e) => setLocalvalorTasacion(e.target.value)}
                    onBlur={() => setvalorTasacion(parseFloat(localvalorTasacion) || 0)}
                />
            </div>
        </div>
    );
};

export default FinancialDetails;
