import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Contacto = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusColor, setStatusColor] = useState(''); // Estado para definir el color del mensaje

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envía un correo electrónico a andresalen@gmail.com con los datos del formulario
      const response = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'andresalen@gmail.com',
          subject: 'Nuevo mensaje de contacto',
          html: `
            <p>Has recibido un nuevo mensaje de contacto con los siguientes datos:</p>
            <ul>
              <li><strong>Nombre:</strong> ${formData.name}</li>
              <li><strong>Email:</strong> ${formData.email}</li>
              <li><strong>Mensaje:</strong> ${formData.message}</li>
            </ul>
          `,
        }),
      });

      if (response.ok) {
        setStatusMessage(t('contact.successMessage'));
        setStatusColor('text-green-500'); // Color verde para mensaje de éxito
        setFormData({ name: '', email: '', message: '' }); // Limpiar el formulario
      } else {
        setStatusMessage(t('contact.errorMessage'));
        setStatusColor('text-red-500'); // Color rojo para mensaje de error
      }
    } catch (error) {
      setStatusMessage(t('contact.connectionErrorMessage'));
      setStatusColor('text-red-500');
    }

    // Limpiar mensaje después de unos segundos
    setTimeout(() => {
      setStatusMessage(null);
    }, 5000);
  };

  return (
    <section className="bg-back text-white py-16 lg:mt-10 mt-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-8 text-center">{t('contact.title')}</h2>
        <form onSubmit={handleSubmit} className="max-w-3xl mx-auto">
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="name">{t('contact.nameLabel')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-400 rounded bg-white text-black"
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="email">{t('contact.emailLabel')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-400 rounded bg-white text-black"
            />
          </div>
          <div className="mb-4">
            <label className="block text-lg mb-2" htmlFor="message">{t('contact.messageLabel')}</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-400 rounded bg-white text-black"
              rows="6"
            ></textarea>
          </div>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="bg-secondary text-white py-2 px-6 rounded hover:bg-opacity-90"
            >
              {t('contact.sendButton')}
            </button>
            {statusMessage && (
              <span className={`${statusColor} font-semibold`}>{statusMessage}</span>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contacto;
