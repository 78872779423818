import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

const CollateralList = ({ collaterals, onEdit, onDelete, onAddNew }) => {
  const { t } = useTranslation();
  useEffect(() => {

  }, [collaterals]);
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-2xl font-semibold text-gray-800">{t("collateralListTitle")}</h3>
        <button
          onClick={onAddNew}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          <FontAwesomeIcon icon={faPlus} />
          {t("addNewCollateral")}
        </button>
      </div>

      {/* Lista de colaterales */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
        {collaterals.map((collateral) => (
          <div
            key={collateral.id}
            className="bg-white shadow-md rounded-lg border border-gray-200 p-4 flex flex-col justify-between"
          >
            <div>
              <h4 className="text-lg font-semibold text-gray-800 mb-2">
                {t("collateralType")}: {collateral.tipo_garantia || t("unknown")}
              </h4>
              <p className="text-sm text-gray-600">
                {t("collateralDescription")}: {collateral.description || t("noDescription")}
              </p>
              <p className="text-sm text-gray-600">
                {t("currentValuation")}: {collateral.valor_tasacion || t("notProvided")}
              </p>
              <p className="text-sm text-gray-600">
                {t("totalGuaranteeValue")}: {collateral.valor_total_garantia || t("notProvided")}
              </p>
              {collateral.tipo_garantia === "Garantía Hipotecaria" && (
                <>
                  <p className="text-sm text-gray-600">
                    {t("assetType")}: {collateral.tipo_activo || t("unknown")}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("mortgageFree")}:{" "}
                    {collateral.libre_de_cargas ? t("yes") : t("no")}
                  </p>
                </>
              )}
              {collateral.tipo_garantia === "Acciones" && (
                <>
                  <p className="text-sm text-gray-600">
                    {t("numberOfShares")}: {collateral.numero_acciones || t("notProvided")}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("shareValue")}: {collateral.valor_acciones || t("notProvided")}
                  </p>
                </>
              )}
              {collateral.tipo_garantia === "Pignoración" && (
                <>
                  <p className="text-sm text-gray-600">
                    {t("pledgeType")}: {collateral.tipo_pignoracion || t("notProvided")}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("pledgedAsset")}: {collateral.pignoracion || t("notProvided")}
                  </p>
                </>
              )}
              {collateral.tipo_garantia === "Derechos de cobro" && (
                <>
                  <p className="text-sm text-gray-600">
                    {t("paymentEntity")}: {collateral.entidad_pagadora || t("notProvided")}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("paymentDate")}: {collateral.fecha_estimada_pago || t("notProvided")}
                  </p>
                </>
              )}
            </div>

            {/* Acciones */}
            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={() => onEdit(collateral)}
                className="flex items-center gap-2 px-3 py-1 bg-yellow-400 text-white rounded-md hover:bg-yellow-500"
              >
                <FontAwesomeIcon icon={faEdit} />
                {t("edit")}
              </button>
              <button
                onClick={() => onDelete(collateral.id)}
                className="flex items-center gap-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                <FontAwesomeIcon icon={faTrash} />
                {t("delete")}
              </button>
            </div>
          </div>
        ))}

        {/* Mostrar mensaje si no hay colaterales */}
        {collaterals.length === 0 && (
          <div className="col-span-full text-center text-gray-500">
            {t("noCollaterals")}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollateralList;
