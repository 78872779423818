import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Step4Congratulations = () => {
  const { t } = useTranslation(); // Hook para traducciones
  const navigate = useNavigate(); // Hook para navegación

  const goToDashboard = () => {
    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu dashboard
  };

  return (
    <div className="bg-back text-white py-16 px-6 lg:px-8 max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-4">
        {t('step4Congratulations.title', '¡Enhorabuena!')}
      </h2>
      <p className="text-lg text-gray-300 mb-8">
        {t(
          'step4Congratulations.description',
          'Tu proyecto ha sido creado exitosamente. Actualmente no es visible porque faltan algunos detalles que puedes completar en tu dashboard de cliente.'
        )}
      </p>
      <button
        className="bg-secondary text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90"
        onClick={goToDashboard}
      >
        {t('step4Congratulations.goToDashboard', 'Ir al Dashboard')}
      </button>
    </div>
  );
};

export default Step4Congratulations;
