import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpWidget from './HelpWidget';

import Inbox from './Inbox';
import DashboardHome from './DashboardHome';
import UserProjects from './UserProjects';
import UserProfile from './UserProfile';
import UserOffers from './UserOffers';
import Project from './Project';
import ProjectAssistantModal from "./ProjectAssistantModal";
import { faBars, faTimes, faHome, faUsers, faFolder, faChartLine, faMoneyBill, faCog, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const DashboardUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [offersCount, setOffersCount] = useState(0);
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar el sidebar
  const [dashboardStats, setDashboardStats] = useState({
    project_count: 0,
    inbox_count: 0,
    offer_count: 0,
    user_name: 'Usuario',
  });
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [collaboratorCode, setCollaboratorCode] = useState(null);
  const [latestUpdate, setLatestUpdate] = useState(null);

  const fetchLatestUpdate = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/system-updates');
      if (!response.ok) {
        throw new Error('Failed to fetch latest update');
      }
      const data = await response.json();
      setLatestUpdate(data);
    } catch (error) {
      console.error('Error fetching latest update:', error);
    }
  };

  useEffect(() => {
    const fetchOffersCount = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/offers/unread-count');
        const data = await response.json();
        setOffersCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de ofertas:', error);
      }
    };

    const fetchInboxCount = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        const role = localStorage.getItem('role');

        if (!userId || !role) {
          console.error('Faltan userId o role en el localStorage.');
          return;
        }

        const response = await fetch('https://api.finanbid.com/api/messages/unread-count', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            role: role,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error al obtener la cantidad de mensajes: ${response.status}`);
        }

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    const fetchDashboardStats = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        if (!userId) {
          console.error('No se encontró userId en localStorage.');
          return;
        }

        const response = await fetch(`https://api.finanbid.com/api/dashboard/stats/${userId}`);
        const data = await response.json();
        setDashboardStats(data);
      } catch (error) {
        console.error('Error al obtener estadísticas del dashboard:', error);
      }
    };

    const fetchCollaboratorInfo = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        const response = await fetch(`https://api.finanbid.com/api/collaborators/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setIsCollaborator(true);
          setCollaboratorCode(data.code);
        } else if (response.status === 404) {
          setIsCollaborator(false);
        } else {
          throw new Error('Error al obtener información del colaborador.');
        }
      } catch (error) {
        console.error('Error al obtener información del colaborador:', error);
      }
    };

    fetchOffersCount();
    fetchInboxCount();
    fetchDashboardStats();
    fetchCollaboratorInfo();
    fetchLatestUpdate(); // Llamada para obtener la última actualización
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const handleProjectsClick = () => {
    if (location.pathname === '/dashboard/projects') {
      navigate('/dashboard');
      setTimeout(() => navigate('/dashboard/projects'), 0);
    } else {
      navigate('/dashboard/projects');
    }
    setIsSidebarOpen(false); // Cierra el sidebar al hacer clic en una opción
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <HelpWidget />
      {/* Header */}
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="User Dashboard" />
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      {/* Sidebar */}
      <nav className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out`}>
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="User Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/dashboard" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faHome} className="mr-2" /> {t('dashboard.home')}
            </Link>
            <button
              onClick={handleProjectsClick}
              className="block text-gray-400 hover:text-white px-4 py-2 text-left w-full"
            >
              <FontAwesomeIcon icon={faFolder} className="mr-2" /> {t('dashboard.myProjects')}
            </button>
            <Link to="/dashboard/offers" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faMoneyBill} className="mr-2" /> {offersCount > 0 ? `${t('dashboard.offers')} (${offersCount})` : t('dashboard.offers')}
            </Link>
            <Link
              to="/dashboard/inbox"
              className={`block px-4 py-2 ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`}
              onClick={() => setIsSidebarOpen(false)}
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <Link to="/dashboard/profile" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
            <FontAwesomeIcon icon={faCog} className="mr-2" /> {t('dashboard.profile')}
          </Link>
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      {/* Contenido Principal con scroll */}
      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
      <Routes>
          <Route path="/" element={<DashboardHome stats={dashboardStats} isCollaborator={isCollaborator} collaboratorCode={collaboratorCode} latestUpdate={latestUpdate} />} />
          <Route path="/projects" element={<UserProjects />} />
          <Route path="/projects/:projectId" element={<Project />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/offers" element={<UserOffers />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardUser;

