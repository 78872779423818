import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone_number: '', // Añadido campo de teléfono
    referred_by_code: '', // Campo para el código de referido
  });
  const [error, setError] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const registerResponse = await fetch('https://api.finanbid.com/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          role: 'promotor', // Enviar siempre el rol como "promotor"
        }),
      });

      if (!registerResponse.ok) {
        const errorData = await registerResponse.json();
        throw new Error(errorData.message || 'Error al registrar el usuario');
      }

      // Después de registrar, obtener la respuesta del usuario
      const registerData = await registerResponse.json();
      const { user } = registerData;

      // Enviar email de bienvenida
      const emailData = {
        to: user.email,
        templateId: 1, // Template de bienvenida
        variables: {
          name: user.name,
          email: user.email,
          pass: formData.password, // Usamos la contraseña introducida en el formulario
        },
      };

      await fetch('https://api.finanbid.com/api/projects/send-email-with-template', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      // Después de enviar el correo, iniciar sesión automáticamente
      const loginResponse = await fetch('https://api.finanbid.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      if (!loginResponse.ok) {
        throw new Error('Error al iniciar sesión');
      }

      const loginData = await loginResponse.json();
      const { token } = loginData;

      // Guardar el token y redirigir al dashboard del promotor
      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (err) {
      console.error('Error al registrar:', err);
      setError(err.message || t('register.error'));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-back">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-secondary">{t('register.title')}</h2>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('register.name')}</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('register.email')}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('register.password')}</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('register.phone')}</label>
            <input
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('register.referredByCode')}</label>
            <input
              type="text"
              name="referred_by_code"
              value={formData.referred_by_code}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md"
              placeholder={t('register.referredByCodePlaceholder')}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-secondary text-white py-2 px-4 rounded-md hover:bg-opacity-90"
          >
            {t('register.signupButton')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
