import React from "react";
import { useTranslation } from "react-i18next";

const LTVCards = ({ ltv }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="bg-white shadow-md rounded-lg border border-gray-200 p-6">
        <h4 className="text-lg font-semibold text-gray-800">{t("currentLTV")}</h4>
        <p className="text-3xl font-bold text-blue-600 mt-4">{ltv.current}%</p>
        <p className="text-sm text-gray-600 mt-2">{t("currentLTVDescription")}</p>
      </div>
      <div className="bg-white shadow-md rounded-lg border border-gray-200 p-6">
        <h4 className="text-lg font-semibold text-gray-800">{t("finalLTV")}</h4>
        <p className="text-3xl font-bold text-green-600 mt-4">{ltv.final}%</p>
        <p className="text-sm text-gray-600 mt-2">{t("finalLTVDescription")}</p>
      </div>
    </div>
  );
};

export default LTVCards;
