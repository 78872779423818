import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon, ClipboardCheckIcon, CurrencyDollarIcon,EyeIcon,MailIcon } from "@heroicons/react/outline";

const ComoFunciona = () => {
  const { t } = useTranslation();
  const steps = [
    {
      title: t("comoFunciona.step1.title"),
      description: t("comoFunciona.step1.description"),
      icon: <PencilIcon className="h-10 w-10 text-white" />, // Ajusta el tamaño y color aquí
      imageUrl: "/images/detalles1.png",
    },
    {
      title: t("comoFunciona.step2.title"),
      description: t("comoFunciona.step2.description"),
      icon: <ClipboardCheckIcon className="h-10 w-10 text-white" />,
      imageUrl: "/images/detalles2.png"
    },
    {
      title: t("comoFunciona.step3.title"),
      description: t("comoFunciona.step3.description"),
      icon: <EyeIcon className="h-10 w-10 text-white" />,
      imageUrl: "/images/detalles3.png"
    },
    {
      title: t("comoFunciona.step4.title"),
      description: t("comoFunciona.step4.description"),
      icon: <MailIcon className="h-10 w-10 text-white" />,
      imageUrl: "/images/offers.png"
    },
    {
      title: t("comoFunciona.step5.title"),
      description: t("comoFunciona.step5.description"),
      icon: <CurrencyDollarIcon className="h-10 w-10 text-white" />,
      imageUrl: "/images/term.png"
    }
  ];

  return (
    <div className="relative overflow-hidden bg-back  pb-32 space-y-24 lg:mt-10 mt-24">

      {steps.map((step, index) => (
        <div key={index} className="relative mb-24">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div
              className={`mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 ${index % 2 === 0 ? "" : "lg:col-start-2"
                }`}
            >
              <div>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-primary">
                    {step.icon} {/* Renderiza el ícono */}
                  </span>
                </div>

                <div className="mt-6">
                  <h3 className="text-3xl font-bold tracking-tight text-white">
                    {step.title}
                  </h3>
                  <p className="mt-4 text-lg text-gray-300">{step.description}</p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div
                className={`${index % 2 === 0 ? "-mr-48 pl-6" : "-ml-48 pr-6"
                  } md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0`}
              >
                <img
                  loading="lazy"
                  width="647"
                  height="486"
                  className="w-full rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto  lg:ml-10"
                  src={step.imageUrl}
                  alt={step.title}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComoFunciona;
