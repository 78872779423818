import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const UserProfile = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    name: '',
    address: '',
    email: '',
    phone_number: '',
  });
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId'); // Asegúrate de que el userId esté almacenado en localStorage
        if (!userId) {
          throw new Error('User ID no encontrado en localStorage');
        }
        const response = await axios.get(`https://api.finanbid.com/api/user/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handleProfileUpdate = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccessMessage('');

      const token = localStorage.getItem('token');
      await axios.patch(
        'https://api.finanbid.com/api/user/profile',
        userData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccessMessage(t('profile.updateSuccess'));
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(t('profile.updateError'));
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordUpdate = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      setError(t('profile.passwordMismatch'));
      return;
    }

    try {
      setLoading(true);
      setError('');
      setSuccessMessage('');

      const token = localStorage.getItem('token');
      await axios.patch(
        'https://api.finanbid.com/api/user/change-password',
        {
          currentPassword: passwords.currentPassword,
          newPassword: passwords.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccessMessage(t('profile.passwordChangeSuccess'));
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      console.error('Error changing password:', error);
      setError(t('profile.passwordChangeError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">{t('profile.title')}</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.name')}</label>
          <input
            type="text"
            name="name"
            value={userData.name || ""}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.address')}</label>
          <input
            type="text"
            name="address"
            value={userData.address || ""}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.email')}</label>
          <input
            type="email"
            name="email"
            value={userData.email || ""}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.phoneNumber')}</label>
          <input
            type="tel"
            name="phone_number"
            value={userData.phone_number || ""}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>

      <button
        onClick={handleProfileUpdate}
        disabled={loading}
        className="mt-4 w-full bg-secondary text-white py-2 rounded hover:bg-back disabled:opacity-50"
      >
        {loading ? t('profile.saving') : t('profile.save')}
      </button>

      <h3 className="text-xl font-bold mt-8 mb-4">{t('profile.changePassword')}</h3>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.currentPassword')}</label>
          <input
            type="password"
            name="currentPassword"
            value={passwords.currentPassword}
            onChange={handlePasswordChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.newPassword')}</label>
          <input
            type="password"
            name="newPassword"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">{t('profile.confirmPassword')}</label>
          <input
            type="password"
            name="confirmPassword"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>

      <button
        onClick={handlePasswordUpdate}
        disabled={loading}
        className="mt-4 w-full bg-secondary text-white py-2 rounded hover:bg-back disabled:opacity-50"
      >
        {loading ? t('profile.saving') : t('profile.changePasswordButton')}
      </button>
    </div>
  );
};

export default UserProfile;
