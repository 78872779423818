import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const StepTermsAndConditions = ({ nextStep, saveTermsAcceptance }) => {
  const { t } = useTranslation(); // Hook para traducciones
  const [accepted, setAccepted] = useState(false); // Estado para saber si se ha marcado el checkbox
  const [terms, setTerms] = useState(''); // Estado para almacenar el texto de los términos y condiciones
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga de los términos
  const [ip, setIp] = useState(''); // Estado para capturar la IP del cliente

  useEffect(() => {
    // Obtener los términos y condiciones
    fetch('https://api.finanbid.com/api/legal-terms')
      .then(response => response.json())
      .then(data => {
        setTerms(data.terms);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching terms and conditions:', error);
        setIsLoading(false);
      });

    // Capturar la IP del cliente
    fetch('https://api.ipify.org?format=json') // Servicio externo para obtener la IP
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error fetching IP:', error));
  }, []);

  const handleCheckboxChange = (e) => {
    setAccepted(e.target.checked); // Cambia el estado cuando el usuario marca el checkbox
  };

  const handleNextClick = async () => {
    if (accepted) {
      try {
        const token = localStorage.getItem('token'); // Obtener el token del usuario

        // Enviar la aceptación de términos al backend
        await fetch('https://api.finanbid.com/api/legal-terms/acceptance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ip: ip || 'No IP detected',
            timestamp: new Date().toISOString(),
          }),
        });

        saveTermsAcceptance(); // Guardamos la aceptación de términos
        nextStep(); // Avanzamos al siguiente paso
      } catch (error) {
        console.error('Error sending terms acceptance:', error);
      }
    }
  };

  return (
    <div className="bg-back text-white py-16 px-6 lg:px-8 max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">{t('terms.title')}</h2>
      
      {isLoading ? (
        <p>{t('terms.loading')}</p> // Mensaje de carga en el idioma seleccionado
      ) : (
        <div className="mb-6">
          <div
            className="bg-white text-black p-4 border rounded h-64 overflow-y-scroll"
            style={{ whiteSpace: 'pre-line' }}
          >
            {terms} {/* Aquí se renderiza el texto de los términos */}
          </div>

          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="terms"
              className="mr-2"
              onChange={handleCheckboxChange}
              checked={accepted}
            />
            <label htmlFor="terms">
              {t('terms.accept')} {/* Texto del checkbox traducido */}
            </label>
          </div>

          <button
            type="button"
            className={`mt-4 px-6 py-2 rounded text-white ${accepted ? 'bg-secondary' : 'bg-gray-400'}`}
            onClick={handleNextClick}
            disabled={!accepted}
          >
            {t('terms.next')} {/* Texto del botón "Siguiente" traducido */}
          </button>
        </div>
      )}
    </div>
  );
};

export default StepTermsAndConditions;
