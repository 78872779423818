import React from 'react';

const TrustLogos = () => {
  // Lista de logos
  const logos = [
    '/logos/fondo1.png',
    '/logos/fondo2.png',
    '/logos/fondo3.png',
    '/logos/fondo4.png',
    '/logos/fondo5.png',
    '/logos/fondo6.png',
    '/logos/fondo7.png',
    '/logos/fondo9.png',

  ];

  return (
    <section className="py-12 bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h3 className="text-center text-2xl font-bold text-back mb-8">
          Algunos fondos que confían en nosotros
        </h3>
        <div className="flex justify-center items-center space-x-6">
          {logos.map((logo, index) => (
            <div
              key={index}
              className="grayscale hover:grayscale-0 transition duration-300 ease-in-out"
            >
              <img
                src={logo}
                alt={`Logo fondo ${index + 1}`}
                className="h-12 lg:h-16"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TrustLogos;
