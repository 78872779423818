import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEye, faTrash, faCheck, faTimes, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FloatingLabelInput from "./FloatingLabelInput";
import AdministratorModal from "./AdministratorModal";
import DocumentUpload from "./DocumentUpload";
import ShareholderModal from "./ShareholderModal";

const Step7LegalEntity = ({ project, projectId, legalEntity, onUpdate }) => {
    const { t } = useTranslation();

    const defaultEntity = {
        business_name: "",
        business_cif: "",
        business_address: "",
        contact_phone: "",
        contact_email: "",
        year_of_incorporation: "",
        documents: [],
        administrators: [],
        shareholders: [],
    };

    const [entityDetails, setEntityDetails] = useState(legalEntity || defaultEntity);
    const [documents, setDocuments] = useState([]);
    const [administrator, setAdministrator] = useState({});
    const [shareholders, setShareholders] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const [showAdminModal, setShowAdminModal] = useState(false);
const [showDocumentModal, setShowDocumentModal] = useState(false);
const [showShareholderModal, setShowShareholderModal] = useState(false);

    useEffect(() => {
        if (legalEntity) {
            setEntityDetails(legalEntity);
            setDocuments(legalEntity.documents || []);
            setAdministrator(legalEntity.administrators?.[0] || {});
            setShareholders(legalEntity.shareholders || []);
        }
    }, [legalEntity]);

    const handleInputChange = (field, value) => {
        const updatedDetails = { ...entityDetails, [field]: value };
        setEntityDetails(updatedDetails);
        onUpdate(updatedDetails);
    };

    const handleFieldUpdate = async (field, value) => {
        try {
            const response = await fetch(`https://api.finanbid.com/api/admin/projects/${project.id}/legal/field`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ field, value }),
            });

            if (!response.ok) {
                throw new Error("Error al actualizar el campo");
            }
        } catch (error) {
            console.error("Error al actualizar el campo:", error);
        }
    };

    const renderDocuments = () => {
        const mandatoryDocs = [
            { type: "Escritura", key: "incorporation_pdf" },
            { type: "Último Impuesto Sociedades", key: "last_tax_pdf" },
            { type: "Titularidad Real", key: "real_ownership_pdf" },
        ];

        return (
            <div className="space-y-4">
                {mandatoryDocs.map((doc) => {
                    const foundDoc = documents.find((d) => d.document_type === doc.type);
                    return (
                        <div key={doc.type} className="flex items-center justify-between border-b py-2">
                            <span>{doc.type}</span>
                            <div>
                                {foundDoc ? (
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => window.open(foundDoc.document_pdf, "_blank")}
                                    />
                                ) : (
                                    <button className="text-blue-500">{t("addDocument")}</button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderShareholders = () => (
        <div className="space-y-2">
            {shareholders.map((shareholder) => (
                <div
                    key={shareholder.id}
                    className="flex justify-between items-center border-b py-2"
                >
                    <span>{shareholder.shareholder_name} ({shareholder.participation}%)</span>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red-500 cursor-pointer"
                        onClick={() => console.log("Eliminar accionista")}
                    />
                </div>
            ))}
        </div>
    );

    return (
        <div className="flex flex-col lg:flex-row gap-8 overflow-y-auto max-h-[90vh]">
            <div className="w-full lg:w-2/3 space-y-6 text-sm">
                {/* Datos de la Empresa */}
                <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="text-md font-semibold text-gray-800 mb-4">{t("companyDetails")}</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <FloatingLabelInput
                            label="Nombre de la Empresa"
                            value={entityDetails.business_name || ""}
                            onBlur={() => handleFieldUpdate("business_name", entityDetails.business_name)}
                            onChange={(e) => handleInputChange("business_name", e.target.value)}
                            style={{ zIndex: 10, position: "relative" }} // Agrega este estilo
                        />
                        <FloatingLabelInput
                            label="CIF"
                            value={entityDetails.business_cif || ""}
                            onBlur={() => handleFieldUpdate("business_cif", entityDetails.business_cif)}
                            onChange={(e) => handleInputChange("business_cif", e.target.value)}
                            className="relative"
                        />
                        <FloatingLabelInput
                            label="Dirección"
                            value={entityDetails.business_address || ""}
                            onBlur={() => handleFieldUpdate("business_address", entityDetails.business_address)}
                            onChange={(e) => handleInputChange("business_address", e.target.value)}
                            style={{ zIndex: 10, position: "relative", overflow: "hidden"}} // Agrega este estilo
                        />
                        <FloatingLabelInput
                            label="Teléfono de Contacto"
                            value={entityDetails.contact_phone || ""}
                            onBlur={() => handleFieldUpdate("contact_phone", entityDetails.contact_phone)}
                            onChange={(e) => handleInputChange("contact_phone", e.target.value)}
                            style={{ zIndex: 10, position: "relative" }} // Agrega este estilo
                        />
                        <FloatingLabelInput
                            label="Email de Contacto"
                            value={entityDetails.contact_email || ""}
                            onBlur={() => handleFieldUpdate("contact_email", entityDetails.contact_email)}
                            onChange={(e) => handleInputChange("contact_email", e.target.value)}
                            style={{ zIndex: 10, position: "relative" }} // Agrega este estilo
                        />
                        <FloatingLabelInput
                            label="Año de Constitución"
                            value={entityDetails.year_of_incorporation || ""}
                            onBlur={() => handleFieldUpdate("year_of_incorporation", entityDetails.year_of_incorporation)}
                            onChange={(e) => handleInputChange("year_of_incorporation", e.target.value)}
                            style={{ zIndex: 10, position: "relative" }} // Agrega este estilo
                        />
                    </div>
                </div>
    
                {/* Administrador */}
                <div className="grid grid-cols-2 gap-4">
                    {/* Card de Administrador */}
                    <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
                        <h3 className="text-md font-semibold text-gray-800 mb-4 flex justify-between items-center">
                            {t("administrator")}
                            <button
                                className="text-blue-500 hover:text-blue-600"
                                onClick={() => setShowAdminModal(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </h3>
                        {administrator?.administrator_name ? (
                            <div className="space-y-2 text-sm">
                                <p>
                                    <strong>{t("name")}:</strong> {administrator.administrator_name}
                                </p>
                                <p>
                                    <strong>{t("documentId")}:</strong> {administrator.document_id}
                                </p>
                                <p>
                                    <strong>{t("profession")}:</strong> {administrator.profession}
                                </p>
                            </div>
                        ) : (
                            <p className="text-gray-500">{t("noAdministratorFound")}</p>
                        )}
                    </div>
    
                    {/* Documentos de la Empresa */}
                    <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
                        <h3 className="text-md font-semibold text-gray-800 mb-4 flex justify-between items-center">
                            {t("companyDocuments")}
                            <button
                                className="text-blue-500 hover:text-blue-600"
                                onClick={() => setShowDocumentModal(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </h3>
                        <div className="space-y-2">
                            {documents.length > 0 ? (
                                documents.map((doc) => (
                                    <div key={doc.id} className="flex justify-between items-center border-b py-2">
                                        <span>{doc.document_type}</span>
                                        <div className="flex items-center gap-2">
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                className="text-blue-500 cursor-pointer"
                                                onClick={() => window.open(doc.document_pdf, "_blank")}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="text-red-500 cursor-pointer"
                                                onClick={() => handleDeleteDocument(doc.id)}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">{t("noDocumentsFound")}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Card de Consejos y Shareholders */}
            <div className="w-full lg:w-1/3 space-y-6">
                {/* Consejo */}
                <div className="bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
                    <h4 className="text-md font-medium text-gray-700 flex items-center">
                        <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
                        {t("tipTitle")}
                    </h4>
                    <p className="text-sm text-gray-600 mt-2">
                        {t("step7Tip")
                            .split("\n")
                            .map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                    </p>
                </div>
    
                {/* Shareholders */}
                <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="text-md font-semibold text-gray-800 mb-4 flex justify-between items-center">
                        {t("shareholders")}
                        <button
                            className="text-blue-500 hover:text-blue-600"
                            onClick={() => setShowShareholderModal(true)}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </h3>
                    <div>
                        {shareholders.length > 0 ? (
                            shareholders.map((sh) => (
                                <div key={sh.id} className="flex justify-between items-center p-2 border-b">
                                    <span>
                                        {sh.shareholder_name} - {sh.participation}%
                                    </span>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => handleDeleteShareholder(sh.id)}
                                    />
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">{t("noShareholdersFound")}</p>
                        )}
                    </div>
                    
                </div>
            </div>
            {/* Modal para Administrador */}
{showAdminModal && (
    <AdministratorModal
        onClose={() => setShowAdminModal(false)}
        onSave={(adminData) => {
            setAdministrator(adminData);
            setShowAdminModal(false);
        }}
        administrator={administrator} // Pasar datos del administrador actual
        entityId={entityDetails.id} // ID de la entidad legal
className="relative z-100"
    />
)}

{/* Modal para Documentos */}
{showDocumentModal && (
    <DocumentUpload
        onClose={() => setShowDocumentModal(false)}
        setDocuments={(newDocs) => setDocuments([...documents, ...newDocs])}
        legalEntityId={entityDetails.id}
    />
)}

{/* Modal para Shareholders */}
{showShareholderModal && (
    <ShareholderModal
        onClose={() => setShowShareholderModal(false)}
        onSave={(newShareholder) => {
            setShareholders([...shareholders, newShareholder]);
            setShowShareholderModal(false);
        }}
    />
)}
        </div>
    );
};

export default Step7LegalEntity;