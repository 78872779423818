import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import AddCollateralModal from './AddCollateralModal';


const CollateralDetails = ({ collaterals, projectId, onUpdateField }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [expandedCollateral, setExpandedCollateral] = useState(null);
  const [localCollaterals, setLocalCollaterals] = useState(collaterals); // Copia local de collaterals para gestionar los cambios
  const [showModal, setShowModal] = useState(false); // Estado para controlar el modal
  const handleToggle = () => setExpanded(!expanded);
  const handleCollateralToggle = (id) => setExpandedCollateral(expandedCollateral === id ? null : id);
  const getStatusColor = (status) => {
    switch (status) {
      case 'OK':
        return 'text-green-500';
      case 'Pending':
        return 'text-orange-500';
      case 'Failed':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }

  };
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const handleBlur = async (collateralId, field, value) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/collateral/${collateralId}/field`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ field, value })
      });

      if (!response.ok) throw new Error(`Error updating ${field} for collateral ${collateralId}`);

      // Actualizar el estado local para reflejar el cambio
      setLocalCollaterals((prevCollaterals) =>
        prevCollaterals.map((collateral) =>
          collateral.id === collateralId ? { ...collateral, [field]: value } : collateral
        )
      );

      if (onUpdateField) {
        onUpdateField(collateralId, field, value);
      }
    } catch (error) {
      console.error(`Error updating ${field} for collateral ${collateralId}:`, error);
    }
  };
  const handleAddCollateral = (newCollateral) => {
    setLocalCollaterals((prevCollaterals) => [...prevCollaterals, newCollateral]);
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex justify-between items-center cursor-pointer" onClick={handleToggle}>
        <h2 className="text-lg font-semibold">{t('collateralDetails.title')}</h2>
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={expanded ? faChevronDown : faChevronRight}
            className="mr-4"
          />
          {/* Botón para abrir el modal */}
          <button
            onClick={() => setShowModal(true)}
            className="text-blue-500 bg-white rounded-full p-2 hover:bg-blue-500 hover:text-white"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>

      {expanded && (
        <div className="mt-4">
          {/* Collateral List */}
          <div className="grid grid-cols-5 gap-4 mb-4 font-semibold text-gray-600">
            <span>{t('collateralDetails.tipo_garantia')}</span>
            <span>{t('collateralDetails.tipo_activo')}</span>
            <span>{t('collateralDetails.valor_total_garantia')}</span>
            <span>{t('collateralDetails.verification_status')}</span>
          </div>

          {localCollaterals.map((collateral) => (
            <div key={collateral.id} className="border-b border-gray-200 py-2">
              <div className="grid grid-cols-5 gap-4 items-center">
                <p>{collateral.tipo_garantia}</p>
                <p>{collateral.tipo_activo}</p>
                <input
                  type="number"
                  defaultValue={collateral.valor_total_garantia}
                  onBlur={(e) => handleBlur(collateral.id, 'valor_total_garantia', e.target.value)}
                  className="border rounded p-1 text-sm"
                />
                <select
                  value={collateral.verification_status}
                  onChange={(e) => handleBlur(collateral.id, 'verification_status', e.target.value)}
                  className={`border rounded p-1 text-sm ${getStatusColor(collateral.verification_status)}`} // Aplicando el color dinámico
                >
                  {['OK', 'Pending', 'Failed'].map((status) => (
                    <option key={status} value={status}>
                      {t(`collateralDetails.statuses.${status}`)}
                    </option>
                  ))}
                </select>
                <button onClick={() => handleCollateralToggle(collateral.id)} className="ml-2">
                  <FontAwesomeIcon icon={expandedCollateral === collateral.id ? faChevronDown : faChevronRight} />
                </button>
              </div>

              {expandedCollateral === collateral.id && (
                <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                  {/* Lista de documentos */}
                  <div className="mt-4 mb-4">
                    {collateral.documents && collateral.documents.length > 0 && (
                      <div className="flex items-center space-x-2">
                        <span className="text-lg font-medium text-gray-600">{t('collateralDetails.viewDocument')}</span>
                        {collateral.documents.map((doc) => (
                          <a
                            key={doc.id}
                            href={doc.document_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            {doc.document_type}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {/* Display fields based on the type of guarantee */}
                    {collateral.tipo_garantia === 'Garantía Hipotecaria' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.tipo_activo')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.tipo_activo}
                            onBlur={(e) => handleBlur(collateral.id, 'tipo_activo', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.valor_tasacion')}</label>
                          <input
                            type="number"
                            defaultValue={collateral.valor_tasacion}
                            onBlur={(e) => handleBlur(collateral.id, 'valor_tasacion', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.valor_compraventa')}</label>
                          <input
                            type="number"
                            defaultValue={collateral.valor_compraventa}
                            onBlur={(e) => handleBlur(collateral.id, 'valor_compraventa', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.libre_de_cargas')}</label>
                          <input
                            type="checkbox"
                            checked={collateral.libre_de_cargas}
                            onChange={(e) => handleBlur(collateral.id, 'libre_de_cargas', e.target.checked)}
                            className="p-2 text-sm"
                          />
                        </div>
                        {!collateral.libre_de_cargas && (
                          <>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.embargos')}</label>
                              <select
                                defaultValue={collateral.embargos}
                                onBlur={(e) => handleBlur(collateral.id, 'embargos', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                              >
                                <option value="Si">{t('collateralDetails.si')}</option>
                                <option value="No">{t('collateralDetails.no')}</option>
                              </select>
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.valor_embargos')}</label>
                              <input
                                type="number"
                                defaultValue={collateral.valor_embargos}
                                onBlur={(e) => handleBlur(collateral.id, 'valor_embargos', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.numero_hipotecas')}</label>
                              <input
                                type="number"
                                defaultValue={collateral.numero_hipotecas}
                                onBlur={(e) => handleBlur(collateral.id, 'numero_hipotecas', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.valor_hipotecas')}</label>
                              <input
                                type="number"
                                defaultValue={collateral.valor_hipotecas}
                                onBlur={(e) => handleBlur(collateral.id, 'valor_hipotecas', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.tipo_propietario')}</label>
                              <select
                                defaultValue={collateral.tipo_propietario}
                                onBlur={(e) => handleBlur(collateral.id, 'tipo_propietario', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                              >
                                <option value="Persona Fisica">{t('collateralDetails.personaFisica')}</option>
                                <option value="Persona Juridica">{t('collateralDetails.personaJuridica')}</option>
                              </select>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {collateral.tipo_garantia === 'Acciones' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.numero_acciones')}</label>
                          <input
                            type="number"
                            defaultValue={collateral.numero_acciones}
                            onBlur={(e) => handleBlur(collateral.id, 'numero_acciones', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.valor_acciones')}</label>
                          <input
                            type="number"
                            defaultValue={collateral.valor_acciones}
                            onBlur={(e) => handleBlur(collateral.id, 'valor_acciones', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.porcentaje_participacion')}</label>
                          <input
                            type="number"
                            defaultValue={collateral.porcentaje_participacion}
                            onBlur={(e) => handleBlur(collateral.id, 'porcentaje_participacion', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                      </>
                    )}
                    {collateral.tipo_garantia === 'Derechos de cobro' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.derecho_cobro')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.derecho_cobro}
                            onBlur={(e) => handleBlur(collateral.id, 'derecho_cobro', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.tipo_derecho_cobro')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.tipo_derecho_cobro}
                            onBlur={(e) => handleBlur(collateral.id, 'tipo_derecho_cobro', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.entidad_pagadora')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.entidad_pagadora}
                            onBlur={(e) => handleBlur(collateral.id, 'entidad_pagadora', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.fecha_estimada_pago')}</label>
                          <input
                            type="date"
                            defaultValue={collateral.fecha_estimada_pago}
                            onBlur={(e) => handleBlur(collateral.id, 'fecha_estimada_pago', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                      </>
                    )}
                    {collateral.tipo_garantia === 'Pignoración' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.pignoracion')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.pignoracion}
                            onBlur={(e) => handleBlur(collateral.id, 'pignoracion', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-600">{t('collateralDetails.tipo_pignoracion')}</label>
                          <input
                            type="text"
                            defaultValue={collateral.tipo_pignoracion}
                            onBlur={(e) => handleBlur(collateral.id, 'tipo_pignoracion', e.target.value)}
                            className="w-full border rounded p-2 text-sm"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
       {showModal && (
        <AddCollateralModal
          projectId={projectId}
          onClose={closeModal}
          onAdd={handleAddCollateral}
        />
      )}
    </div>
  );
};

export default CollateralDetails;

