import React from 'react';
import { useTranslation } from 'react-i18next';


const Step0 = ({ nextStep }) => {
  const { t } = useTranslation(); // Hook para traducciones

  return (
    <div className="bg-back text-white py-16 px-6 lg:px-8 max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-4">{t('step0.title')}</h2>
      <p className="text-lg text-gray-300 mb-8">
        {t('step0.description')}
      </p>
      <p className="text-lg text-gray-300 mb-8">
        {t('step0.guide')}
      </p>
      <button
        className="bg-secondary text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90"
        onClick={nextStep} // Avanza al siguiente paso
      >
        {t('step0.startButton')}
      </button>
    </div>
  );
};

export default Step0;
