import React from 'react';
import { useTranslation } from 'react-i18next';

const PromoterSteps = () => {
  const { t } = useTranslation();

  return (
    <section
      className="py-16 bg-cover bg-center" // Eliminamos el mt-16
      style={{
        backgroundImage: 'url("/fondo.png")', // Ruta de la imagen de fondo
      }}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl sm:text-center mb-14">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('promoterSteps.title')}
          </p>
          <p className="mt-2 text-lg sm:text-center leading-8 text-gray-300">
            {t('promoterSteps.subtitle')}
          </p>
        </div>
        
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          
          {/* Paso 1 */}
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-secondary">
              <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              {t('promoterSteps.step1.title')}
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-500/80 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-white">
              {t('promoterSteps.step1.heading')}
            </p>
            <p className="mt-1 text-base leading-7 text-gray-300">
              {t('promoterSteps.step1.description')}
            </p>
          </div>

          {/* Paso 2 */}
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-secondary">
              <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              {t('promoterSteps.step2.title')}
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-500/80 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-white">
              {t('promoterSteps.step2.heading')}
            </p>
            <p className="mt-1 text-base leading-7 text-gray-300">
              {t('promoterSteps.step2.description')}
            </p>
          </div>

          {/* Paso 3 */}
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-secondary">
              <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              {t('promoterSteps.step3.title')}
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-500/80 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-white">
              {t('promoterSteps.step3.heading')}
            </p>
            <p className="mt-1 text-base leading-7 text-gray-300">
              {t('promoterSteps.step3.description')}
            </p>
          </div>

          {/* Paso 4 */}
          <div>
            <div className="flex items-center text-sm font-semibold leading-6 text-secondary">
              <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              </svg>
              {t('promoterSteps.step4.title')}
              <div
                className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-500/80 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                aria-hidden="true"
              ></div>
            </div>
            <p className="mt-6 text-lg font-semibold leading-8 tracking-wide text-white">
              {t('promoterSteps.step4.heading')}
            </p>
            <p className="mt-1 text-base leading-7 text-gray-300">
              {t('promoterSteps.step4.description')}
            </p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default PromoterSteps;
