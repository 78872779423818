import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Step1Login = ({ nextStep, setUserData }) => {
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(true); // Estado para alternar entre login y registro
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    phone: '',
    role: 'user',
    referred_by_code: '', // Campo para el código de referido
  });
  const [error, setError] = useState('');

  const toggleForm = () => {
    setIsLogin(!isLogin); // Cambia entre login y registro
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const url = isLogin
      ? 'https://api.finanbid.com/api/login'
      : 'https://api.finanbid.com/api/register';

    const payload = isLogin
      ? { email: formData.email, password: formData.password }
      : {
          email: formData.email,
          password: formData.password,
          name: formData.name,
          phone_number: formData.phone,
          role: 'promotor',
          referred_by_code: formData.referred_by_code || null, // Incluir el código de referido si existe
        };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en la solicitud');
      }

      const data = await response.json();

      if (!isLogin) {
        // Registro exitoso: enviar email de bienvenida
        const emailData = {
          to: formData.email,
          templateId: 1, // Template de bienvenida
          variables: {
            name: formData.name,
            email: formData.email,
            pass: formData.password,
          },
        };

        await fetch('https://api.finanbid.com/api/projects/send-email-with-template', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
        });

        // Iniciar sesión automáticamente después del registro
        const loginResponse = await fetch('https://api.finanbid.com/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: formData.email, password: formData.password }),
        });

        if (!loginResponse.ok) {
          const errorData = await loginResponse.json();
          throw new Error(errorData.message || 'Error en el login automático');
        }

        const loginData = await loginResponse.json();
        localStorage.setItem('token', loginData.token);
        localStorage.setItem('role', loginData.role);
        localStorage.setItem('userId', loginData.userId);

        setUserData({ email: formData.email, role: loginData.role });
        nextStep();
      } else {
        // Login exitoso
        const { token, role, userId } = data;
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('userId', userId);

        setUserData({ email: formData.email, role });
        nextStep();
      }
    } catch (error) {
      setError(error.message || 'Ocurrió un error inesperado.');
    }
  };

  return (
    <div className="bg-back text-white py-16 px-6 lg:px-8 max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div>
        <h2 className="text-4xl font-bold mb-4">{t('step1.title')}</h2>
        <p className="text-lg text-gray-300 mb-8">{t('step1.description')}</p>
        <button
          onClick={toggleForm}
          className="bg-secondary text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90"
        >
          {isLogin ? t('step1.noAccount') : t('step1.haveAccount')}
        </button>
      </div>

      <div>
        <div className="bg-white p-8 rounded-lg shadow-md text-back">
          <h3 className="text-2xl font-bold mb-6 text-center">
            {isLogin ? t('step1.loginTitle') : t('step1.registerTitle')}
          </h3>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-lg mb-2" htmlFor="email">
                {t('step1.email')}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded"
                required
              />
            </div>

            {!isLogin && (
              <>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="name">
                    {t('step1.name')}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="phone">
                    {t('step1.phone')}
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-lg mb-2" htmlFor="referred_by_code">
                    {t('step1.referredByCode')}
                  </label>
                  <input
                    type="text"
                    id="referred_by_code"
                    name="referred_by_code"
                    value={formData.referred_by_code}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded"
                  />
                </div>
              </>
            )}

            <div className="mb-6">
              <label className="block text-lg mb-2" htmlFor="password">
                {t('step1.password')}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-secondary text-white py-3 px-6 rounded-lg font-bold hover:bg-opacity-90"
            >
              {isLogin ? t('step1.loginButton') : t('step1.registerButton')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step1Login;


