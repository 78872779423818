import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const LoanDetails = ({ data, onUpdateField }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ ...data });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setFormData({ ...data });
  }, [data]);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleBlur = async (field, value) => {
    if (!formData.project_id) {
      console.error("Project ID is undefined.");
      return;
    }

    if (onUpdateField) {
      await onUpdateField(field, value);
    } else {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/admin/projects/${formData.project_id}/loan_request/field`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ field, value })
        });
        
        if (!response.ok) {
          throw new Error(`Failed to update ${field}`);
        }

        // Actualizar el estado local si es necesario después de una actualización exitosa
        setFormData((prevData) => ({ ...prevData, [field]: value }));
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    }
  };

  const calculateLTV = () => {
    if (!formData.amount_requested || !formData.collaterals || formData.collaterals.length === 0) return 0;
    const totalCollateralValue = formData.collaterals.reduce((sum, collateral) => {
      const value = parseFloat(collateral.valor_total_garantia || 0);
      return sum + value;
    }, 0);
    return totalCollateralValue ? Math.round((formData.amount_requested / totalCollateralValue) * 100) : 0;
  };

  const getLtvColor = (ltv) => {
    if (ltv <= 60) return 'bg-green-100';
    if (ltv <= 75) return 'bg-orange-100';
    return 'bg-red-100';
  };

  const calculateLTC = () => {
    return 0; // Placeholder for actual LTC calculation
  };

  const getLtcColor = (ltc) => {
    if (ltc <= 60) return 'bg-green-100';
    if (ltc <= 75) return 'bg-orange-100';
    return 'bg-red-100';
  };

  const getVerificationStatusColor = () => {
    switch (formData.verification_status) {
      case 'OK':
        return 'text-green-600';
      case 'Pending':
        return 'text-orange-600';
      case 'Failed':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">Detalles del préstamo</h2>
        <div className="flex items-center">
          <label className="mr-2 text-sm font-medium text-gray-600">Verification Status:</label>
          <select
            value={formData.verification_status || ""}
            onChange={(e) => {
              handleChange('verification_status', e.target.value);
              handleBlur('verification_status', e.target.value);
            }}
            className={`border rounded p-1 text-sm ${getVerificationStatusColor()}`}
          >
            <option value="OK">OK</option>
            <option value="Pending">Pending</option>
            <option value="Failed">Failed</option>
          </select>
          <FontAwesomeIcon
            icon={expanded ? faChevronDown : faChevronRight}
            className="ml-4 cursor-pointer"
            onClick={toggleAccordion}
          />
        </div>
      </div>

      {expanded && (
        <>
          {/* Calculated LTV and LTC */}
          <div className="flex space-x-4 my-4">
            <div className={`flex-1 rounded-lg p-4 ${getLtvColor(calculateLTV())} shadow`}>
              <div className="flex justify-between items-center bg-gray-200 p-2 rounded-t-lg">
                <h3 className="text-sm font-semibold">LTV</h3>
              </div>
              <div className="mt-4 text-center text-lg font-semibold">{formData.ltv}%</div>
            </div>
            <div className={`flex-1 rounded-lg p-4 ${getLtcColor(calculateLTC())} shadow`}>
              <div className="flex justify-between items-center bg-gray-200 p-2 rounded-t-lg">
                <h3 className="text-sm font-semibold">LTC</h3>
              </div>
              <div className="mt-4 text-center text-lg font-semibold">{calculateLTC()}%</div>
            </div>
          </div>

          {/* Remaining Fields */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">Amount Requested</label>
              <input
                type="number"
                defaultValue={formData.amount_requested ?? ""}
                onChange={(e) => handleChange('amount_requested', e.target.value)}
                onBlur={() => handleBlur('amount_requested', formData.amount_requested)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Tasacion Valor</label>
              <input
                type="number"
                defaultValue={formData.tasacion_valor ?? ""}
                onChange={(e) => handleChange('tasacion_valor', e.target.value)}
                onBlur={() => handleBlur('tasacion_valor', formData.tasacion_valor)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">LTV</label>
              <input
                type="number"
                defaultValue={formData.ltv ?? ""}
                onChange={(e) => handleChange('ltv', e.target.value)}
                onBlur={() => handleBlur('ltv', formData.ltv)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Licencia</label>
              <input
                type="checkbox"
                checked={!!formData.licencia}
                onChange={(e) => handleChange('licencia', e.target.checked)}
                onBlur={() => handleBlur('licencia', formData.licencia)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Capital Invertido</label>
              <input
                type="number"
                defaultValue={formData.capital_invertido ?? ""}
                onChange={(e) => handleChange('capital_invertido', e.target.value)}
                onBlur={() => handleBlur('capital_invertido', formData.capital_invertido)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Valor Compraventa</label>
              <input
                type="number"
                defaultValue={formData.valor_compraventa ?? ""}
                onChange={(e) => handleChange('valor_compraventa', e.target.value)}
                onBlur={() => handleBlur('valor_compraventa', formData.valor_compraventa)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Duración Préstamo</label>
              <input
                type="number"
                defaultValue={formData.duracion_prestamo ?? ""}
                onChange={(e) => handleChange('duracion_prestamo', e.target.value)}
                onBlur={() => handleBlur('duracion_prestamo', formData.duracion_prestamo)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Devolución Intereses</label>
              <input
                type="text"
                defaultValue={formData.devolucion_intereses ?? ""}
                onChange={(e) => handleChange('devolucion_intereses', e.target.value)}
                onBlur={() => handleBlur('devolucion_intereses', formData.devolucion_intereses)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Repago</label>
              <textarea
                defaultValue={formData.repago ?? ""}
                onChange={(e) => handleChange('repago', e.target.value)}
                onBlur={() => handleBlur('repago', formData.repago)}
                className="w-full border rounded-sm p-2 text-sm"
                rows="3"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Valor Tasacion Final</label>
              <input
                type="number"
                defaultValue={formData.valor_tasacion_final ?? ""}
                onChange={(e) => handleChange('valor_tasacion_final', e.target.value)}
                onBlur={() => handleBlur('valor_tasacion_final', formData.valor_tasacion_final)}
                className="w-full border rounded-sm p-2 text-sm"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};


export default LoanDetails;
