import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon, LightBulbIcon, BriefcaseIcon, CollectionIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  // Función para mostrar el modal
  const handleModalOpen = () => {
    setShowModal(true);
  };

  // Función para cerrar el modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <section
      className="min-h-screen w-screen bg-back text-white lg:mt-10 flex items-center"
      style={{
        backgroundImage: 'url("/fondo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="mx-auto max-w-7xl w-full">
        <div className="lg:pt-20 lg:grid lg:grid-cols-12 lg:gap-8 mt-32 lg:mt-16 items-center"
          style={{
            paddingTop: '20px'
          }}>
          <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left lg:mr-12 lg:pt-16 md:pt-20 sm:pt-24">
            <div className="space-y-8">
              <div className="space-y-4 pb-12">
                <div className="space-y-2">
                  <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                    {t('hero.earlyAccess')}
                  </span>
                  <h1 className="text-4xl font-extrabold tracking-wide sm:text-5xl md:text-6xl">
                    <span className="block sm:inline text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-red-500 to-pink-600">
                      {t('hero.title')}
                    </span> 
                    <span className="block font-extrabold text-white">
                      {t('hero.subtitle')}
                    </span>
                  </h1>
                </div>
                <p className="text-lg text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {t('hero.description')}
                </p>
              </div>
              <div className="border-t border-gray-700"></div>
              <div className="mt-4 sm:mt-6 mb-2">
                <button
                  onClick={() => navigate('/subir-proyecto')}
                  className="inline-block px-8 py-3 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-opacity-90"
                >
                  {t('hero.boton1')}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-8 lg:mt-0 mb-4 lg:col-span-6 grid grid-cols-1 sm:grid-cols-2 gap-4 px-6 lg:px-0 lg:ml-12">
            <div
              onClick={() => navigate('/inmobiliario')}
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <HomeIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card1')}</h2>
            </div>

            <div
              onClick={() => navigate('/renovables')}
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <LightBulbIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card2')}</h2>
            </div>

            <div
              onClick={handleModalOpen} // Muestra el modal para Card 3
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <BriefcaseIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card3')}</h2>
            </div>

            <div
              onClick={handleModalOpen} // Muestra el modal para Card 4
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <CollectionIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card4')}</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('hero.proximamente')}</h2>
            <p className="text-gray-600 mb-6">{t('hero.prox2')}</p>
            <button
              onClick={handleModalClose}
              className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-opacity-90"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;
