import React from 'react';

const Privacidad = () => {
  return (
    <div className="bg-back text-white w-11/12 md:w-8/12 lg:w-7/12 mx-auto mt-24 p-6 rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Política de Privacidad</h1>
      <p className="mb-4">
        <strong>Última actualización:</strong> Novienmbre 2024
      </p>
      <p className="mb-4">
        En <strong>Finanbid</strong>, tu privacidad es una prioridad. Nuestra
        Política de Privacidad cumple con el Reglamento (UE) 2016/679 General de
        Protección de Datos (GDPR) del Parlamento Europeo y del Consejo, de 27 de
        abril de 2016, y otras normativas aplicables en materia de protección de
        datos.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">Nuestros datos de contacto</h2>
      <p className="mb-4">
        <strong>Finanbid</strong>
        <br />
        Av de Sor Teresa Prat, 15, Carretera de Cádiz, 29003 Málaga
        <br />
        Teléfono: (+34) 614 224 935
        <br />
        Email:{' '}
        <a href="mailto:privacy@finanbid.com" className="text-blue-500 underline">
          privacy@finanbid.com
        </a>
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">Delegado de Protección de Datos</h2>
      <p className="mb-4">
        Puedes contactar con nuestro Delegado de Protección de Datos escribiendo a:{' '}
        <a href="mailto:privacy@finanbid.com" className="text-blue-500 underline">
          privacy@finanbid.com
        </a>
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">¿Para qué utilizamos los datos personales?</h2>
      <p className="mb-4">En <strong>Finanbid</strong>, tratamos tus datos personales con las siguientes finalidades:</p>
      <ul className="list-disc list-inside space-y-2">
        <li>
          <strong>Prestación de servicios:</strong>
          <ul className="list-disc list-inside ml-5 space-y-1">
            <li>Facilitar el acceso y uso de la plataforma para la publicación, análisis y financiación de proyectos.</li>
            <li>Gestionar solicitudes de contacto entre promotores y fondos tras la aceptación de condiciones.</li>
          </ul>
        </li>
        <li>
          <strong>Cumplimiento normativo:</strong>
          <ul className="list-disc list-inside ml-5 space-y-1">
            <li>Verificar antecedentes documentales y realizar controles de cumplimiento.</li>
          </ul>
        </li>
        <li>
          <strong>Mejorar nuestros servicios:</strong>
          <ul className="list-disc list-inside ml-5 space-y-1">
            <li>Analizar y optimizar la experiencia del usuario en nuestra plataforma.</li>
            <li>Implementar medidas para satisfacer las necesidades de promotores y fondos.</li>
          </ul>
        </li>
        <li>
          <strong>Comunicaciones y marketing:</strong>
          <ul className="list-disc list-inside ml-5 space-y-1">
            <li>Gestionar suscripciones a newsletters o notificaciones sobre nuevos proyectos.</li>
          </ul>
        </li>
        <li>
          <strong>Procesos de selección:</strong>
          <ul className="list-disc list-inside ml-5 space-y-1">
            <li>Tratar datos de candidatos para procesos de selección y contratación actuales o futuros.</li>
          </ul>
        </li>
      </ul>
      <br />
      <br />
      <h2>¿Con qué bases jurídicas tratamos los datos personales?</h2>
    <p>El tratamiento de tus datos personales se realiza únicamente cuando se cumple, al menos, una de las siguientes condiciones:</p>
    <br />
    <ul>
      <li><strong>Consentimiento:</strong> Por ejemplo, para enviarte newsletters o gestionar tu registro en la plataforma.</li>
      <li><strong>Relación contractual:</strong> Cuando el tratamiento es necesario para prestarte nuestros servicios como usuario registrado en la plataforma.</li>
      <li><strong>Cumplimiento de obligaciones legales:</strong> Incluyendo las relacionadas con normativas financieras y de prevención de delitos.</li>
      <li><strong>Interés legítimo:</strong> Como la mejora de nuestros servicios o la promoción de nuestra plataforma, siempre que no prevalezcan tus derechos y libertades fundamentales.</li>
    </ul>
    <br />
    <br />

    <h2>¿A quién podemos comunicar tus datos?</h2>
    <p>Tus datos personales pueden ser compartidos con los siguientes destinatarios:</p>
    <ul>
      <li><strong>Entidades del grupo Finanbid:</strong> Empresas que formen parte de nuestro grupo empresarial para facilitar la gestión interna y los servicios ofrecidos.</li>
      <li><strong>Fondos e inversores:</strong> Solo en caso de aceptación explícita de las condiciones por parte del usuario.</li>
      <li><strong>Autoridades competentes:</strong> Cuando sea requerido por ley, como en el caso de auditorías o investigaciones legales.</li>
      <li><strong>Terceros fuera de la UE:</strong> En caso de transferencias internacionales, nos aseguramos de que se cumplan las condiciones establecidas en la normativa aplicable.</li>
    </ul>
    <br />
    <br />
    <h2>¿Durante cuánto tiempo conservamos tus datos?</h2>
    <p>Conservamos los datos personales mientras sean necesarios para:</p>
    <ul>
      <li>Justificar la prestación de servicios o el cumplimiento de obligaciones legales.</li>
      <li>Gestionar posibles reclamaciones o requerimientos legales dentro de los plazos establecidos por la normativa vigente.</li>
    </ul>
    <p>Una vez finalizado el período de conservación, procederemos a su eliminación de manera segura.</p>
    <br />
    <br />
    <h2>Medidas de seguridad</h2>
    <p>En <strong>Finanbid</strong>, implementamos medidas técnicas y organizativas para proteger tus datos personales y garantizar su confidencialidad, integridad y disponibilidad. Estas medidas incluyen el cifrado de comunicaciones, controles de acceso y auditorías periódicas de seguridad.</p>
    <br /><br />
    <h1>Tus derechos y cómo ejercerlos</h1>
    <br />
    <p>Como usuario, tienes derecho a:</p>
    <ul>
      <li><strong>Acceso:</strong> Conocer qué datos personales tratamos sobre ti.</li>
      <li><strong>Rectificación:</strong> Corregir datos inexactos o incompletos.</li>
      <li><strong>Supresión:</strong> Solicitar la eliminación de tus datos personales.</li>
      <li><strong>Limitación:</strong> Restringir el tratamiento de tus datos en ciertas circunstancias.</li>
      <li><strong>Portabilidad:</strong> Recibir tus datos en un formato estructurado o transferirlos a otro responsable.</li>
      <li><strong>Oposición:</strong> Oponerte al tratamiento basado en intereses legítimos o acciones de marketing.</li>
      <li><strong>Retirar tu consentimiento:</strong> En cualquier momento para los tratamientos basados en este.</li>
    </ul>
    <br />
    <p>Para ejercer estos derechos, contáctanos a través de: <a href="mailto:privacy@finanbid.com">privacy@finanbid.com</a></p>
    <br />
    <p>Si consideras que tus derechos no han sido respetados, puedes presentar una reclamación ante la <strong>Agencia Española de Protección de Datos (AEPD)</strong>.</p>
    <br />
    <h2>Revisión y actualización</h2>
    <p>Esta Política de Privacidad será revisada y actualizada, al menos, una vez al año o siempre que haya cambios significativos en el tratamiento de datos personales.</p>
    <br />
    <p><strong>Fecha de última actualización:</strong> Noviembre 2024</p>

      {/* Continúa añadiendo el resto del contenido de la política con clases Tailwind */}
    </div>
  );
};

export default Privacidad;

