import React, { useState, useEffect } from 'react';
import GeneralInfo from './GeneralInfo';
import FinancialDetails from './FinancialDetails';
import Collaterals from './Collaterals';
import Documents from './Documents';
import LegalEntity from './LegalEntity';
import ProyImages from './ProyImages';
import Assets from './Assets'; // Importa el nuevo componente
import ProjectAssistant from './ProjectAssistant';
import LandPurchaseLoanDetails from './LandPurchaseLoanDetails';
import BridgeLoanDetails from './BridgeLoanDetails';
import DeveloperLoanDetails from './DeveloperLoanDetails';
import FlippingLoanDetails from './FlippingLoanDetails';
import ProjectAssistantModal from "./ProjectAssistantModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { calculateLTV } from "../utils/ltvCalculator";

const Project = ({ projectId, onBack }) => {
    const [projectData, setProjectData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAssistant, setShowAssistant] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false); // Estado para el modal de estado
    const [projectDetails, setProjectDetails] = useState(null); // Estado para projectDetails
    const [assets, setAssets] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [ltv, setLtv] = useState({ current: 0, final: 0 }); // Valores del LTV
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!projectId) return;
    
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
    
                const [projectRes, collateralsRes, documentsRes, imagesRes, entityRes, detailsRes, assetsRes] = await Promise.all([
                    fetch(`https://api.finanbid.com/api/projects/${projectId}`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/projects/${projectId}/collaterals`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/projects/${projectId}/documents`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/projects/${projectId}/images`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/legal-entities/${projectId}`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/projects/${projectId}/details`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`https://api.finanbid.com/api/projects/${projectId}/assets`, { headers: { Authorization: `Bearer ${token}` } })
                ]);
    
                if ([projectRes, collateralsRes, documentsRes, imagesRes, entityRes, detailsRes, assetsRes].some(res => !res.ok)) {
                    throw new Error('Error al cargar los datos');
                }
    
                const project = await projectRes.json();
                const collaterals = await collateralsRes.json();
                const documents = await documentsRes.json();
                const images = await imagesRes.json();
                const legalEntity = await entityRes.json();
                const projectDetails = await detailsRes.json();
                const assets = await assetsRes.json();
    
                setProjectData({ project, collaterals, documents, images, legalEntity });
                setProjectDetails(projectDetails); // Almacena los detalles específicos del proyecto
                setAssets(assets);
                setData({ project, collaterals, assets });
                const nonEditableStatuses = ["En Revision", "Visible", "Cancelado", "Finalizado"];
                if (nonEditableStatuses.includes(project.status)) {
                    setShowStatusModal(true);
                }
                // Llama a `updateLTV` solo cuando todos los datos están disponibles
                updateLTV(project, collaterals, assets);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [projectId]);

    // Función para calcular LTV y actualizar el estado
    const updateLTV = async (project, collaterals, assets) => {
        const amountRequested = parseFloat(project.amount_requested || 0);
        const capitalInvertido = parseFloat(project.capital_invertido || 0);

        // Calcular el nuevo LTV
        const newLTV = calculateLTV(amountRequested, capitalInvertido, assets, collaterals);
 
        setLtv(newLTV); // Actualizar el estado de LTV localmente
    
       
        
    };

    const updateLoanRequestField = async (field, value) => {
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://api.finanbid.com/api/projects/${projectId}/loan-request-details`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ [field]: value })
            });
        } catch (error) {
            console.error('Error updating loan request field:', error);
        }
    };
    const setRepagoPrestamo = (newRepago) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                repago: newRepago,
            },
        }));
        updateLoanRequestField('repago', newRepago);
    };
    // Funciones para actualizar valores específicos del proyecto
    const setAmountRequested = (newAmount) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                amount_requested: newAmount,
            },
        }));
        updateLoanRequestField('amount_requested', newAmount);
    };
    const setCapitalInvertido = (newCapital) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                capital_invertido: newCapital,
            },
        }));
        updateLoanRequestField('capital_invertido', newCapital);
    };

    const setDuracionPrestamo = (newDuration) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                duracion_prestamo: newDuration,
            },
        }));
        updateLoanRequestField('duracion_prestamo', newDuration);
    };

    const setvalorTasacion = (newTasacion) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                valor_tasacion_final: newTasacion,
            },
        }));
        updateLoanRequestField('valor_tasacion_final', newTasacion);
    };

    // Otras funciones para actualizar datos en el estado del proyecto
    const setResumen = (newResumen) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                resumen: newResumen,
            },
        }));
    };

    const setDireccion = (newDireccion) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                direccion: newDireccion,
            },
        }));
    };

    const setLocalidad = (newLocalidad) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                localidad: newLocalidad,
            },
        }));
    };

    const setProvincia = (newProvincia) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                provincia: newProvincia,
            },
        }));
    };
    const setPais = (newPais) => {
        setProjectData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                pais: newPais,
            },
        }));
    };

    const openModal = (projectId) => {
        setSelectedProjectId(projectId);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedProjectId(null);
    };

    const onUploadDocument = (newDocumentUrl) => {
        setProjectData((prevData) => ({
            ...prevData,
            documents: [...prevData.documents, { id: Date.now(), document_url: newDocumentUrl }]
        }));
    };

    const onSaveProjectDetails = async (updatedData) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(updatedData)
            });

            if (!response.ok) {
                throw new Error('Error al actualizar el proyecto');
            }

            const data = await response.json();
            
            setProjectData((prevData) => ({
                ...prevData,
                project: data.project
            }));
        } catch (error) {
            console.error('Error al actualizar el proyecto:', error);
        }
    };

    const onDeleteImage = async (imageId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/images/${imageId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Error al eliminar la imagen');
            }

            setProjectData((prevData) => ({
                ...prevData,
                images: prevData.images.filter((image) => image.id !== imageId)
            }));
        } catch (error) {
            console.error('Error al eliminar la imagen:', error);
            alert('Hubo un problema al eliminar la imagen. Por favor, intenta de nuevo.');
        }
    };

    const onImageUpload = (imageUrl) => {
        setProjectData((prevData) => ({
            ...prevData,
            images: [...prevData.images, { id: Date.now(), url: imageUrl }]
        }));
    };
    if (loading) return <div>Cargando...</div>;
    if (error) return <div>Error: {error}</div>;
    const isAssetsRequired = [4, 5, 6, 7].includes(projectData.project.product_type);

    const handleSaveDetails = async (updatedDetails) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/save-details`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ details: updatedDetails })
            });

            if (!response.ok) {
                throw new Error('Error al guardar los detalles del proyecto');
            }

            const data = await response.json();
            setProjectData((prevData) => ({
                ...prevData,
                details: data.details
            }));
        } catch (error) {
            console.error('Error al guardar los detalles del proyecto:', error);
        }
    };

    const handleSendForReview = async () => {
        try {
            const token = localStorage.getItem('token');

            // Cambia el estado del proyecto a "En revision" 
            const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/field`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    field: 'status',
                    value: 'En Revision'
                })
            });

            if (!response.ok) throw new Error('Error al actualizar el estado del proyecto');

            // Envía el correo de notificación a admin@finanbid.com
            await fetch('https://api.finanbid.com/api/projects/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    to: 'andresalen@gmail.com',
                    subject: 'Solicitud de revisión de proyecto',
                    html: `<p>Un usuario ha enviado un proyecto para revisión. ID del proyecto: ${projectId}</p>`
                })
            });

            // Redirige al usuario a la página de proyectos
            window.location.href = 'https://www.finanbid.com/dashboard/projects';

        } catch (error) {
            console.error('Error al enviar el proyecto a revisión:', error);
            alert('Hubo un problema al enviar el proyecto a revisión. Por favor, intenta de nuevo.');
        }
    };
    
    // Componentes del asistente con `projectData` y funciones de actualización
    const components = [
        {
            title: 'Información General',
            helpText: 'Completa los datos básicos del proyecto.',
            component: <GeneralInfo
                project={projectData?.project}
                setResumen={setResumen}
                setDireccion={setDireccion}
                setLocalidad={setLocalidad}
                setProvincia={setProvincia}
                setPais={setPais}
            />
        },
        {
            title: 'Detalles Financieros',
            helpText: 'Introduce los detalles financieros del proyecto.',
            component: <FinancialDetails
            amountRequested={projectData?.project?.amount_requested}
            setAmountRequested={setAmountRequested}
            capitalInvertido={projectData?.project?.capital_invertido}
            setCapitalInvertido={setCapitalInvertido}
            duracionPrestamo={projectData?.project?.duracion_prestamo}
            setDuracionPrestamo={setDuracionPrestamo}
            repagoPrestamo={projectData?.project?.repago}
            setRepagoPrestamo={setRepagoPrestamo}
            colaterales={projectData?.collaterals || []}
            valorTasacion={projectData?.project?.valor_tasacion_final}
            setvalorTasacion={setvalorTasacion}
            ltv={ltv}

        />
        },
        {
            title: 'Detalles Específicos',
            helpText: 'Introduce los detalles específicos del proyecto según su tipo.',
            component: (() => {
                switch (projectData?.project?.product_type) {
                    case 1:
                        return <LandPurchaseLoanDetails details={projectData.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
                    case 4:
                    case 5:
                        return <BridgeLoanDetails details={projectData.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
                    case 6:
                        return <DeveloperLoanDetails details={projectData.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
                    case 7:
                        return <FlippingLoanDetails details={projectData.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
                    default:
                        return <div>No hay detalles específicos para este tipo de proyecto.</div>;
                }
            })()
        },
        {
            title: 'Colaterales',
            helpText: 'Añade las garantías colaterales para el préstamo.',
            component: <Collaterals
                collaterals={projectData?.collaterals}
                projectId={projectId}
            />
        },
        {
            title: 'Entidad Legal',
            helpText: 'Introduce la información de la entidad legal.',
            component: <LegalEntity
                legalEntity={projectData?.legalEntity}
                projectId={projectId}
            />
        },
        {
            title: 'Documentos del Proyecto',
            helpText: 'Sube los documentos necesarios para el proyecto.',
            component: <Documents
                documents={projectData?.documents}
                onUploadDocument={onUploadDocument}
                projectId={projectId}
            />
        },
        {
            title: 'Imágenes del Proyecto',
            helpText: 'Añade imágenes del proyecto para mejor visualización.',
            component: <ProyImages
                images={projectData?.images}
                onDeleteImage={onDeleteImage}
                onImageUpload={onImageUpload}
                projectId={projectId}
            />
        }
    ];





    // Determina el componente a mostrar basado en el tipo de proyecto
    const renderProjectSpecificCard = () => {
        if (!projectDetails) return null;

        switch (projectDetails.project_type) {
            case 1:
                return <LandPurchaseLoanDetails details={projectDetails.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
            case 4:
            case 5:
                return <BridgeLoanDetails details={projectDetails.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
            case 6:
                return <DeveloperLoanDetails details={projectDetails.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
            case 7:
                return <FlippingLoanDetails details={projectDetails.details} onSave={handleSaveDetails} projectId={projectData.project.id} />;
            default:
                return null;
        }
    };

    return (
        <div className="p-6 bg-gray-100 min-h-full">
            {showStatusModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg text-center">
                        <h3 className="text-xl font-semibold mb-4">El proyecto no se puede modificar</h3>
                        <p>Este proyecto está en estado: {projectData.project.status}</p>
                        <button
                            onClick={() => window.location.href = 'https://www.finanbid.com/dashboard/projects'}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
                        >
                            Volver a Mis Proyectos
                        </button>
                    </div>
                </div>
            )}
            <div className={`flex justify-between mb-4 ${showStatusModal ? 'pointer-events-none opacity-50' : ''}`}>
                <button onClick={onBack} className="text-blue-500">
                    ← Volver a Mis Proyectos
                </button>
                <div className="flex space-x-4">
                    <button
                        onClick={handleSendForReview}
                        className="bg-yellow-500 text-white font-semibold px-4 py-2 rounded-lg shadow-lg"
                    >
                        Enviar proyecto a revisión
                    </button>
                    {/* Nuevo modal Asistente Magico */}

                    <button
                        onClick={() => openModal(projectId)}
                        className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                    >
                        <FontAwesomeIcon icon={faMagicWandSparkles} className="mr-2" />
                        Asistente Mágico
                    </button>
                    {isModalOpen && (
                        <ProjectAssistantModal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            projectId={selectedProjectId}
                        />
                    )}
                </div>
            </div>

            <div className={`grid grid-cols-1 lg:grid-cols-3 gap-6 ${showStatusModal ? 'pointer-events-none opacity-50' : ''}`}>
                <div className="bg-white shadow rounded-lg p-4">
                    <GeneralInfo
                        project={{
                            ...projectData?.project,
                            setResumen,
                            setDireccion,
                            setLocalidad,
                            setProvincia,
                            setPais
                        }}
                        onSaveProjectDetails={onSaveProjectDetails}
                    />
                </div>
                <div className="bg-white shadow rounded-lg p-4">
                    <FinancialDetails
                        amountRequested={projectData?.project?.amount_requested}
                        setAmountRequested={setAmountRequested}
                        capitalInvertido={projectData?.project?.capital_invertido}
                        setCapitalInvertido={setCapitalInvertido}
                        duracionPrestamo={projectData?.project?.duracion_prestamo}
                        setDuracionPrestamo={setDuracionPrestamo}
                        repagoPrestamo={projectData?.project?.repago}
                        setRepagoPrestamo={setRepagoPrestamo}
                        colaterales={projectData?.collaterals}
                        valorTasacion={projectData?.project?.valor_tasacion_final}
                        setvalorTasacion={setvalorTasacion}
                        data={projectData}
                        assets={assets}
                        ltv={ltv} // Pasar el LTV para mostrarlo en LTVCards
                    />
                </div>
                <div className="bg-white shadow rounded-lg p-4">
                    {renderProjectSpecificCard()}
                </div>

            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-6">
                {isAssetsRequired && (
                    <div className="bg-white shadow rounded-lg p-4 z-20">
                        <Assets projectId={projectId} assets={assets} />
                    </div>
                )}

                <div className="bg-white shadow rounded-lg p-4">
                    <LegalEntity
                        legalEntity={projectData?.legalEntity}
                        projectId={projectId}
                        onSave={updatedEntity =>
                            setProjectData(prev => ({
                                ...prev,
                                legalEntity: updatedEntity,
                            }))
                        }
                    />
                </div>

                <div className="bg-white shadow rounded-lg p-4">
                    <Collaterals
                        collaterals={projectData?.collaterals}
                        projectId={projectId}
                        onAddCollateral={(newCollateral) => {
                            setProjectData((prevData) => ({
                                ...prevData,
                                collaterals: [...prevData.collaterals, newCollateral],
                            }));
                        }}
                    />
                </div>

                <div className={"bg-white shadow rounded-lg p-4"}>
                    <Documents
                        documents={projectData?.documents}
                        onUploadDocument={onUploadDocument}
                        projectId={projectId}
                    />
                </div>

                <div className="bg-white shadow rounded-lg p-4 lg:col-span-2 z-10">
                    <ProyImages
                        images={projectData?.images}
                        onDeleteImage={onDeleteImage}
                        onImageUpload={onImageUpload}
                        projectId={projectId}
                    />
                </div>
            </div>

            {/* Modal del Asistente */}
            {showAssistant && (
                <ProjectAssistant
                    components={components}
                    onClose={() => setShowAssistant(false)}
                    projectData={projectData}
                    setRepagoPrestamo={setRepagoPrestamo}
                    setAmountRequested={setAmountRequested}
                    setCapitalInvertido={setCapitalInvertido}
                    setDuracionPrestamo={setDuracionPrestamo}
                    setResumen={setResumen}
                    setDireccion={setDireccion}
                    setLocalidad={setLocalidad}
                    setProvincia={setProvincia}
                    setPais={setPais}
                    onUploadDocument={onUploadDocument}
                    onSaveProjectDetails={onSaveProjectDetails}
                    onDeleteImage={onDeleteImage}
                    onImageUpload={onImageUpload}
                    details={projectDetails.details}
                    onSave={handleSaveDetails}
                    setvalorTasacion={setvalorTasacion}
                />
            )}
        </div>
    );

};

export default Project;


