import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import EmailTemplates from './EmailTemplates'; // Subcomponente para la gestión de plantillas de email
import SectorsManagement from './SectorsManagement'; // Subcomponente para gestión de sectores, productos y subsectores
import AdminSystemUpdates from './AdminSystemUpdates'; // Subcomponente para gestión de actualizaciones del sistema

const Settings = () => {
  const [activeTab, setActiveTab] = useState('email-templates');

  const menuItems = [
    { id: 'email-templates', label: 'Email Templates', component: <EmailTemplates /> },
    { id: 'user-roles', label: 'User Roles', component: <div>Gestión de Roles</div> }, // Placeholder
    { id: 'system-updates', label: 'System Updates', component: <AdminSystemUpdates /> }, // Placeholder
    { id: 'logs', label: 'Logs', component: <div>Logs del Sistema</div> }, // Placeholder
    { id: 'sectors', label: 'Sectors', component: <SectorsManagement /> }, // Nuevo elemento del menú
  ];

  return (
    <div className="h-screen bg-gray-100">
      {/* Menú Superior */}
      <nav className="bg-gray-800 text-white px-4 py-2 flex items-center space-x-4">
        {menuItems.map((item) => (
          <button
            key={item.id}
            onClick={() => setActiveTab(item.id)}
            className={`px-4 py-2 rounded ${
              activeTab === item.id ? 'bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'
            }`}
          >
            {item.label}
          </button>
        ))}
      </nav>

      {/* Contenedor Central */}
      <div className="p-6">
        {menuItems.map(
          (item) =>
            activeTab === item.id && (
              <div key={item.id} className="bg-white shadow rounded-lg p-4">
                {item.component}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Settings;
