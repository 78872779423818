import React, { useState, useEffect } from 'react';

const EmailTemplates = () => {
  const [templates, setTemplates] = useState([]); // Listado de plantillas
  const [selectedTemplate, setSelectedTemplate] = useState(null); // Plantilla seleccionada
  const [newTemplate, setNewTemplate] = useState({ subject: '', html_content: '' }); // Datos para nueva plantilla
  const [isCreatingNew, setIsCreatingNew] = useState(false); // Estado para alternar entre creación y edición
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga

  // Cargar listado de plantillas al montar el componente
  useEffect(() => {
    const fetchTemplates = async () => {
      setIsLoading(true); // Inicia el estado de carga
      try {
        const response = await fetch('https://api.finanbid.com/api/email-templates');
        if (!response.ok) {
          throw new Error(`Error fetching templates: ${response.status}`);
        }
        const data = await response.json();
        setTemplates(data); // Guarda las plantillas en el estado
      } catch (error) {
        console.error('Error fetching templates:', error);
        alert('Failed to fetch email templates. Please try again.');
      } finally {
        setIsLoading(false); // Finaliza el estado de carga
      }
    };
  
    fetchTemplates();
  }, []);

  // Guardar cambios de una plantilla existente
  const handleSaveTemplate = async () => {
    try {
      const response = await fetch(`https://api.finanbid.com/api/email-templates/${selectedTemplate.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedTemplate),
      });
      if (response.ok) {
        alert('Template updated successfully!');
        setSelectedTemplate(null);
        refreshTemplates();
      } else {
        throw new Error('Error updating template');
      }
    } catch (error) {
      console.error('Error updating template:', error);
      alert('Failed to update the template');
    }
  };

  // Crear una nueva plantilla
  const handleCreateTemplate = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/email-templates', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newTemplate),
      });
      if (response.ok) {
        const createdTemplate = await response.json();
        setTemplates([...templates, createdTemplate]);
        setNewTemplate({ subject: '', html_content: '' });
        setIsCreatingNew(false);
        alert('Template created successfully!');
      } else {
        throw new Error('Error creating template');
      }
    } catch (error) {
      console.error('Error creating template:', error);
      alert('Failed to create the template');
    }
  };

  // Cancelar edición o creación
  const handleCancel = () => {
    setSelectedTemplate(null);
    setNewTemplate({ subject: '', html_content: '' });
    setIsCreatingNew(false);
  };

  // Refrescar el listado de plantillas
  const refreshTemplates = () => {
    setIsLoading(true);
    fetch('https://api.finanbid.com/api/email-templates')
      .then((res) => res.json())
      .then((data) => {
        setTemplates(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error refreshing templates:', error);
        setIsLoading(false);
      });
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg min-h-screen">
      <h2 className="text-2xl font-semibold mb-6">Email Templates</h2>
  
      {/* Loading */}
      {isLoading && <p className="text-gray-500">Loading templates...</p>}
  
      {/* Botón para crear nueva plantilla */}
      {!isCreatingNew && !selectedTemplate && (
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setIsCreatingNew(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Create New Template
          </button>
        </div>
      )}
  
      {/* Listado de Plantillas */}
      {!isCreatingNew && !selectedTemplate && templates.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {templates.map((template) => (
            <div
              key={template.id}
              className="border p-4 rounded-lg shadow hover:shadow-lg cursor-pointer transition"
              onClick={() => setSelectedTemplate(template)}
            >
              <h3 className="text-lg font-medium">{template.subject || 'Unnamed Template'}</h3>
              <p className="text-sm text-gray-500 truncate">{template.html_content}</p>
            </div>
          ))}
        </div>
      )}
  
      {/* Mensaje cuando no hay plantillas */}
      {!isCreatingNew && !selectedTemplate && templates.length === 0 && !isLoading && (
        <p className="text-gray-500">No templates found. Create a new one!</p>
      )}
  
      {/* Formulario para nueva plantilla */}
      {isCreatingNew && (
        <div className="mt-6 h-[calc(100vh-150px)]">
          <h3 className="text-lg font-semibold mb-4">Create New Template</h3>
          <div className="space-y-4 h-full">
            <input
              type="text"
              placeholder="Subject"
              value={newTemplate.subject}
              onChange={(e) => setNewTemplate({ subject: e.target.value, html_content: newTemplate.html_content })}
              className="w-full p-2 border rounded"
            />
            <textarea
              placeholder="HTML Content"
              value={newTemplate.html_content}
              onChange={(e) => setNewTemplate({ subject: newTemplate.subject, html_content: e.target.value })}
              className="w-full p-2 border rounded h-[calc(100%-150px)] resize-none"
            ></textarea>
            <div className="flex space-x-4">
              <button
                onClick={handleCreateTemplate}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                Save Template
              </button>
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Edición de Plantilla Seleccionada */}
      {selectedTemplate && (
        <div className="mt-6 h-[calc(100vh-150px)]">
          <h3 className="text-lg font-semibold mb-4">Edit Template</h3>
          <div className="space-y-4 h-full">
            <input
              type="text"
              placeholder="Subject"
              value={selectedTemplate.subject}
              onChange={(e) =>
                setSelectedTemplate({ ...selectedTemplate, subject: e.target.value })
              }
              className="w-full p-2 border rounded"
            />
            <textarea
              value={selectedTemplate.html_content}
              onChange={(e) =>
                setSelectedTemplate({ ...selectedTemplate, html_content: e.target.value })
              }
              className="w-full p-2 border rounded h-[calc(100%-150px)] resize-none"
            ></textarea>
            <div className="flex space-x-4">
              <button
                onClick={handleSaveTemplate}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                Save Changes
              </button>
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailTemplates;
