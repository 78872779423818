import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DeveloperLoanDetails = ({ projectId, onNextStep }) => {
  const { t } = useTranslation();
  const [purpose, setPurpose] = useState('');
  const [costeActivo, setCosteActivo] = useState('');
  const [costeObra, setCosteObra] = useState('');
  const [tipoInmueble, setTipoInmueble] = useState('');
  const [preventas, setPreventas] = useState('');
  const [arquitecto, setArquitecto] = useState('');
  const [comercializadora, setComercializadora] = useState('');
  const [images, setImages] = useState([]);
  const [renders, setRenders] = useState([]);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'imagen');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedImages.push(data.imageUrl);
        } else {
          setError('Error al subir la imagen');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
        console.error('Error al subir la imagen:', error);
      }
    }

    setImages([...images, ...uploadedImages]);
  };

  const handleRenderUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedRenders = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'render');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedRenders.push(data.imageUrl);
        } else {
          setError('Error al subir el render');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
        console.error('Error al subir el render:', error);
      }
    }

    setRenders([...renders, ...uploadedRenders]);
  };

  const handleImageDelete = (index, type) => {
    if (type === 'imagen') {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    } else if (type === 'render') {
      const newRenders = [...renders];
      newRenders.splice(index, 1);
      setRenders(newRenders);
    }
  };

  const handleSaveDetails = async () => {
    if (!purpose || !costeActivo || !costeObra || !tipoInmueble) {
      setError(t('errors.fillRequiredFields'));
      return;
    }

    const updatedDetails = {
      purpose,
      coste_activo: costeActivo,
      coste_obra: costeObra,
      tipo_inmueble: tipoInmueble,
      preventas,
      arquitecto,
      comercializadora,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/save-details`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ details: updatedDetails }),
      });

      if (response.ok) {
        if (onNextStep) onNextStep();
      } else {
        const errorData = await response.json();
        setError(t('errors.projectDetailsError') + errorData.message);
      }
    } catch (error) {
      setError(t('errors.connectionError'));
    }
  };

  return (
    <div className="flex space-x-4 bg-back">
      {/* Panel izquierdo para subir imágenes */}
      <div className="w-1/2">
        <h3 className="text-lg font-semibold mb-4">Subir Imágenes del Proyecto</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={image}
                alt={`Proyecto ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'imagen')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>

        <h3 className="text-lg font-semibold mb-4 mt-8">Subir Renders del Proyecto</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleRenderUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {renders.map((render, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={render}
                alt={`Render ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'render')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Panel derecho con el formulario */}
      <div className="w-1/2">
        <h3 className="text-2xl font-bold mb-4">{t('developerLoanDetails.title')}</h3>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.purpose')}</label>
          <textarea
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.costeActivo')}</label>
          <input
            type="number"
            value={costeActivo}
            onChange={(e) => setCosteActivo(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.costeObra')}</label>
          <input
            type="number"
            value={costeObra}
            onChange={(e) => setCosteObra(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.tipoInmueble')}</label>
          <input
            type="text"
            value={tipoInmueble}
            onChange={(e) => setTipoInmueble(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.preventas')}</label>
          <input
            type="text"
            value={preventas}
            onChange={(e) => setPreventas(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('developerLoanDetails.arquitecto')}</label>
          <input
            type="text"
            value={arquitecto}
            onChange={(e) => setArquitecto(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
        <label className="block text-sm font-medium">{t('developerLoanDetails.comercializadora')}</label>
        <input
          type="text"
          value={comercializadora}
          onChange={(e) => setComercializadora(e.target.value)}
          className="w-full px-4 py-2 border rounded-md text-black"
        />
      </div>

      <button
        onClick={handleSaveDetails}
        className="w-full bg-primary text-white py-2 rounded-md hover:bg-opacity-90"
        disabled={uploading}
      >
        {t('developerLoanDetails.saveButton')}
      </button>
    </div>
  </div>
  );
};

export default DeveloperLoanDetails;

