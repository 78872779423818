import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // i18n para traducciones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Step1Description = ({ data, onUpdate, errors }) => {
  const { t } = useTranslation(); // Hook para traducciones
  const [formData, setFormData] = useState({
    resumen: "",
    direccion: "",
    localidad: "",
    provincia: "",
    pais: t("defaultCountry"), // Traducción para país por defecto
  });

  // Sincronizar datos de la API con el estado local
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFormData((prev) => ({
        ...prev,
        resumen: data.resumen || "",
        direccion: data.direccion || "",
        localidad: data.localidad || "",
        provincia: data.provincia || "",
        pais: data.pais || t("defaultCountry"),
      }));
    }
  }, [data, t]);

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    onUpdate(updatedFormData); // Pasar datos actualizados al componente padre
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Formulario */}
      <div className="w-full lg:w-2/3">
        <h3 className="text-2xl font-semibold text-gray-800">{t("step1Title")}</h3>
        <p className="text-sm text-gray-600 mt-2">{t("step1Subtitle")}</p>
        <div className="mt-6 space-y-6">
          {/* Campo: Resumen */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("projectDescriptionLabel")} <span className="text-red-500">*</span>
            </label>
            <textarea
              name="resumen"
              value={formData.resumen}
              onChange={handleChange}
              rows={4}
              className={`mt-1 block w-full rounded-md border ${
                errors?.resumen ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            ></textarea>
            {errors?.resumen && <p className="text-sm text-red-500 mt-1">{errors.resumen}</p>}
          </div>

          {/* Campo: Dirección */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("addressLabel")} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.direccion ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.direccion && <p className="text-sm text-red-500 mt-1">{errors.direccion}</p>}
          </div>

          {/* Campos: Localidad y Provincia */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("cityLabel")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="localidad"
                value={formData.localidad}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${
                  errors?.localidad ? "border-red-500" : "border-gray-300"
                } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
              />
              {errors?.localidad && <p className="text-sm text-red-500 mt-1">{errors.localidad}</p>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("provinceLabel")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="provincia"
                value={formData.provincia}
                onChange={handleChange}
                className={`mt-1 block w-full rounded-md border ${
                  errors?.provincia ? "border-red-500" : "border-gray-300"
                } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
              />
              {errors?.provincia && (
                <p className="text-sm text-red-500 mt-1">{errors.provincia}</p>
              )}
            </div>
          </div>

          {/* Campo: País */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("countryLabel")} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.pais ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.pais && <p className="text-sm text-red-500 mt-1">{errors.pais}</p>}
          </div>
        </div>
      </div>

      {/* Consejos */}
      <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
        <h4 className="text-md font-medium text-gray-700 flex items-center">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
          {t("tipTitle")}
        </h4>
        <p className="text-sm text-gray-600 mt-2">
  {t("step1Tip").split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))}
</p>
      </div>
    </div>
  );
};

export default Step1Description;
