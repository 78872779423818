import React, { useState } from "react";
import FloatingLabelInput from "./FloatingLabelInput";

const AdministratorModal = ({ onClose, onSave, administrator, entityId }) => {
    const [name, setName] = useState(administrator?.administrator_name || "");
    const [documentId, setDocumentId] = useState(administrator?.document_id || "");
    const [nationality, setNationality] = useState(administrator?.nationality || "");
    const [maritalStatus, setMaritalStatus] = useState(administrator?.marital_status || "");
    const [fiscalAddress, setFiscalAddress] = useState(administrator?.fiscal_address || "");
    const [profession, setProfession] = useState(administrator?.profession || "");
    const [assets, setAssets] = useState(administrator?.assets || "");
    const [debts, setDebts] = useState(administrator?.debts || "");
    const token = localStorage.getItem("token");

    const handleSave = async () => {
        const adminData = {
            administrator_name: name,
            document_id: documentId,
            nationality,
            marital_status: maritalStatus,
            fiscal_address: fiscalAddress,
            profession,
            assets,
            debts,
            legal_entity_id: entityId,
        };

        try {
            const endpoint = administrator?.id
                ? `https://api.finanbid.com/api/entity/update-administrator/${administrator.id}`
                : `https://api.finanbid.com/api/entity/add-administrator`;

            const method = administrator?.id ? "PATCH" : "POST";

            const response = await fetch(endpoint, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(adminData),
            });

            if (!response.ok) throw new Error("Error al guardar el administrador");

            onSave(adminData); // Actualiza los datos en el Step7
            onClose(); // Cierra el modal
        } catch (error) {
            console.error("Error al guardar el administrador:", error);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[600]">
            <div className="bg-white p-6 rounded-lg w-full max-w-3xl">
                <h3 className="text-lg font-semibold mb-4">Administrador</h3>
                <div className="grid grid-cols-2 gap-4 text-sm">
                    <FloatingLabelInput
                        label="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nombre"
                    />
                    <FloatingLabelInput
                        label="Documento ID"
                        value={documentId}
                        onChange={(e) => setDocumentId(e.target.value)}
                        placeholder="Documento ID"
                    />
                    <FloatingLabelInput
                        label="Nacionalidad"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                        placeholder="Nacionalidad"
                    />
                    <FloatingLabelInput
                        label="Estado Civil"
                        value={maritalStatus}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                        placeholder="Estado Civil"
                    />
                    <FloatingLabelInput
                        label="Dirección Fiscal"
                        value={fiscalAddress}
                        onChange={(e) => setFiscalAddress(e.target.value)}
                        placeholder="Dirección Fiscal"
                    />
                    <FloatingLabelInput
                        label="Profesión"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        placeholder="Profesión"
                    />
                    <FloatingLabelInput
                        label="Activos"
                        value={assets}
                        onChange={(e) => setAssets(e.target.value)}
                        placeholder="Activos"
                    />
                    <FloatingLabelInput
                        label="Deudas"
                        value={debts}
                        onChange={(e) => setDebts(e.target.value)}
                        placeholder="Deudas"
                    />
                </div>
                <div className="flex justify-end mt-6">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={handleSave}>
                        Guardar
                    </button>
                    <button className="px-4 py-2 bg-gray-300 rounded ml-2" onClick={onClose}>
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdministratorModal;
