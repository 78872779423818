import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import AdministratorModal from './AdministratorModal';
import DocumentUpload from './DocumentUpload';
import ShareholderModal from './ShareholderModal';
import { useTranslation } from 'react-i18next';

const EditLegalEntityModal = ({ legalEntity, onClose, onSave = () => {}, projectId }) => {
    const { t } = useTranslation();

    const [businessName, setBusinessName] = useState(legalEntity?.entity?.business_name || '');
    const [businessCif, setBusinessCif] = useState(legalEntity?.entity?.business_cif || '');
    const [businessAddress, setBusinessAddress] = useState(legalEntity?.entity?.business_address || '');
    const [contactPhone, setContactPhone] = useState(legalEntity?.entity?.contact_phone || '');
    const [contactEmail, setContactEmail] = useState(legalEntity?.entity?.contact_email || '');
    const [yearOfIncorporation, setYearOfIncorporation] = useState(legalEntity?.entity?.year_of_incorporation || '');

    const [documents, setDocuments] = useState(legalEntity?.documents || []);
    const [adminData, setAdminData] = useState(legalEntity?.administrators[0] || null);
    const [shareholders, setShareholders] = useState(legalEntity?.shareholders || []);

    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
    const [isShareholderModalOpen, setIsShareholderModalOpen] = useState(false);
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

    useEffect(() => {
        if (legalEntity) {
            setDocuments(legalEntity.documents || []);
            setAdminData(legalEntity.administrators[0] || null);
            setShareholders(legalEntity.shareholders || []);
        }
    }, [legalEntity]);

    const associateLegalEntityToProject = async (entityId) => {
        try {
            const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/associate-legal-entity`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ legal_entity_id: entityId }),
            });

            if (!response.ok) {
                throw new Error('Error al asociar la entidad legal al proyecto');
            }

            console.log('Entidad legal asociada al proyecto');
        } catch (error) {
            console.error('Error en la asociación:', error);
        }
    };

    const handleOpenAdminModal = () => {
        if (adminData) {
            const confirmReplace = window.confirm('Ya existe un administrador. ¿Deseas reemplazarlo?');
            if (confirmReplace) {
                deleteCurrentAdmin();
                setIsAdminModalOpen(true);
            }
        } else {
            setIsAdminModalOpen(true);
        }
    };

    const deleteCurrentAdmin = async () => {
        try {
            await fetch(`https://api.finanbid.com/api/entity/delete-administrator/${adminData.id}`, {
                method: 'DELETE',
            });
            setAdminData(null);
        } catch (error) {
            console.error('Error al eliminar el administrador actual:', error);
        }
    };

    const handleSave = async () => {
        try {
            let entityId = legalEntity.entity.id;

            if (!entityId) {
                const response = await fetch('https://api.finanbid.com/api/entity/create-legal-entity', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        business_name: businessName,
                        business_cif: businessCif,
                        business_address: businessAddress,
                        contact_phone: contactPhone,
                        contact_email: contactEmail,
                        year_of_incorporation: yearOfIncorporation,
                    }),
                });

                const newEntity = await response.json();
                entityId = newEntity.id;
            } else {
                await fetch(`https://api.finanbid.com/api/entity/update-legal-entity/${entityId}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        business_name: businessName,
                        business_address: businessAddress,
                        contact_phone: contactPhone,
                        contact_email: contactEmail,
                    }),
                });
            }

            await associateLegalEntityToProject(entityId);

             // Obtener datos actualizados de la entidad legal
        const updatedEntityResponse = await fetch(`https://api.finanbid.com/api/entity/${entityId}`);
        const updatedEntity = await updatedEntityResponse.json();

            if (adminData && !legalEntity.administrators.length) {
                await fetch('https://api.finanbid.com/api/entity/add-administrator', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ...adminData, legal_entity_id: entityId }),
                });
            }

            for (const shareholder of shareholders) {
                if (!shareholder.id) {
                    await fetch('https://api.finanbid.com/api/entity/add-shareholder', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ ...shareholder, legal_entity_id: entityId }),
                    });
                }
            }

            onSave(updatedEntity);
        } catch (error) {
            console.error('Error al guardar los cambios de la entidad legal:', error);
        } finally {
            onClose();
        }
    };

    const confirmAndDelete = (type, id) => {
        if (window.confirm(`¿Estás seguro de eliminar este ${type}?`)) {
            if (type === 'document') {
                setDocuments(docs => docs.filter(doc => doc.id !== id));
                fetch(`https://api.finanbid.com/api/entity/delete-document/${id}`, { method: 'DELETE' })
                    .catch(error => console.error('Error al eliminar el documento:', error));
            } else if (type === 'shareholder') {
                setShareholders(shs => shs.filter(sh => sh.id !== id));
                fetch(`https://api.finanbid.com/api/entity/delete-shareholder/${id}`, { method: 'DELETE' })
                    .catch(error => console.error('Error al eliminar el accionista:', error));
            }
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-4/5 max-w-5xl">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">{t('Editar o Completar datos de la empresa')}</h3>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h4 className="text-lg font-semibold">{t('Datos de la Empresa')}</h4>
                        <div className="space-y-3 text-sm">
                            <input value={businessName} onChange={(e) => setBusinessName(e.target.value)} placeholder={t('Nombre de la Empresa')} className="w-full p-1 border-b" />
                            <input value={businessCif} onChange={(e) => setBusinessCif(e.target.value)} placeholder={t('CIF')} className="w-full p-1 border-b" />
                            <input value={businessAddress} onChange={(e) => setBusinessAddress(e.target.value)} placeholder={t('Dirección')} className="w-full p-1 border-b" />
                            <input value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} placeholder={t('Teléfono')} className="w-full p-1 border-b" />
                            <input value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} placeholder={t('Email')} className="w-full p-1 border-b" />
                            <input value={yearOfIncorporation} onChange={(e) => setYearOfIncorporation(e.target.value)} placeholder={t('Año de constitución')} className="w-full p-1 border-b" />
                        </div>

                        <div className="mt-4">
                            <h4 className="text-lg font-semibold flex justify-between items-center">
                                {t('Documentos')}
                                <button
                                    onClick={() => setIsDocumentModalOpen(true)}
                                    className="text-blue-500 p-1"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </h4>
                            <div className="mt-2">
                                {documents.map((doc) => (
                                    <div key={doc.id} className="flex justify-between items-center p-2 border-b text-sm">
                                        <span>{doc.document_type} ({doc.year})</span>
                                        <div className="flex items-center gap-2">
                                            <a href={doc.document_url} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faEye} />
                                            </a>
                                            <button onClick={() => confirmAndDelete('document', doc.id)} className="text-red-500">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <h4 className="text-lg font-semibold flex justify-between items-center">
                                {t('Administrador')}
                                <button onClick={handleOpenAdminModal} className="text-blue-500 p-1">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </h4>
                            {adminData ? (
                                <div className="mt-2 p-2 border rounded text-sm">
                                    <p><strong>{t('Nombre')}:</strong> {adminData.administrator_name}</p>
                                    <p><strong>{t('Profesión')}:</strong> {adminData.profession}</p>
                                    <p><strong>{t('Dirección')}:</strong> {adminData.fiscal_address}</p>
                                </div>
                            ) : (
                                <p className="text-sm text-gray-500">{t('No hay administrador asociado')}</p>
                            )}
                        </div>

                        <div>
                            <h4 className="text-lg font-semibold flex justify-between items-center">
                                {t('Accionistas')}
                                <button onClick={() => setIsShareholderModalOpen(true)} className="text-blue-500 p-1">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </h4>
                            <div className="mt-2">
                                {shareholders.map((shareholder) => (
                                    <div key={shareholder.id} className="flex justify-between items-center p-2 border-b text-sm">
                                        <div className="flex-1"><span>{shareholder.shareholder_name} ({shareholder.type})</span></div>
                                        <div className="text-right w-20"><span>{shareholder.participation}%</span></div>
                                        <button onClick={() => confirmAndDelete('shareholder', shareholder.id)} className="text-red-500 ml-2">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex justify-end">
                    <button type="button" onClick={onClose} className="text-gray-600 mr-4">{t('Cancelar')}</button>
                    <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">{t('Guardar cambios')}</button>
                </div>

                {isAdminModalOpen && <AdministratorModal onClose={() => setIsAdminModalOpen(false)} onSave={(admin) => setAdminData(admin)} />}
                {isShareholderModalOpen && <ShareholderModal onClose={() => setIsShareholderModalOpen(false)} onSave={(shareholder) => setShareholders([...shareholders, shareholder])} />}
                {isDocumentModalOpen && (
                    <DocumentUpload
                        onClose={() => setIsDocumentModalOpen(false)}
                        setDocuments={setDocuments}
                        legalEntityId={legalEntity.entity.id}
                        documents={documents}
                    />
                )}
            </div>
        </div>
    );
};

export default EditLegalEntityModal;
