import React from 'react';

const UserSettings = () => {
  return (
    <div>
      <h2 className="text-xl font-bold text-gray-700">Configuraciones</h2>
      {/* Aquí puedes agregar opciones de configuración para el usuario */}
    </div>
  );
}

export default UserSettings;
