import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faUpload, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Estilos de Tippy.js


const Step6ProjectImages = ({ projectId, project, onUpdate, errors }) => {
    const { t } = useTranslation();
    const [images, setImages] = useState([]);
    const [newImage, setNewImage] = useState({ type: "", files: [] });
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");

    // Función para obtener las imágenes desde la API
    const fetchImages = async () => {
        try {
            const response = await fetch(
                `https://api.finanbid.com/api/projects/${projectId}/images`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Error al obtener imágenes del proyecto");
            }

            const data = await response.json();

            // Filtrar las imágenes válidas y marcar la portada
            const filteredImages = data
                .filter((image) => {
                    const type = image.image_type?.toLowerCase();
                    return type === "imagen" || type === "render";
                })
                .map((image) => ({
                    ...image,
                    is_cover: image.image_url === project?.url_imagen_principal,
                }));

            setImages(filteredImages);
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect para cargar las imágenes al montar el componente
    useEffect(() => {
        fetchImages();
    }, [projectId]);

    const handleFileSelection = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setNewImage((prev) => ({ ...prev, files: selectedFiles }));
    };

    const handleUpload = async () => {
        if (!newImage.type || newImage.files.length === 0) {
            alert(t("missingImageFields"));
            return;
        }

        setLoading(true);
        try {
            for (const file of newImage.files) {
                const formData = new FormData();
                formData.append("image", file);
                formData.append("image_type", newImage.type);
                formData.append("project_id", projectId);

                const response = await fetch("https://api.finanbid.com/api/upload-image", {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Error al subir la imagen");
                }
            }

            // Recarga las imágenes actualizadas
            await fetchImages();
            setNewImage({ type: "", files: [] }); // Limpia el formulario
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (imageId) => {
        if (!window.confirm(t("confirmDeleteImage"))) return;

        try {
            const response = await fetch(
                `https://api.finanbid.com/api/images/${imageId}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Error al eliminar la imagen");
            }

            // Recarga las imágenes actualizadas
            await fetchImages();
        } catch (error) {
            console.error(error);
        }
    };

    const setCoverImage = async (imageId, imageUrl) => {
        try {
            const response = await fetch(
                `https://api.finanbid.com/api/admin/projects/${projectId}/field`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ field: "url_imagen_principal", value: imageUrl }),
                }
            );

            if (!response.ok) {
                throw new Error("Error al establecer la imagen de portada");
            }

            // Actualizar el estado local de las imágenes
            setImages((prevImages) =>
                prevImages.map((image) => ({
                    ...image,
                    is_cover: image.id === imageId, // Actualizar la imagen seleccionada como portada
                }))
            );
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="flex flex-col lg:flex-row gap-8">
            <div className="w-full lg:w-2/3 space-y-6">
                <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">{t("projectImages")}</h3>
                    <div className="overflow-y-auto max-h-[400px]">
                        <table className="w-full border-collapse border border-gray-300 text-sm">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="border px-2 py-1 text-left">{t("imageType")}</th>
                                    <th className="border px-2 py-1 text-left">{t("actions")}</th>
                                    <th className="border px-2 py-1 text-center">{t("coverImage")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {images.map((image) => (
                                    <tr key={image.id}>
                                        <td className="border px-2 py-1">{image.image_type}</td>
                                        <td className="border px-2 py-1 text-center flex justify-center gap-2">
                                            <Tippy
                                                content={
                                                    <img
                                                        src={image.image_url}
                                                        alt={t("preview")}
                                                        className="max-w-[150px] max-h-[150px] object-contain"
                                                    />
                                                }
                                            >
                                                <button className="text-blue-500 hover:text-blue-600">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </Tippy>
                                            <button
                                                onClick={() => handleDelete(image.id)}
                                                className="text-red-500 hover:text-red-600"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                        <td className="border px-2 py-1 text-center">
                                            <input
                                                type="radio"
                                                name="coverImage"
                                                checked={image.is_cover}
                                                onChange={() => setCoverImage(image.id, image.image_url)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                {images.length === 0 && (
                                    <tr>
                                        <td colSpan="3" className="text-center text-gray-500 py-4">
                                            {t("noImages")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>




                <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                    <h4 className="text-md font-semibold text-gray-800 mb-4">{t("uploadNewImages")}</h4>
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-grow">
                                <label className="block text-sm font-medium mb-1">{t("imageType")}</label>
                                <select
                                    value={newImage.type}
                                    onChange={(e) =>
                                        setNewImage((prev) => ({ ...prev, type: e.target.value }))
                                    }
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="">{t("selectImageType")}</option>
                                    <option value="Imagen">Imagen</option>
                                    <option value="Render">Render</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">{t("selectFiles")}</label>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileSelection}
                                    className="p-2 border rounded"
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleUpload}
                            className={`mt-2 px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 ${loading ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            disabled={loading}
                        >
                            {t("uploadImages")}
                        </button>
                    </div>
                </div>

                {errors?.images && <p className="text-red-500 text-sm mt-2">{errors.images}</p>}
            </div>

            <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
                <h4 className="text-md font-medium text-gray-700 flex items-center">
                    <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
                    {t("tipTitle")}
                </h4>
                <p className="text-sm text-gray-600 mt-2">
                    {t("step6Tip")
                        .split("\n")
                        .map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>
            </div>
        </div>

    );
};

export default Step6ProjectImages;
