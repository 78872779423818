import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faEye, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal'; // Usar react-modal o cualquier biblioteca de modales
import AdministratorModal from '../dashuser/AdministratorModal';

const LegalEntitiesDetails = ({ legalEntity, onUpdateField, onUploadDocument }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
    const [isShareholderModalOpen, setIsShareholderModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        ...legalEntity,
        verification_status: legalEntity.verification_status || "Pending",
    });

    if (!legalEntity) {
        return <div>Loading...</div>; // Mostrar un mensaje de carga o spinner
    }

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const handleDocumentView = (url) => {
        window.open(url, '_blank');
    };

    const handleAdminModalToggle = () => {
        setIsAdminModalOpen(!isAdminModalOpen);
    };

    const handleShareholderModalToggle = () => {
        setIsShareholderModalOpen(!isShareholderModalOpen);
    };

    const handleBlur = async (field, value) => {
        if (onUpdateField) {
            try {
                await onUpdateField(field, value); // Realiza la llamada a la API
                setFormData((prevData) => ({ ...prevData, [field]: value })); // Actualiza el estado local
            } catch (error) {
                console.error(`Error updating ${field}:`, error);
            }
        }
    };
    // Función para cambiar el color según el estado de verificación
    const getVerificationStatusColor = () => {
        switch (formData.verification_status) {
            case "OK":
                return "text-green-500";
            case "Pending":
                return "text-orange-500";
            case "Failed":
                return "text-red-500";
            default:
                return "";
        }
    };

    const confirmAndDelete = (type, id) => {
        if (window.confirm(`¿Estás seguro de eliminar este ${type}?`)) {
            if (type === 'document') {
                
                fetch(`https://api.finanbid.com/api/entity/delete-document/${id}`, { method: 'DELETE' })
                    .catch(error => console.error('Error al eliminar el documento:', error));
            } else if (type === 'shareholder') {
                
                fetch(`https://api.finanbid.com/api/entity/delete-shareholder/${id}`, { method: 'DELETE' })
                    .catch(error => console.error('Error al eliminar el accionista:', error));
            }
        }
    };

    const handleSaveAdmin = async (adminData) => {
        if (!adminData) return;

        try {
            const token = localStorage.getItem('token'); // Si necesitas autenticación
            const response = await fetch('https://api.finanbid.com/api/entity/add-administrator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Incluye este encabezado si la API lo requiere
                },
                body: JSON.stringify({
                    ...adminData,
                    legal_entity_id: legalEntity.id, // Pasar el ID de la entidad legal
                }),
            });

            if (!response.ok) {
                throw new Error('Error al añadir el administrador');
            }

            const newAdmin = await response.json();
            console.log('Administrador añadido con éxito:', newAdmin);

            // Actualiza la UI localmente si es necesario
            setFormData((prevData) => ({
                ...prevData,
                administrators: [...(prevData.administrators || []), newAdmin],
            }));
        } catch (error) {
            console.error('Error al añadir el administrador:', error);
        }
    };


    return (
        <div className="bg-white p-6 shadow-md rounded-lg">
            <div className="flex justify-between items-center cursor-pointer" onClick={handleToggle}>
                <h2 className="text-lg font-semibold">{t('legalEntitiesDetails.title')}</h2>
                <div className="flex items-center">
                    <label className="mr-2 text-sm font-medium text-gray-600">Verification Status:</label>
                    <select
                        value={formData.verification_status || ""}
                        onChange={(e) => handleBlur('verification_status', e.target.value)}
                        className={`border rounded p-1 text-sm ${getVerificationStatusColor()}`}
                    >
                        <option value="OK">OK</option>
                        <option value="Pending">Pending</option>
                        <option value="Failed">Failed</option>
                    </select>
                    <FontAwesomeIcon className="ml-4" icon={expanded ? faChevronDown : faChevronRight} />
                </div>
            </div>

            {expanded && (
                <>
                    <div className="mt-4 p-4 border rounded-lg bg-gray-50">
                        <h3 className="text-md font-semibold mb-2">{t('legalEntitiesDetails.companyData')}</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.business_name')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.business_name}
                                    onBlur={(e) => handleBlur('business_name', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                            {/* Agregar los otros campos en el mismo formato */}
                            {/* Razón Social, NIF, Sede Social, etc. */}
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.business_cif')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.business_cif}
                                    onBlur={(e) => handleBlur('business_cif', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                            {/* Otros campos en dos columnas */}
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.business_address')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.business_address}
                                    onBlur={(e) => handleBlur('business_address', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.contact_phone')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.contact_phone}
                                    onBlur={(e) => handleBlur('contact_phone', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.contact_email')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.contact_email}
                                    onBlur={(e) => handleBlur('contact_email', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.business_website')}</label>
                                <input
                                    type="text"
                                    defaultValue={legalEntity.business_website}
                                    onBlur={(e) => handleBlur('business_website', e.target.value)}
                                    className="w-full border rounded p-2 text-sm"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Documentos */}
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        {['incorporation_pdf', 'real_ownership_pdf', 'last_tax_pdf'].map((docType, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <span>{t(`legalEntitiesDetails.${docType}`)}</span>
                                <div className="flex items-center space-x-2">
                                    <button onClick={() => handleDocumentView(legalEntity[docType])} className="text-blue-600">
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                    <button onClick={() => onUploadDocument(docType)} className="text-gray-600">
                                        <FontAwesomeIcon icon={faUpload} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Campos adicionales */}
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.assets')}</label>
                            <textarea
                                defaultValue={legalEntity.assets}
                                onBlur={(e) => handleBlur('assets', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                                rows="3"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.debts')}</label>
                            <textarea
                                defaultValue={legalEntity.debts}
                                onBlur={(e) => handleBlur('debts', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                                rows="3"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.partners_cv')}</label>
                            <textarea
                                defaultValue={legalEntity.partners_cv}
                                onBlur={(e) => handleBlur('partners_cv', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                                rows="4"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600">{t('legalEntitiesDetails.promotions_history')}</label>
                            <textarea
                                defaultValue={legalEntity.promotions_history}
                                onBlur={(e) => handleBlur('promotions_history', e.target.value)}
                                className="w-full border rounded p-2 text-sm"
                                rows="4"
                            />
                        </div>
                    </div>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
  {/* Columna izquierda: Datos del Administrador */}
  <div>
    <h3 className="text-lg font-semibold mb-4">{t('legalEntitiesDetails.administrator')}</h3>
    {legalEntity.administrators && legalEntity.administrators.length > 0 ? (
      <div className="space-y-4">
        {legalEntity.administrators.map((admin) => (
          <div key={admin.id} className="relative p-4 border rounded-lg bg-gray-50 shadow-md">
            {/* Botón de papelera */}
            <button
              onClick={async () => {
                try {
                  await fetch(`https://api.finanbid.com/api/entity/delete-administrator/${admin.id}`, {
                    method: 'DELETE',
                  });
                  // Actualizar el estado después de borrar
                  setLegalEntity((prev) => ({
                    ...prev,
                    administrators: prev.administrators.filter((a) => a.id !== admin.id),
                  }));
                } catch (error) {
                  console.error('Error al eliminar el administrador actual:', error);
                }
              }}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>

            {/* Información del administrador */}
            <p><strong>{t('Nombre')}:</strong> {admin.administrator_name}</p>
            <p><strong>{t('DNI/NIE/ID')}:</strong> {admin.document_id}</p>
            <p><strong>{t('Nacionalidad')}:</strong> {admin.nationality}</p>
            <p><strong>{t('Estado Civil')}:</strong> {admin.marital_status}</p>
            <p><strong>{t('Dirección Fiscal')}:</strong> {admin.fiscal_address}</p>
            <p><strong>{t('Profesión')}:</strong> {admin.profession}</p>
            <p><strong>{t('Activos')}:</strong> {admin.assets}</p>
            <p><strong>{t('Deudas')}:</strong> {admin.debts}</p>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-sm text-gray-500">{t('No hay administrador asociado')}</p>
    )}
  </div>

  {/* Columna derecha: Listado de Shareholders */}
  <div>
    <h3 className="text-lg font-semibold mb-4">{t('legalEntitiesDetails.shareholders')}</h3>
    {legalEntity.shareholders && legalEntity.shareholders.length > 0 ? (
      <div className="space-y-2">
        {legalEntity.shareholders.map((shareholder) => (
          <div key={shareholder.id} className="flex justify-between items-center p-2 border rounded-lg shadow-sm bg-white">
            <div className="flex-1">
              <span>{shareholder.shareholder_name} ({shareholder.type})</span>
            </div>
            <div className="text-right w-20">
              <span>{shareholder.participation}%</span>
            </div>
            <button
              onClick={() => confirmAndDelete('shareholder', shareholder.id)}
              className="text-red-500 ml-2 hover:text-red-700"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-sm text-gray-500">{t('No hay shareholders asociados')}</p>
    )}
  </div>
</div>


                    {/* Botones para ver modales de administrador y socios */}
                    <div className="mt-4 flex space-x-4">
                        <button onClick={handleAdminModalToggle} className="px-4 py-2 bg-blue-500 text-white rounded">
                            {t('legalEntitiesDetails.viewAdmin')}
                        </button>
                        <button onClick={handleShareholderModalToggle} className="px-4 py-2 bg-blue-500 text-white rounded">
                            {t('legalEntitiesDetails.viewShareholders')}
                        </button>
                    </div>

                    {/* Modal de Administrador */}
                    {isAdminModalOpen && (
                        <AdministratorModal
                            onClose={handleAdminModalToggle}
                            onSave={(adminData) => {
                                handleSaveAdmin(adminData); // Llama a la función para añadir el administrador
                                handleAdminModalToggle();  // Cierra el modal
                            }}
                        />
                    )}

                    {/* Modal de Socios */}
                    <Modal isOpen={isShareholderModalOpen} onRequestClose={handleShareholderModalToggle} contentLabel="Shareholders Modal">
                        <h2>{t('legalEntitiesDetails.shareholdersModalTitle')}</h2>
                        {/* Listado de socios */}
                        {/* shareholder_name, type, participation */}
                        <button onClick={handleShareholderModalToggle}>{t('legalEntitiesDetails.close')}</button>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default LegalEntitiesDetails;
