import React, { useState } from 'react';

const ProjectAssistant = ({
    components,
    onClose,
    projectData,
    setRepagoPrestamo,
    setAmountRequested,
    setCapitalInvertido,
    setDuracionPrestamo,
    setResumen,
    setDireccion,
    setLocalidad,
    setProvincia,
    setPais,
    onUploadDocument,
    onSaveProjectDetails,
    onDeleteImage,
    onImageUpload,
    details,
    onSave
}) => {
    const [stepIndex, setStepIndex] = useState(0);
    const isLastStep = stepIndex === components.length - 1;

    const handleNext = () => {
        if (!isLastStep) {
            setStepIndex((prevStepIndex) => prevStepIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (stepIndex > 0) {
            setStepIndex((prevStepIndex) => prevStepIndex - 1);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-11/12 md:w-3/4 lg:w-1/2 xl:w-2/3 p-6 rounded-lg shadow-lg relative">
                {/* Texto de ayuda */}
                <div className="mb-4 text-center text-gray-700">
                    <h2 className="text-xl font-semibold mb-2">
                        Paso {stepIndex + 1}: {components[stepIndex].title}
                    </h2>
                    <p>{components[stepIndex].helpText}</p>
                </div>

                {/* Componente actual con datos del proyecto */}
                <div className="my-6">
                    {React.cloneElement(components[stepIndex].component, { 
                        projectData,
                        setRepagoPrestamo,
                        setAmountRequested,
                        setCapitalInvertido,
                        setDuracionPrestamo,
                        setResumen,
                        setDireccion,
                        setLocalidad,
                        setProvincia,
                        setPais,
                        onUploadDocument,
                        onSaveProjectDetails,
                        onDeleteImage,
                        onImageUpload,
                        details,
                        onSave
                    })}
                </div>

                {/* Botones de navegación */}
                <div className="flex justify-between mt-4">
                    <button 
                        onClick={handlePrevious} 
                        disabled={stepIndex === 0}
                        className="bg-gray-200 px-4 py-2 rounded disabled:opacity-50"
                    >
                        Anterior
                    </button>
                    <button 
                        onClick={onClose} 
                        className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                        Cerrar
                    </button>
                    <button 
                        onClick={handleNext} 
                        disabled={isLastStep}
                        className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    >
                        {isLastStep ? "Finalizar" : "Siguiente"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProjectAssistant;
