import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AdminProjects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchProjects = async () => {
        try {
          const response = await fetch('https://api.finanbid.com/api/admin/projects', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setProjects(data);
          } else if (response.status === 401 || response.status === 403) {
            navigate('/login');
          } else {
            console.error(t('adminProjects.errorLoadingProjects'));
          }
        } catch (error) {
          console.error(t('adminProjects.errorLoadingProjects'), error);
        }
      };

      fetchProjects();
    }
  }, [t, navigate]);

  const handleProjectClick = (projectId) => {
    // Navega a la ruta específica del proyecto con el projectId en la URL
    navigate(`/admin/projects/${projectId}`);
  };

  const getProductTypeName = (productTypeId) => {
    return t(`projectUpload.products.${productTypeId}`, { defaultValue: productTypeId });
  };

  const calculateLTV = (amountRequested, collaterals) => {
    if (!amountRequested || !collaterals || collaterals.length === 0) return 0;
    const totalCollateralValue = collaterals.reduce((sum, collateral) => {
      const value = parseFloat(collateral.valor_total_garantia || 0);
      return sum + value;
    }, 0);
    return totalCollateralValue ? Math.round((amountRequested / totalCollateralValue) * 100) : 0;
  };

  return (
    <div className="p-6 bg-gray-100 min-h-full">
      <h2 className="text-2xl font-bold mb-6">{t('adminProjects.title')}</h2>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.id')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.date')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.productType')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.province')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.userEmail')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.requestedAmount')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.ltv')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.followUps')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.offersReceived')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('adminProjects.status')}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr
                key={project.id}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => handleProjectClick(project.id)} // Pasamos el ID del proyecto
              >
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">{project.id}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">
                  {new Date(project.created_at).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">
                  {getProductTypeName(project.product_type)}
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">{project.provincia}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">{project.email}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">
                  ${project.amount_requested.toLocaleString()}
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">
                  {calculateLTV(project.amount_requested, project.collaterals)}%
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">12</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm text-gray-700">2</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm font-semibold">
                  <span
                    className={`text-sm font-semibold ${project.status === 'Incompleto' || project.status === 'incompleto'
                        ? 'text-blue-500'
                        : project.status === 'En Revisión' || project.status === 'En Modificación'
                          ? 'text-orange-500'
                          : project.status === 'Visible' || project.status === 'Finalizado'
                            ? 'text-green-500'
                            : 'text-red-500'
                      }`}
                  >
                    {t(`adminProjects.statuses.${project.status}`)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminProjects;
