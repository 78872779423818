import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SectorsManagement = () => {
  const [sectors, setSectors] = useState([]);
  const [products, setProducts] = useState([]);
  const [subsectors, setSubsectors] = useState([]);

  const [newSector, setNewSector] = useState({ name: '', description: '' });
  const [newProduct, setNewProduct] = useState({ name: '', sector_id: '', description: '' });
  const [newSubsector, setNewSubsector] = useState({ name: '', sector_id: '', description: '' });

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
      const [sectorsRes, productsRes, subsectorsRes] = await Promise.all([
        axios.get('https://api.finanbid.com/api/sectors'),
        axios.get('https://api.finanbid.com/api/productos'),
        axios.get('https://api.finanbid.com/api/subsectors'),
      ]);
      setSectors(sectorsRes.data);
      setProducts(productsRes.data);
      setSubsectors(subsectorsRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleActive = async (id, type, isActive) => {
    try {
      const endpoint = type === 'sector' ? 'https://api.finanbid.com/api/sectors' : type === 'product' ? 'https://api.finanbid.com/api/products' : 'https://api.finanbid.com/api/subsectors';
      await axios.patch(`${endpoint}/${id}`, { is_active: !isActive });
      fetchAllData();
    } catch (error) {
      console.error('Error toggling active status:', error);
    }
  };

  const addNewEntry = async (type) => {
    try {
      const data = type === 'sector' ? newSector : type === 'product' ? newProduct : newSubsector;
      const endpoint = type === 'sector' ? 'https://api.finanbid.com/api/sectors' : type === 'product' ? 'https://api.finanbid.com/api/products' : 'https://api.finanbid.com/api/subsectors';
      await axios.post(endpoint, data);
      fetchAllData();

      if (type === 'sector') setNewSector({ name: '', description: '' });
      else if (type === 'product') setNewProduct({ name: '', sector_id: '', description: '' });
      else setNewSubsector({ name: '', sector_id: '', description: '' });
    } catch (error) {
      console.error('Error adding new entry:', error);
    }
  };

  const renderTable = (data, type) => (
    <div className="mb-6">
      <h3 className="text-md font-semibold mb-2 capitalize">{type}s</h3>
      <table className="w-full border border-gray-300 bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4">ID</th>
            <th className="py-2 px-4">Name</th>
            <th className="py-2 px-4">Description</th>
            <th className="py-2 px-4">Active</th>
            <th className="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.id}</td>
              <td className="py-2 px-4">{item.name}</td>
              <td className="py-2 px-4">{item.description || 'N/A'}</td>
              <td className="py-2 px-4">{item.is_active ? 'Yes' : 'No'}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => toggleActive(item.id, type, item.is_active)}
                  className={`px-4 py-1 rounded ${
                    item.is_active ? 'bg-red-500 text-white' : 'bg-green-500 text-white'
                  }`}
                >
                  {item.is_active ? 'Deactivate' : 'Activate'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="p-6 space-y-8">
      <h2 className="text-lg font-semibold mb-4">Sectors Management</h2>

      {/* Sectores */}
      {renderTable(sectors, 'sector')}
      <div className="flex items-center space-x-4">
        <input
          type="text"
          placeholder="Name"
          value={newSector.name}
          onChange={(e) => setNewSector({ ...newSector, name: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <input
          type="text"
          placeholder="Description"
          value={newSector.description}
          onChange={(e) => setNewSector({ ...newSector, description: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <button
          onClick={() => addNewEntry('sector')}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Sector
        </button>
      </div>

      {/* Subsectores */}
      {renderTable(subsectors, 'subsector')}
      <div className="flex items-center space-x-4">
        <input
          type="text"
          placeholder="Name"
          value={newSubsector.name}
          onChange={(e) => setNewSubsector({ ...newSubsector, name: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <select
          value={newSubsector.sector_id}
          onChange={(e) => setNewSubsector({ ...newSubsector, sector_id: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        >
          <option value="">Select Sector</option>
          {sectors.map((sector) => (
            <option key={sector.id} value={sector.id}>
              {sector.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Description"
          value={newSubsector.description}
          onChange={(e) => setNewSubsector({ ...newSubsector, description: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <button
          onClick={() => addNewEntry('subsector')}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Subsector
        </button>
      </div>

      {/* Productos */}
      {renderTable(products, 'product')}
      <div className="flex items-center space-x-4">
        <input
          type="text"
          placeholder="Name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <select
          value={newProduct.sector_id}
          onChange={(e) => setNewProduct({ ...newProduct, sector_id: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        >
          <option value="">Select Sector</option>
          {sectors.map((sector) => (
            <option key={sector.id} value={sector.id}>
              {sector.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Description"
          value={newProduct.description}
          onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
          className="border rounded px-2 py-1 w-1/3"
        />
        <button
          onClick={() => addNewEntry('product')}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Product
        </button>
      </div>
    </div>
  );
};

export default SectorsManagement;