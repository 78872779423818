import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProjectOverview from './ProjectOverview';
import LoanDetails from './LoanDetails';
import DocumentsViewer from './DocumentsViewer';
import CollateralDetails from './CollateralDetails';
import VerificationStatusControl from './VerificationStatusControl';
import BridgeLoanDetails from './BridgeLoanDetails';
import DeveloperLoanDetails from './DeveloperLoanDetails';
import FlippingLoanDetails from './FlippingLoanDetails';
import LandPurchaseDetails from './LandPurchaseDetails'; // Asegúrate de importar LandPurchaseDetails si es necesario
import LegalEntitiesDetails from './LegalEntitiesDetails';
import ImageDetails from './ProjectImages';
import ProjectAssistantModal from "../dashuser/ProjectAssistantModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";

const AdminProject = () => {
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [message, setMessage] = useState(''); // Define `message` como parte del estado
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  
  const openModal = (projectId) => {
    setSelectedProjectId(projectId);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProjectId(null);
  };


  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch project data');

        const data = await response.json();
        setProjectData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectId]);

  const onUpdateDocumentStatus = async (documentId, newStatus) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/documents/${documentId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ verification_status: newStatus }),
      });

      if (!response.ok) throw new Error('Error updating document status');

      // Aquí puedes opcionalmente actualizar el estado local de projectData
      setProjectData((prevData) => ({
        ...prevData,
        documents: prevData.documents.map((doc) =>
          doc.id === documentId ? { ...doc, verification_status: newStatus } : doc
        ),
      }));
    } catch (error) {
      console.error(`Error updating document status for document ${documentId}:`, error);
    }
  };
  const handleDeleteImage = async (imageId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/images/${imageId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Error al eliminar la imagen');

      // Actualizar el estado local para reflejar la eliminación
      setProjectData((prevData) => ({
        ...prevData,
        images: prevData.images.filter((image) => image.id !== imageId),
      }));
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
    }
  };

  const handleImageUpload = (newImage) => {
    setProjectData((prevData) => ({
      ...prevData,
      images: [...prevData.images, newImage],
    }));
  };

  // Función para llamar a la API y generar el Due Diligence
  const generateDueDiligence = async () => {
    setLoading(true);
    setMessage('');
  
    try {
      const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/due-diligence`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error del servidor:', errorData);
        setMessage('Error al generar Due Diligence');
        return;
      }
  
      const data = await response.json();
      console.log('JSON generado:', data);
      setMessage('Due Diligence generado exitosamente');
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error de conexión al generar Due Diligence');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (!response.ok) throw new Error('Failed to update status');

      setProjectData((prevData) => ({ ...prevData, project: { ...prevData.project, status: newStatus } }));
    } catch (err) {
      console.error('Error updating status:', err);
    }
  };

  const handleCollateralFieldUpdate = async (collateralId, field, value) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/collateral/${collateralId}/field`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ field, value }),
      });
      // Actualizar el estado local si es necesario
    } catch (error) {
      console.error(`Error updating ${field} for collateral ${collateralId}:`, error);
    }
  };

  const handleFieldUpdate = async (field, value) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/field`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ field, value }), // Enviar `field` y `value` en el cuerpo de la solicitud
      });

      if (!response.ok) throw new Error('Failed to update project field');

      // Actualizar el estado local después de un cambio exitoso
      setProjectData((prevData) => ({
        ...prevData,
        project: {
          ...prevData.project,
          [field]: value,
        },
      }));
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleLegalFieldUpdate = async (field, value) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/legal/field`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ field, value })
      });

      if (!response.ok) {
        throw new Error(`Failed to update ${field}`);
      }

      // Actualiza el estado localmente después de una actualización exitosa
      setProjectData((prevData) => ({
        ...prevData,
        legalEntity: {
          ...prevData.legalEntity,
          [field]: value
        }
      }));
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };




  const handleLoanFieldUpdate = async (field, value) => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/loan_request/field`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ field, value }),
      });

      setProjectData((prevData) => ({
        ...prevData,
        loanDetails: {
          ...prevData.loanDetails,
          [field]: value,
        },
      }));
    } catch (error) {
      console.error(`Error updating loan field ${field}:`, error);
    }
  };



  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">
          {t(`projectUpload.products.${projectData.project.product_type}`)} - Id. {projectId}
        </h1>
        <button
          onClick={generateDueDiligence}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
          disabled={loading}
        >
          {loading ? 'Generando...' : 'Generar Due Diligence'}
        </button>
        <button
    onClick={() => openModal(projectId)}
    className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
  >
    <FontAwesomeIcon icon={faMagicWandSparkles} className="mr-2" />
    Asistente Mágico
  </button>
      </div>
     

{isModalOpen && (
  <ProjectAssistantModal
    isOpen={isModalOpen}
    onClose={closeModal}
    projectId={selectedProjectId}
  />
)}
      {message && <p className="mt-4 text-green-500 font-semibold">{message}</p>}

      <div className="space-y-6">
        {/* Overview of basic project information */}
        <ProjectOverview projectData={projectData.project} onUpdateField={handleFieldUpdate} />

        {/* Loan Request Details */}
        <LoanDetails data={{ ...projectData.loanDetails, project_id: projectId }} onUpdateField={handleLoanFieldUpdate} />

        {/* Legal Entity Details */}
        {projectData.legalEntity && (
          <LegalEntitiesDetails
            legalEntity={projectData.legalEntity}
            onUpdateField={handleLegalFieldUpdate}
          />
        )}

        {/* Collateral Details */}
        <CollateralDetails collaterals={projectData.collaterals} projectId={projectId} onUpdateField={handleCollateralFieldUpdate} />

        {/* Project Documents */}
        <DocumentsViewer documents={projectData.documents} onUpdateDocumentStatus={onUpdateDocumentStatus} />

        {/* Specific Loan Type Details */}
        {(projectData.project.product_type === 4 || projectData.project.product_type === 5) && (
          <BridgeLoanDetails data={projectData.specificLoanDetails} />
        )}
        {projectData.project.product_type === 6 && (
          <DeveloperLoanDetails data={projectData.specificLoanDetails} />
        )}
        {projectData.project.product_type === 7 && (
          <FlippingLoanDetails data={projectData.specificLoanDetails} />
        )}
        {projectData.project.product_type === 1 && (
          <LandPurchaseDetails data={projectData.specificLoanDetails} />
        )}

        {/* Image Details */}
      <ImageDetails
        images={projectData.images || []}
        projectId={projectId}
        onDeleteImage={handleDeleteImage}
        onImageUpload={(selectedFile, imageType) =>
          handleImageUpload({ selectedFile, imageType })
        }
      />
      </div>
      
    </div>
  );

};

export default AdminProject;

