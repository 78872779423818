import React from 'react';

const VerificationStatusControl = ({ status, onChangeStatus }) => {
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    onChangeStatus(newStatus);
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <h3 className="text-lg font-semibold mb-4">Project Status</h3>
      <select
        value={status}
        onChange={handleStatusChange}
        className="w-full p-2 border rounded-md focus:ring focus:ring-blue-300"
      >
        <option value="pending">Pending</option>
        <option value="approved">Approved</option>
        <option value="rejected">Rejected</option>
        <option value="visible">Visible</option>
      </select>
    </div>
  );
};

export default VerificationStatusControl;
