import React from 'react';
import { useTranslation } from 'react-i18next';

const Bidfunding = () => {
  const { t } = useTranslation();

  return (
    <section className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8"> {/* Añadí padding horizontal */}
        <h2 className="text-4xl font-bold mb-4">{t('bidfunding.title')}</h2>
        <p className="text-lg text-gray-700 mb-8" style={{ whiteSpace: 'pre-line' }}>
  {t('bidfunding.description')}
</p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-2xl font-semibold mb-3">{t('bidfunding.advantages.bestConditions')}</h3>
            <p>{t('bidfunding.advantages.bestConditionsDescription')}</p>
          </div>
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-2xl font-semibold mb-3">{t('bidfunding.advantages.simplifiedProcess')}</h3>
            <p>{t('bidfunding.advantages.simplifiedProcessDescription')}</p>
          </div>
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-2xl font-semibold mb-3">{t('bidfunding.advantages.confidentiality')}</h3>
            <p>{t('bidfunding.advantages.confidentialityDescription')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bidfunding;
