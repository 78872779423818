import React, { useState } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Search from './SearchOperations';
import Tracking from './Tracking';
import Tesis from './Tesis';
import Inbox from './Inbox';
import Profile from './UserProfile';
import ProjectDetails from './ProjectDetails';
import { faBars, faTimes, faSearch, faChartLine, faMoneyBill, faEnvelope, faUser, faSignOutAlt, faBook } from '@fortawesome/free-solid-svg-icons';

const DashboardFondos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [offersCount, setOffersCount] = useState(0);
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  React.useEffect(() => {
    const fetchOffersCount = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/offers/unread-count');
        const data = await response.json();
        setOffersCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de ofertas:', error);
      }
    };

    const fetchInboxCount = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        const role = localStorage.getItem('role');

        if (!userId || !role) {
          console.error('Faltan userId o role en el localStorage.');
          return;
        }

        const response = await fetch('https://api.finanbid.com/api/messages/unread-count', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            role: role,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error al obtener la cantidad de mensajes: ${response.status}`);
        }

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    fetchOffersCount();
    fetchInboxCount();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      {/* Header */}
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="Fondos Dashboard" />
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      {/* Sidebar */}
      <nav className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out`}>
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="Fondos Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/fund/dashboard" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" /> {t('dashboard.search')}
            </Link>
            <Link to="/fund/dashboard/tracking" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faChartLine} className="mr-2" /> {t('dashboard.tracking')}
            </Link>
            <Link to="/fund/dashboard/tesis" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faBook} className="mr-2" /> {t('dashboard.tesis')}
            </Link>
            <Link to="/fund/dashboard/inbox" className={`block px-4 py-2 ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`} onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <Link to="/fund/dashboard/profile" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
            <FontAwesomeIcon icon={faUser} className="mr-2" /> {t('dashboard.profile')}
          </Link>
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      {/* Contenido Principal con scroll */}
      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
        <Routes>
          <Route path="/" element={<Search />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/tesis" element={<Tesis />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/project/:projectId" element={<ProjectDetails />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardFondos;
