import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LandPurchaseDetails = ({ projectId, onNextStep }) => {
  const { t } = useTranslation();
  const [landPrice, setLandPrice] = useState('');
  const [hasLicense, setHasLicense] = useState(false);
  const [appraisalPrice, setAppraisalPrice] = useState('');

  // Estados para los archivos y sus URLs
  const [notaSimplePdfUrl, setNotaSimplePdfUrl] = useState('');
  const [licensePdfUrl, setLicensePdfUrl] = useState('');
  const [saleContractPdfUrl, setSaleContractPdfUrl] = useState('');

  const [images, setImages] = useState([]); // Imágenes generales
  const [renders, setRenders] = useState([]); // Renders
  const [error, setError] = useState(null);

  // Manejo de archivos
  const handleDocumentUpload = async (file, documentType) => {
    const formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('document_type', documentType);
    formData.append('document', file);

    try {
      const response = await fetch('https://api.finanbid.com/api/upload-document', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data.documentUrl;
      } else {
        const errorData = await response.json();
        setError(`Error al subir el documento: ${errorData.message}`);
        return '';
      }
    } catch (error) {
      setError('Error en la conexión al servidor');
      return '';
    }
  };

  // Manejadores de cambio de archivo para subir inmediatamente los documentos
  const handleNotaSimpleChange = async (e) => {
    const file = e.target.files[0];
    const url = await handleDocumentUpload(file, 'nota_simple_pdf');
    setNotaSimplePdfUrl(url);
  };

  const handleLicenseChange = async (e) => {
    const file = e.target.files[0];
    const url = await handleDocumentUpload(file, 'license_pdf');
    setLicensePdfUrl(url);
  };

  const handleSaleContractChange = async (e) => {
    const file = e.target.files[0];
    const url = await handleDocumentUpload(file, 'sale_contract_pdf');
    setSaleContractPdfUrl(url);
  };

  // Subida de imágenes (tipo imagen)
  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'imagen');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedImages.push(data.imageUrl);
        } else {
          setError('Error al subir la imagen');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
      }
    }

    setImages([...images, ...uploadedImages]);
  };

  // Subida de renders (tipo render)
  const handleRenderUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedRenders = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'render');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedRenders.push(data.imageUrl);
        } else {
          setError('Error al subir el render');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
      }
    }

    setRenders([...renders, ...uploadedRenders]);
  };

  const handleSaveDetails = async () => {
    if (!landPrice || !appraisalPrice) {
      setError(t('errors.fillRequiredFields'));
      return;
    }

    const updatedDetails = {
      land_price: landPrice,
      has_license: hasLicense,
      appraisal_price: appraisalPrice,
      nota_simple_pdf: notaSimplePdfUrl,
      license_pdf: licensePdfUrl,
      sale_contract_pdf: saleContractPdfUrl,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/save-details`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ details: updatedDetails }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(`Error al guardar detalles: ${errorData.message}`);
      } else {
        if (onNextStep) onNextStep();
      }
    } catch (error) {
      setError('Error en la conexión al servidor');
    }
  };

  const handleImageDelete = (index, type) => {
    if (type === 'imagen') {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    } else if (type === 'render') {
      const newRenders = [...renders];
      newRenders.splice(index, 1);
      setRenders(newRenders);
    }
  };

  return (
    <div className="flex space-x-4 bg-back">
      {/* Panel izquierdo para subir imágenes */}
      <div className="w-1/2">
        <h3 className="text-lg font-semibold mb-4">Subir Imágenes del Proyecto</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={image}
                alt={`Proyecto ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'imagen')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>

        <h3 className="text-lg font-semibold mb-4 mt-8">Subir Renders del Proyecto</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleRenderUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {renders.map((render, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={render}
                alt={`Render ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'render')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Panel derecho con el formulario */}
      <div className="w-1/2">
        <h3 className="text-2xl font-bold mb-4">Detalles de Compra de Suelo</h3>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label className="block text-sm font-medium">Precio del Suelo</label>
          <input
            type="number"
            value={landPrice}
            onChange={(e) => setLandPrice(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4 flex items-center">
          <label className="block text-sm font-medium">¿Tiene Licencia?</label>
          <input
            type="checkbox"
            checked={hasLicense}
            onChange={() => setHasLicense(!hasLicense)}
            className="ml-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">Precio de Tasación</label>
          <input
            type="number"
            value={appraisalPrice}
            onChange={(e) => setAppraisalPrice(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>
  
        {/* Subida de Nota Simple */}
        <div className="mb-4">
          <label className="block text-sm font-medium">Nota Simple (PDF)</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleNotaSimpleChange}
            className="w-full px-4 py-2 border rounded-md text-black mt-2"
          />
          {notaSimplePdfUrl && (
            <p className="text-green-500 mt-2">Documento subido correctamente</p>
          )}
        </div>
  
        {/* Subida de Licencia */}
        <div className="mb-4">
          <label className="block text-sm font-medium">Licencia (PDF)</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleLicenseChange}
            className="w-full px-4 py-2 border rounded-md text-black mt-2"
          />
          {licensePdfUrl && (
            <p className="text-green-500 mt-2">Documento subido correctamente</p>
          )}
        </div>
  
        {/* Subida de Contrato de Compraventa */}
        <div className="mb-4">
          <label className="block text-sm font-medium">Contrato de Compraventa (PDF)</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleSaleContractChange}
            className="w-full px-4 py-2 border rounded-md text-black mt-2"
          />
          {saleContractPdfUrl && (
            <p className="text-green-500 mt-2">Documento subido correctamente</p>
          )}
        </div>
  
        <button
          onClick={handleSaveDetails}
          className="w-full bg-primary text-white py-2 rounded-md hover:bg-opacity-90"
        >
          Guardar y Continuar
        </button>
      </div>
    </div>
  );
  };
  
  export default LandPurchaseDetails;
  
