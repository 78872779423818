import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FlippingLoanDetails = ({ details = {}, onSave, projectId }) => {
  const { t } = useTranslation();

  const initialDetails = {
    purpose: details?.purpose || '',
    coste_activo: details?.coste_activo || '',
    coste_obra: details?.coste_obra || '',
    tipo_inmueble: details?.tipo_inmueble || '',
    preventas: details?.preventas || '',
    arquitecto: details?.arquitecto || '',
    comercializadora: details?.comercializadora || ''
  };

  const [localDetails, setLocalDetails] = useState(initialDetails);

  useEffect(() => {
    // Verificamos que `details` esté definido y tenga las propiedades necesarias
    if (details) {
      setLocalDetails({
        purpose: details?.purpose || '',
        coste_activo: details?.coste_activo || '',
        coste_obra: details?.coste_obra || '',
        tipo_inmueble: details?.tipo_inmueble || '',
        preventas: details?.preventas || '',
        arquitecto: details?.arquitecto || '',
        comercializadora: details?.comercializadora || ''
      });
    }
  }, [details]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = () => {
    onSave(projectId, localDetails);
};

  // Verificación adicional antes de renderizar para asegurarse de que `localDetails` tenga valores definidos
  if (!localDetails) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-4">{t('flippingLoanDetails.title')}</h3>

      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.purpose')}</label>
        <textarea
          name="purpose"
          value={localDetails.purpose}
          onChange={handleChange}
          onBlur={() => handleBlur('purpose')}
          className="w-full border rounded p-2"
          rows="3"
        />
      </div> */}

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.costeActivo')}</label>
          <input
            type="number"
            name="coste_activo"
            value={localDetails.coste_activo}
            onChange={handleChange}
            onBlur={() => handleBlur('coste_activo')}
            className="w-full border rounded p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.costeObra')}</label>
          <input
            type="number"
            name="coste_obra"
            value={localDetails.coste_obra}
            onChange={handleChange}
            onBlur={() => handleBlur('coste_obra')}
            className="w-full border rounded p-2"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.tipoInmueble')}</label>
        <input
          type="text"
          name="tipo_inmueble"
          value={localDetails.tipo_inmueble}
          onChange={handleChange}
          onBlur={() => handleBlur('tipo_inmueble')}
          className="w-full border rounded p-2"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.preventas')}</label>
        <input
          type="text"
          name="preventas"
          value={localDetails.preventas}
          onChange={handleChange}
          onBlur={() => handleBlur('preventas')}
          className="w-full border rounded p-2"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.arquitecto')}</label>
        <input
          type="text"
          name="arquitecto"
          value={localDetails.arquitecto}
          onChange={handleChange}
          onBlur={() => handleBlur('arquitecto')}
          className="w-full border rounded p-2"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-1">{t('flippingLoanDetails.comercializadora')}</label>
        <input
          type="text"
          name="comercializadora"
          value={localDetails.comercializadora}
          onChange={handleChange}
          onBlur={() => handleBlur('comercializadora')}
          className="w-full border rounded p-2"
        />
      </div>
    </div>
  );
};

export default FlippingLoanDetails;
