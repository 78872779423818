import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';

const Assets = ({ projectId, assets }) => {
    const [localAssets, setLocalAssets] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingAsset, setEditingAsset] = useState(null);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState({});

    useEffect(() => {
        setLocalAssets(assets); // Inicializa localAssets con los assets recibidos como prop
    }, [assets]);

    const openModal = (asset = null) => {
        setEditingAsset(asset);
        setFormData(asset || {});
        setDocuments({});
        setModalOpen(true);
    };

    const closeModal = () => {
        setEditingAsset(null);
        setFormData({});
        setDocuments({});
        setModalOpen(false);
    };
    const [isOther, setIsOther] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setDocuments({ ...documents, [name]: files[0] });
    };

    const handleUpload = async () => {
        setLoading(true);
    
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No se encontró el token de autenticación');
    
            // Determinar el método y la URL en función de si estamos editando o creando un asset
            const url = editingAsset
                ? `https://api.finanbid.com/api/assets/${formData.id}` // URL para actualizar
                : 'https://api.finanbid.com/api/assets'; // URL para crear
    
            const method = editingAsset ? 'PATCH' : 'POST';
    
            // Realizar la llamada para crear o actualizar el asset
            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, project_id: projectId }),
            });
    
            if (!response.ok) throw new Error('Error en la creación/actualización del activo');
    
            const data = await response.json();
    
            // Actualizar la lista local de assets
            setLocalAssets((prevAssets) => {
                if (editingAsset) {
                    // Actualizar el asset en la lista local
                    return prevAssets.map((asset) => (asset.id === data.id ? data : asset));
                } else {
                    // Añadir el nuevo asset a la lista local
                    return [...prevAssets, data];
                }
            });
    
            // Subir los documentos asociados al asset, si existen
            for (const [docType, file] of Object.entries(documents)) {
                if (file) {
                    const formData = new FormData();
                    formData.append('asset_id', data.id); // ID del asset recién creado o actualizado
                    formData.append('document_type', docType);
                    formData.append('document', file);
    
                    const uploadResponse = await fetch('https://api.finanbid.com/api/upload-asset-document', {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: formData,
                    });
    
                    if (!uploadResponse.ok) {
                        console.error(`Error al subir el documento ${docType}`);
                    }
                }
            }
    
            // Cerrar el modal después de guardar el asset y subir los documentos
            closeModal();
        } catch (error) {
            console.error('Error al manejar activo:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleDelete = async (assetId) => {
        if (!window.confirm('¿Estás seguro de que deseas eliminar este activo?')) return;
        try {
            const token = localStorage.getItem('token');
            await fetch(`https://api.finanbid.com/api/assets/${assetId}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` }
            });
            setLocalAssets((prevAssets) => prevAssets.filter((asset) => asset.id !== assetId));
        } catch (error) {
            console.error('Error al eliminar el activo:', error);
        }
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold">Activos asociados al proyecto</h3>
                <button onClick={() => openModal()} className="text-blue-500 bg-white rounded-full p-1 hover:bg-blue-500 hover:text-white">
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <div className="space-y-2">
                {localAssets.map((asset) => (
                    <div key={asset.id} className="flex justify-between items-center py-2 border-b border-gray-300">
                        <div>
                            <span className="font-medium text-gray-800">{asset.type}</span>
                            <p className="text-sm text-gray-500">{asset.valor_tasacion}</p>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={() => openModal(asset)} className="text-blue-500">
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => handleDelete(asset.id)} className="text-red-500">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {modalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-200">
                    <div className="bg-white rounded-lg p-6 max-w-2xl w-full text-sm">
                        <h3 className="text-lg font-semibold mb-1">{editingAsset ? 'Editar Activo' : 'Añadir Activo'}</h3>
                        <div className="grid grid-cols-1 ggap-x-4 gap-y-2 mb-2">
                            <label className="block text-sm font-medium mb-1 capitalize">Descripción del activo</label>
                            <textarea
                                name="descripcion"
                                value={formData.descripcion || ''}
                                onChange={handleInputChange}
                                rows="2" /* Establece el número de líneas visibles */
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-x-4 gap-y-2 mb-2">
                            <label className="block text-sm font-medium capitalize">Tipo de activo</label>
                            <select
                                name="type"
                                value={formData.type || ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setFormData((prev) => ({
                                        ...prev,
                                        type: value === 'Otros' ? '' : value, // Si selecciona "Otros", limpia el valor en type
                                    }));
                                    setIsOther(value === 'Otros'); // Actualiza si debe mostrarse el input adicional
                                }}
                                className="w-full p-2 border rounded"
                            >
                                <option value="">Selecciona un tipo</option>
                                <option value="Suelo Urbano">Suelo Urbano</option>
                                <option value="Suelo Urbanizable">Suelo Urbanizable</option>
                                <option value="Villa">Villa</option>
                                <option value="Adosado">Adosado</option>
                                <option value="Pareado">Pareado</option>
                                <option value="Piso">Piso</option>
                                <option value="Duplex">Dúplex</option>
                                <option value="Nave Industrial">Nave Industrial</option>
                                <option value="Edificio residencial">Edificio residencial</option>
                                <option value="Edificio Comercial">Edificio Comercial</option>
                                <option value="Local Comercial">Local Comercial</option>
                                <option value="Otros">Otros</option>
                            </select>

                            {/* Input para el tipo personalizado si selecciona "Otros" */}
                            {isOther && (
                                <div className="mb-1">
                                    <label className="block text-sm font-medium">Especifica el tipo</label>
                                    <input
                                        type="text"
                                        name="type"
                                        value={formData.type || ''}
                                        onChange={handleInputChange}
                                        placeholder="Especifica el tipo de activo"
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            )}
                        </div>


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                            {['registro_propiedad', 'referencia_catastral', 'finca', 'tomo', 'folio', 'titular', 'valor_tasacion', 'valor_compraventa'].map((field) => (
                                <div key={field} className="mb-1">
                                    <label className="block text-sm font-medium mb-1 capitalize">{field.replace('_', ' ')}</label>
                                    <input
                                        type="text"
                                        name={field}
                                        value={formData[field] || ''}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">Nota Simple</label>
                                <input
                                    type="file"
                                    name="nota_simple"
                                    onChange={handleFileChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">Tasación</label>
                                <input
                                    type="file"
                                    name="tasacion"
                                    onChange={handleFileChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">Contrato de Compraventa</label>
                                <input
                                    type="file"
                                    name="contrato_compraventa"
                                    onChange={handleFileChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end items-center">
                            {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin className="text-secondary mr-4 text-xl" />
                            ) : (
                                <>
                                    <button onClick={closeModal} className="text-gray-600 mr-4">Cancelar</button>
                                    <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded">{editingAsset ? 'Actualizar' : 'Añadir'}</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Assets;


