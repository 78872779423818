import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faUpload, faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Step5ProjectDocuments = ({ projectId, onUpdate, errors }) => {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState([]);
    const [newDocument, setNewDocument] = useState({ type: "", file: null });
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");

// Función para obtener documentos del servidor
const fetchDocuments = async () => {
    try {
      const response = await fetch(
        `https://api.finanbid.com/api/projects/${projectId}/documents`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error al obtener documentos del proyecto");
      }

      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Llamar a fetchDocuments al cargar el componente
  useEffect(() => {
    if (projectId) {
      fetchDocuments();
    }
  }, [projectId, token]);

  const handleUpload = async () => {
    if (!newDocument.file || !newDocument.type) {
      alert(t("missingDocumentFields"));
      return;
    }

    const formData = new FormData();
    formData.append("document", newDocument.file);
    formData.append("document_type", newDocument.type);
    formData.append("project_id", projectId);

    setLoading(true);
    try {
      const response = await fetch("https://api.finanbid.com/api/upload-document", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error al subir el documento");
      }

      // Llamar a fetchDocuments para recargar la lista actualizada desde el servidor
      await fetchDocuments();

      setNewDocument({ type: "", file: null }); // Limpiar el formulario
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (documentId) => {
    if (!window.confirm(t("confirmDeleteDocument"))) return;

    try {
      const response = await fetch(
        `https://api.finanbid.com/api/documents/${documentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error al eliminar el documento");
      }

      // Eliminar el documento del listado local
      setDocuments((prev) => prev.filter((doc) => doc.id !== documentId));
    } catch (error) {
      console.error(error);
    }
  };

   

    return (
        <div className="flex flex-col lg:flex-row gap-8">
            {/* Contenido principal */}
            <div className="w-full lg:w-2/3 space-y-6">
                {/* Listado de documentos */}
                <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">{t("projectDocuments")}</h3>
                    <table className="w-full border-collapse border border-gray-300 text-sm">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="border px-2 py-1 text-left">{t("documentType")}</th>
                                <th className="border px-2 py-1 text-left">{t("uploadedAt")}</th>
                                <th className="border px-2 py-1 text-center">{t("actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((doc) => (
                                <tr key={doc.id}>
                                    <td className="border px-2 py-1">{doc.document_type}</td>
                                    <td className="border px-2 py-1">{new Date(doc.uploaded_at).toLocaleDateString()}</td>
                                    <td className="border px-2 py-1 text-center flex justify-center gap-2">
                                        <button
                                            onClick={() => window.open(doc.document_url, "_blank")}
                                            className="text-blue-500 hover:text-blue-600"
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(doc.id)}
                                            className="text-red-500 hover:text-red-600"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {documents.length === 0 && (
                                <tr>
                                    <td colSpan="3" className="text-center text-gray-500 py-4">
                                        {t("noDocuments")}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
  <h4 className="text-md font-semibold text-gray-800 mb-4">{t("uploadNewDocument")}</h4>
  {/* Tarjeta subida de docs */}
  {/* Primera línea: Input y botón de seleccionar archivo */}
  <div className="flex items-center gap-4">
    {/* Input de tipo de documento */}
    <div className="flex-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">{t("documentType")}</label>
      <input
        type="text"
        value={newDocument.type}
        onChange={(e) =>
          setNewDocument((prev) => ({ ...prev, type: e.target.value }))
        }
        className="w-full p-2 border rounded"
        placeholder={t("enterDocumentType")}
      />
    </div>

    {/* Botón para seleccionar archivo */}
    <div className="flex-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">{t("selectFile")}</label>
      <input
        type="file"
        onChange={(e) =>
          setNewDocument((prev) => ({ ...prev, file: e.target.files[0] }))
        }
        className="w-full p-2 border rounded"
      />
    </div>
  </div>

  {/* Segunda línea: Botón para subir documento */}
  <div className="mt-4">
    <button
      onClick={handleUpload}
      className={`px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      disabled={loading}
    >
      <FontAwesomeIcon icon={faUpload} className="mr-2" />
      {t("uploadDocument")}
    </button>
  </div>
</div>

                {/* Errores */}
                {errors?.documents && <p className="text-red-500 text-sm mt-2">{errors.documents}</p>}
            </div>

            {/* Consejos */}
            <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
                <h4 className="text-md font-medium text-gray-700 flex items-center">
                    <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
                    {t("tipTitle")}
                </h4>
                <p className="text-sm text-gray-600 mt-2">
                    {t("step5Tip")
                        .split("\n")
                        .map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>
            </div>
        </div>
    );
};

export default Step5ProjectDocuments;
