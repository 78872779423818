import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const StepCreateEntity = ({ nextStep, saveEntityAndUser, collaboratorId }) => {
  const { t } = useTranslation();

  const [entityData, setEntityData] = useState({
    business_name: "",
    business_cif: "",
    business_address: "",
    contact_email: "",
    contact_phone: "",
    year_of_incorporation: "",
  });

  const [legalEntities, setLegalEntities] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [consentChecked, setConsentChecked] = useState(false);
  const [communicationsRecipient, setCommunicationsRecipient] = useState(""); // "colaborador" o "cliente"
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchLegalEntities = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.finanbid.com/api/legal-entities-collaborator",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Entidades legales cargadas:", response.data);
        setLegalEntities(response.data);
      } catch (error) {
        console.error("Error fetching legal entities:", error);
      }
    };
  
    fetchLegalEntities();
  }, []);
  

  const handleEntitySelect = (entityId) => {
    const selectedEntity = legalEntities.find((e) => e.id === Number(entityId)); // Conversión explícita
  
    if (selectedEntity) {
      if (selectedEntity.user_id) {
        saveEntityAndUser(selectedEntity.id, selectedEntity.user_id, collaboratorId);
        nextStep();
      } else {
        handleMissingUser(selectedEntity);
      }
    } else {
      setErrorMessage("La entidad seleccionada no es válida.");
    }
  };

  const handleMissingUser = async (selectedEntity) => {
    try {
      const email = entityData.contact_email;
      const token = localStorage.getItem("token");

      const userResponse = await fetch(
        `https://api.finanbid.com/api/users/by-email/${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let userId;
      if (userResponse.ok) {
        // Si el usuario existe, asociarlo a la entidad
        const existingUser = await userResponse.json();
        userId = existingUser.user.id;

        await fetch(
          `https://api.finanbid.com/api/legal-entities/${selectedEntity.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
      } else {
        // Si no existe, crear el usuario
        userId = await createUser(entityData, collaboratorId, token);

        await fetch(
          `https://api.finanbid.com/api/legal-entities/${selectedEntity.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
      }

      saveEntityAndUser(selectedEntity.id, userId, collaboratorId);
      nextStep();
    } catch (error) {
      console.error("Error al manejar usuario faltante:", error);
      setErrorMessage("No se pudo procesar el usuario faltante.");
    }
  };

  const createUser = async (entityData, collaboratorId, token) => {
    const temporaryPassword = generateTemporaryPassword();
    const userPayload = {
      email: entityData.contact_email,
      password: temporaryPassword,
      name: entityData.business_name,
      phone_number: entityData.contact_phone,
      role: "promotor",
      referred_by: collaboratorId,
    };

    const userResponse = await fetch("https://api.finanbid.com/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userPayload),
    });

    if (!userResponse.ok) {
      throw new Error(t("projectUpload.errors.userCreationFailed"));
    }

    const userData = await userResponse.json();
    return userData.user.id;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEntityData((prev) => ({ ...prev, [name]: value }));
  };

  const handleConsentChange = (e) => {
    setConsentChecked(e.target.checked);
  };

  const generateTemporaryPassword = () => {
    const length = 10;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleSubmit = async () => {
    if (!consentChecked) {
      setErrorMessage(t("projectUpload.errors.missingConsent"));
      return;
    }

    if (!communicationsRecipient) {
      setErrorMessage(t("projectUpload.errors.missingCommunicationsRecipient"));
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const token = localStorage.getItem("token");
      let entityId = selectedEntityId;
      let userId;

      // **Caso 1: Si se selecciona una entidad existente**
      if (selectedEntityId) {
        const entity = legalEntities.find((e) => e.id === selectedEntityId);

        if (!entity) {
          throw new Error(t("projectUpload.errors.entityNotFound"));
        }

        if (entity.user_id) {
          // La entidad ya tiene un usuario asociado
          userId = entity.user_id;
        } else {
          // La entidad no tiene un usuario asociado, usar el email del formulario
          await handleMissingUser(entity);
          return;
        }
      } else {
        // **Caso 2: Si no se selecciona una entidad legal existente**
        const email = entityData.contact_email;

        // Verificar si el usuario ya existe
        const userResponse = await fetch(
          `https://api.finanbid.com/api/users/by-email/${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (userResponse.ok) {
          // Si el usuario existe, obtener su ID
          const existingUser = await userResponse.json();
          userId = existingUser.id;
        } else {
          // Si el usuario no existe, crear uno nuevo
          userId = await createUser(entityData, collaboratorId, token);
        }

        // Crear la nueva entidad legal asociada al usuario
        const entityPayload = { ...entityData, user_id: userId };
        const entityResponse = await fetch(
          "https://api.finanbid.com/api/entity/create-legal-entity",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(entityPayload),
          }
        );

        if (!entityResponse.ok) {
          throw new Error(t("projectUpload.errors.entityCreationFailed"));
        }

        const entityDataResponse = await entityResponse.json();
        entityId = entityDataResponse.id;
      }

      // Definir el `comId` según la selección del colaborador
      const comId = communicationsRecipient === "colaborador" ? collaboratorId : userId;

      // Pasar al siguiente paso con la entidad legal seleccionada o creada
      saveEntityAndUser(entityId, userId, comId);
      nextStep();
    } catch (error) {
      console.error("Error al procesar la entidad legal:", error);
      setErrorMessage(error.message || t("projectUpload.errors.apiError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-white flex flex-col md:flex-row justify-between bg-back p-4">
      {/* Columna izquierda: Título y descripción */}
      <div className="hidden md:block md:w-1/2 pr-8">
        <h3 className="text-3xl font-semibold mb-4">
          {t("projectUpload.stepCreateEntity.title")}
        </h3>
        <p>{t("projectUpload.stepCreateEntity.description")}</p>

        {/* Dropdown para seleccionar entidad legal */}
        <div className="mt-6">
          <h4 className="text-xl font-semibold mb-2">
            {t("projectUpload.stepCreateEntity.selectLegalEntity")}
          </h4>
          <select
  value={selectedEntityId || ""}
  onChange={(e) => {
    const entityId = e.target.value ? Number(e.target.value) : null;
    setSelectedEntityId(entityId);
    if (entityId) {
      handleEntitySelect(entityId); // Llama a la función aquí
    }
  }}
  className="w-full px-4 py-2 border rounded-md text-black"
>
  <option value="">
    {t("projectUpload.stepCreateEntity.selectLegalEntityPlaceholder")}
  </option>
  {legalEntities.map((entity) => (
    <option key={entity.id} value={entity.id}>
      {entity.business_name} - {entity.business_cif}
    </option>
  ))}
</select>
          <p className="mt-4 text-sm text-gray-300">
            {t("projectUpload.stepCreateEntity.orCreateNewEntity")}
          </p>
        </div>
      </div>

      {/* Columna derecha: Formulario */}
      <div className="md:w-1/2 w-full">
        <h3 className="text-2xl font-bold mb-4">
          {t("projectUpload.stepCreateEntity.formTitle")}
        </h3>

        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

        <form>
          {/* Campos del formulario */}
          {Object.entries(entityData).map(([key, value]) => (
            <div className="mb-4" key={key}>
              <label className="block text-sm font-medium">
                {t(`projectUpload.stepCreateEntity.${key}`)}
              </label>
              <input
                type="text"
                name={key}
                value={value}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md text-black"
                required
              />
            </div>
          ))}

          {/* Checkbox para consentimiento */}
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={consentChecked}
                onChange={handleConsentChange}
                className="mr-2"
              />
              <span>{t("projectUpload.stepCreateEntity.consentDeclaration")}</span>
            </label>
          </div>

          {/* Dropdown para comunicaciones */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-white">
              {t("projectUpload.stepCreateEntity.whoReceivesCommunications")}
            </label>
            <select
              value={communicationsRecipient}
              onChange={(e) => setCommunicationsRecipient(e.target.value)}
              className={`w-full px-4 py-2 border rounded-md ${
                !communicationsRecipient && errorMessage
                  ? "border-red-500"
                  : "text-black"
              }`}
            >
              <option value="">{t("projectUpload.stepCreateEntity.selectRecipient")}</option>
              <option value="colaborador">{t("projectUpload.stepCreateEntity.collaborator")}</option>
              <option value="cliente">{t("projectUpload.stepCreateEntity.client")}</option>
            </select>
          </div>

          {/* Botón de enviar */}
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="w-full bg-primary text-white py-2 rounded-md hover:bg-opacity-90"
          >
            {loading
              ? t("common.loading")
              : t("projectUpload.stepCreateEntity.submitButton")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default StepCreateEntity;
