import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Funds = ({ funds }) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Asegúrate de llamarlo dentro del cuerpo del componente
  const [sortedFunds, setSortedFunds] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [showModal, setShowModal] = useState(false);
  const [newFund, setNewFund] = useState({
    nombre: '',
    razon_social: '',
    direccion: '',
    localidad: '',
    provincia: '',
    cif: '',
    tipo_fondo: '',
  });

  useEffect(() => {
    setSortedFunds(funds);
  }, [funds]);

  const sortFunds = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sorted = [...funds].sort((a, b) => {
      if (key === 'nombre') {
        return direction === 'asc'
          ? (a.nombre || '').localeCompare(b.nombre || '')
          : (b.nombre || '').localeCompare(a.nombre || '');
      }
      return direction === 'asc' ? (a[key] || 0) - (b[key] || 0) : (b[key] || 0) - (a[key] || 0);
    });

    setSortedFunds(sorted);
    setSortConfig({ key, direction });
  };

  const openFundDetails = (fundId) => {
    navigate(`/admin/funds/${fundId}`); // Cambia a la ruta del fondo
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFund((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddFund = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/funds/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newFund),
      });

      if (!response.ok) {
        throw new Error('Error al crear el fondo');
      }

      const fund = await response.json();
      setSortedFunds((prevFunds) => [...prevFunds, fund]);
      setShowModal(false);
      setNewFund({
        nombre: '',
        razon_social: '',
        direccion: '',
        localidad: '',
        provincia: '',
        cif: '',
        tipo_fondo: '',
      });
    } catch (error) {
      console.error('Error al crear el fondo:', error);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">{t('funds.title')}</h2>
        <button
          onClick={() => setShowModal(true)}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          {t('funds.addFund')}
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th
                className="p-4 border border-gray-300 cursor-pointer"
                onClick={() => sortFunds('nombre')}
              >
                {t('funds.nombre')}
                {sortConfig.key === 'nombre' && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
              </th>
              <th className="p-4 border border-gray-300">{t('funds.razonSocial')}</th>
              <th
                className="p-4 border border-gray-300 cursor-pointer"
                onClick={() => sortFunds('followupsCount')}
              >
                {t('funds.projects')}
                {sortConfig.key === 'followupsCount' && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
              </th>
              <th
                className="p-4 border border-gray-300 cursor-pointer"
                onClick={() => sortFunds('offersCount')}
              >
                {t('funds.offers')}
                {sortConfig.key === 'offersCount' && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
              </th>
              <th className="p-4 border border-gray-300">{t('funds.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedFunds.map((fund) => (
              <tr key={fund.id} className="hover:bg-gray-50">
                <td className="p-4 border border-gray-300">{fund.nombre || 'N/A'}</td>
                <td className="p-4 border border-gray-300">{fund.razon_social || 'N/A'}</td>
                <td className="p-4 border border-gray-300 text-center">{fund.followupsCount || 0}</td>
                <td className="p-4 border border-gray-300 text-center">{fund.offersCount || 0}</td>
                <td className="p-4 border border-gray-300 text-right">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => openFundDetails(fund.id)}
                  >
                    {t('funds.viewDetails')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">{t('funds.addFundTitle')}</h3>
            <div className="space-y-4">
              {Object.keys(newFund).map((field) => (
                <input
                  key={field}
                  type="text"
                  name={field}
                  placeholder={t(`funds.fields.${field}`)}
                  value={newFund[field]}
                  onChange={handleInputChange}
                  className="w-full border rounded px-4 py-2"
                />
              ))}
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                {t('funds.cancel')}
              </button>
              <button
                onClick={handleAddFund}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                {t('funds.save')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Funds.propTypes = {
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string,
      razon_social: PropTypes.string,
      followupsCount: PropTypes.number,
      offersCount: PropTypes.number,
    })
  ).isRequired,
};

export default Funds;

