import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const BridgeLoanDetails = ({ details, onSave, projectId }) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  const initialDetails = {
    purpose: details?.purpose || '',
    nota_simple_pdf: details?.nota_simple_pdf || '',
    business_plan: details?.business_plan || ''
  };

  const [localDetails, setLocalDetails] = useState(initialDetails);

  useEffect(() => {
    if (details) {
      setLocalDetails({
        purpose: details.purpose || '',
        nota_simple_pdf: details.nota_simple_pdf || '',
        business_plan: details.business_plan || ''
      });
    }
  }, [details]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = (field) => {
    onSave(projectId, { ...localDetails, [field]: localDetails[field] });
  };

  const handleFileChange = async (e, documentType) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || !projectId) return;

    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('project_id', projectId);
    formData.append('document_type', documentType);

    try {
      setUploading(true);
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.finanbid.com/api/upload-document', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Error al subir el documento');
      }

      const data = await response.json();
      const documentUrl = data.documentUrl;

      setLocalDetails((prev) => {
        const updatedDetails = {
          ...prev,
          [documentType]: documentUrl
        };
        onSave(projectId, updatedDetails); // Asegúrate de pasar projectId aquí
        return updatedDetails;
      });
    } catch (error) {
      console.error('Error al subir el documento:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-md font-semibold mb-4">{t('bridgeLoanDetails.title')}</h3>

      <div className="mb-4">
        <label className="text-sm font-medium text-gray-600 mb-1">{t('bridgeLoanDetails.purpose')}</label>
        <textarea
          name="purpose"
          value={localDetails.purpose}
          onChange={handleChange}
          onBlur={() => handleBlur('purpose')}
          className="w-full border rounded p-2"
          rows="3"
        />
      </div>

      <div className="flex flex-col space-y-4 mt-4">
        <div className="flex items-center space-x-2" style={{ width: '70%' }}>
          <label className="bg-secondary text-white px-4 py-2 rounded cursor-pointer flex-grow text-center">
            {localDetails.nota_simple_pdf ? t('Cambiar Nota Simple') : t('Añadir Nota Simple')}
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'nota_simple_pdf')}
              className="hidden"
              disabled={uploading}
            />
          </label>
          {localDetails.nota_simple_pdf && <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />}
        </div>

        <div className="flex items-center space-x-2" style={{ width: '70%' }}>
          <label className="bg-secondary text-white px-4 py-2 rounded cursor-pointer flex-grow text-center">
            {localDetails.business_plan ? t('Cambiar Business Plan') : t('Añadir Business Plan')}
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'business_plan')}
              className="hidden"
              disabled={uploading}
            />
          </label>
          {localDetails.business_plan && <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />}
        </div>
      </div>
    </div>
  );
};

export default BridgeLoanDetails;

