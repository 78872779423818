import React, { useState, useEffect , useRef} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import io from 'socket.io-client';
import socket from '../../utils/socket';



const AdminInbox = () => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const messagesEndRef = useRef(null); // Referencia para el final del chat
  const token = localStorage.getItem('token');
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Cargar todas las conversaciones
  useEffect(() => {
    const fetchConversations = async () => {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      if (!userId) return;
  
      try {
        const response = await axios.get(`https://api.finanbid.com/api/conversations?userId=${userId}`,
          {
              headers: {
                  Authorization: `Bearer ${token}`, // Agregamos el token como Bearer en los headers
              },
          });
        const updatedConversations = response.data.map((conv) => ({
          ...conv,
          isUnread: false,
          messages: [],
        }));
        setConversations(updatedConversations);
  
        // Suscribir a eventos de WebSocket
        updatedConversations.forEach((conversation) => {
          socket.on(`conversation_${conversation.conversation_id}`, (newMessage) => {
            console.log(`Nuevo mensaje en conversación ${conversation.conversation_id}:`, newMessage);
  
            setConversations((prev) =>
              prev.map((conv) =>
                conv.conversation_id === newMessage.conversation_id
                  ? { ...conv, isUnread: true, last_message: newMessage.message }
                  : conv
              )
            );
  
            setSelectedConversation((prev) => {
              if (prev?.conversation_id === newMessage.conversation_id) {
                return { ...prev, messages: [...(prev.messages || []), newMessage] };
              }
              scrollToBottom(); // Desplázate al final cuando se recibe un nuevo mensaje
              return prev;
             
            });
          });
        });
      } catch (error) {
        console.error('Error al cargar las conversaciones:', error);
      }
    };
  
    fetchConversations();
  
    // Limpieza al desmontar
    return () => {
      conversations.forEach((conversation) => {
        socket.off(`conversation_${conversation.conversation_id}`);
      });
    };
  }, []); // Dependencia vacía para que solo se ejecute una vez

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Manejar la selección de una conversación
  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);

    try {
      const response = await axios.get(
        `https://api.finanbid.com/api/messages?conversationId=${conversation.conversation_id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`, // Agregamos el token como Bearer en los headers
            },
        }
      );

      const messages = response.data.map((msg) => ({
        ...msg,
        fileUrl: msg.file_url || null,
        fileName: msg.file_name || null,
      }));

      setSelectedConversation((prev) => {
        const updatedConversation = {
          ...prev,
          messages,
          recipient_id: messages[0]?.recipient_id || conversation.recipient_id,
          recipient_role: messages[0]?.recipient_role || conversation.recipient_role,
        };
        scrollToBottom(); // Desplázate al final
        return updatedConversation;
      });
      

      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversation_id === conversation.conversation_id ? { ...conv, isUnread: false } : conv
        )
      );
      scrollToBottom();
    } catch (error) {
      console.error('Error al cargar los mensajes:', error);
    }
    
  };

  // Manejar el envío de un nuevo mensaje
  const handleSendMessage = async () => {
    if (!newMessage.trim() && !attachment) return;

    const userId = parseInt(localStorage.getItem('userId'), 10);
    const senderRole = localStorage.getItem('role');

    const { conversation_id, recipient_id, recipient_role } = selectedConversation || {};
    if (!conversation_id || !recipient_id || !recipient_role) return;

    try {
      const formData = new FormData();
      formData.append('conversationId', conversation_id);
      formData.append('senderId', userId);
      formData.append('senderRole', senderRole);
      formData.append('recipientId', recipient_id);
      formData.append('recipientRole', recipient_role);
      if (newMessage.trim()) formData.append('message', newMessage);
      if (attachment) formData.append('attachment', attachment);

      const response = await axios.post('https://api.finanbid.com/api/messages', formData, {
        headers: { 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
         },
      });

      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), response.data],
      }));
      
      scrollToBottom(); // Desplázate al final después de enviar

      setNewMessage('');
      setAttachment(null);
      scrollToBottom();
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.response?.data || error.message);
    }
  };

  // Manejar el cambio de archivo
  const handleFileChange = (e) => setAttachment(e.target.files[0]);

  // Abrir el modal para nueva conversación
  const openNewConversationModal = () => {
    setIsModalOpen(true);
    setUserRole('');
    setUsersList([]);
    setSelectedUser(null);
  };

  // Cargar usuarios por rol
  const fetchUsersByRole = async (role) => {
    try {
      const response = await axios.get(`https://api.finanbid.com/api/users?role=${role}`);
      setUsersList(response.data);
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    }
  };

  // Crear nueva conversación
  const handleCreateConversation = async () => {
    if (!selectedUser || !userRole) return;

    try {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const response = await axios.post('https://api.finanbid.com/api/conversations', {
        userId,
        recipientId: selectedUser.id,
        recipientRole: userRole,
      });

      setConversations((prev) => [...prev, response.data]);
      setSelectedConversation(response.data);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al crear conversación:', error);
    }
  };
  


return (
  <div className="flex h-full">
    {/* Lista de conversaciones */}
    <div className="w-1/3 bg-gray-100 p-4 border-r overflow-y-auto relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{t('inbox.conversations')}</h2>
        <button
          onClick={openNewConversationModal}
          className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
        >
          +
        </button>
      </div>
      <div>
        {conversations.length > 0 ? (
          conversations.map((conversation) => (
            <div
              key={conversation.conversation_id}
              onClick={() => handleSelectConversation(conversation)}
              className={`p-3 mb-2 cursor-pointer rounded-lg ${
                selectedConversation?.conversation_id === conversation.conversation_id
                  ? 'bg-blue-200'
                  : conversation.isUnread
                  ? 'bg-yellow-100'
                  : 'bg-white hover:bg-gray-200'
              }`}
            >
              <p className="font-semibold">
                {conversation.user_name || t('inbox.unknownUser')}
              </p>
              <p className="text-sm text-gray-600">
                {conversation.last_message || t('inbox.noMessages')}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-600">{t('inbox.noConversations')}</p>
        )}
      </div>
    </div>

    {/* Modal para nueva conversación */}
    {isModalOpen && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
          <h3 className="text-lg font-bold mb-4">{t('inbox.newConversation')}</h3>
          <label className="block mb-2">
            {t('inbox.selectRole')}
            <select
              value={userRole}
              onChange={(e) => {
                setUserRole(e.target.value);
                fetchUsersByRole(e.target.value);
              }}
              className="w-full p-2 border rounded"
            >
              <option value="">{t('inbox.select')}</option>
              <option value="promotor">{t('inbox.promotor')}</option>
              <option value="fondo">{t('inbox.fund')}</option>
            </select>
          </label>
          {userRole && (
            <label className="block mb-2">
              {t('inbox.selectUser')}
              <select
                value={selectedUser?.id || ''}
                onChange={(e) =>
                  setSelectedUser(
                    usersList.find((user) => user.id === parseInt(e.target.value, 10))
                  )
                }
                className="w-full p-2 border rounded"
              >
                <option value="">{t('inbox.selectUser')}</option>
                {usersList.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name} ({user.email})
                  </option>
                ))}
              </select>
            </label>
          )}
          <div className="flex justify-end">
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
            >
              {t('inbox.cancel')}
            </button>
            <button
              onClick={handleCreateConversation}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {t('inbox.open')}
            </button>
          </div>
        </div>
      </div>
    )}

    {/* Detalle de la conversación */}
    <div className="w-2/3 p-4 flex flex-col justify-between">
        {selectedConversation ? (
          <>
            <div className="flex-1 overflow-y-auto mb-4">
              <h2 className="text-xl font-bold mb-4">
                {t('inbox.conversationWith')} #Fondo {selectedConversation.recipient_id}
              </h2>
              {selectedConversation?.messages?.map((msg, index) => (
                <div
                  key={index}
                  className={`mb-2 flex ${msg.sender_id === parseInt(localStorage.getItem('userId'), 10)
                    ? 'justify-end'
                    : 'justify-start'
                    }`}
                >
                  <div
                    className={`inline-block p-3 rounded-lg ${msg.sender_id === parseInt(localStorage.getItem('userId'), 10)
                      ? 'bg-green-200 text-right'
                      : 'bg-gray-200 text-left'
                      }`}
                  >
                    <p className="text-sm">{msg.message}</p>
                    {/* Manejo de adjuntos */}
                    {msg.file_url && (
                      <a
                        href={msg.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {t('inbox.viewAttachment')}
                      </a>
                    )}
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(msg.fecha_creacion).toLocaleString()}
                    </p>
                  </div>
                  <div ref={messagesEndRef} /> {/* Este div asegura que se desplaza al final */}
                </div>
              ))}
            </div>

            {/* Campo para subir archivos */}
            <div className="flex items-center mb-2">
              <input
                type="file"
                onChange={(e) => setAttachment(e.target.files[0])}
                className="p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Input para escribir mensajes */}
            <div className="flex items-center">
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t('inbox.typeMessage')}
                rows="4"
                className="flex-1 p-2 border border-gray-300 rounded-l-lg"
              />
              <button
                onClick={handleSendMessage}
                className="p-2 bg-blue-500 ml-3 text-white rounded-r-lg hover:bg-blue-600"
              >
                {t('inbox.send')}
              </button>
            </div>
          </>
        ) : (
          <p className="text-gray-600">{t('inbox.selectConversation')}</p>
        )}
      </div>

    </div>
  );
};

export default AdminInbox;

