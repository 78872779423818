import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminSystemUpdates = () => {
  const [updates, setUpdates] = useState([]);
  const [newUpdate, setNewUpdate] = useState({
    update_date: "",
    version: "",
    update_text: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch existing updates
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await axios.get("https://api.finanbid.com/api/system-updates");
        setUpdates(response.data);
      } catch (error) {
        console.error("Error fetching updates:", error);
      }
    };

    fetchUpdates();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUpdate((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newUpdate.update_date || !newUpdate.version || !newUpdate.update_text) {
      setErrorMessage("All fields are required.");
      return;
    }
    try {
      const response = await axios.post("https://api.finanbid.com/api/system-updates", newUpdate);
      setUpdates((prev) => [response.data, ...prev]);
      setNewUpdate({ update_date: "", version: "", update_text: "" });
      setSuccessMessage("Update added successfully.");
      setErrorMessage("");
    } catch (error) {
      console.error("Error adding update:", error);
      setErrorMessage("Failed to add update. Please try again.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">System Updates</h1>

      {/* Form to add a new update */}
      <div className="mb-6 bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Add New Update</h2>
        {errorMessage && <p className="text-red-600">{errorMessage}</p>}
        {successMessage && <p className="text-green-600">{successMessage}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date
            </label>
            <input
              type="date"
              name="update_date"
              value={newUpdate.update_date}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Version
            </label>
            <input
              type="text"
              name="version"
              value={newUpdate.version}
              onChange={handleInputChange}
              placeholder="e.g., 1.0.0"
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Update Text
            </label>
            <textarea
              name="update_text"
              value={newUpdate.update_text}
              onChange={handleInputChange}
              rows={4}
              placeholder="Describe the update..."
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Submit
          </button>
        </form>
      </div>

      {/* List of updates */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Updates</h2>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b border-gray-200 text-left text-sm font-semibold text-gray-600">
                Date
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left text-sm font-semibold text-gray-600">
                Version
              </th>
              <th className="px-4 py-2 border-b border-gray-200 text-left text-sm font-semibold text-gray-600">
                Text
              </th>
            </tr>
          </thead>
          <tbody>
            {updates.map((update) => (
              <tr key={update.id}>
                <td className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700">
                  {new Date(update.update_date).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700">
                  {update.version}
                </td>
                <td className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700">
                  {update.update_text}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminSystemUpdates;
