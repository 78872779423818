import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const BridgeLoanDetails = ({ data, onUpdateField }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ ...data });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setFormData({ ...data });
  }, [data]);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleBlur = async (field, value) => {
    if (!formData.project_id) {
      console.error('Project ID is undefined.');
      return;
    }

    if (onUpdateField) {
      await onUpdateField(field, value);
    } else {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.finanbid.com/api/admin/projects/${formData.project_id}/bridge_loan/field`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ field, value }),
        });

        if (!response.ok) {
          throw new Error(`Failed to update ${field}`);
        }

        setFormData((prevData) => ({ ...prevData, [field]: value }));
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    }
  };

  const getVerificationStatusColor = () => {
    switch (formData.verification_status) {
      case 'OK':
        return 'text-green-600';
      case 'Pending':
        return 'text-yellow-600';
      case 'Failed':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">{t('bridgeLoanDetails.title')}</h2>
        <div className="flex items-center">
          <label className="mr-2 text-sm font-medium text-gray-600">
            {t('bridgeLoanDetails.verification_status')}:
          </label>
          <select
            value={formData.verification_status || ''}
            onChange={(e) => {
              handleChange('verification_status', e.target.value);
              handleBlur('verification_status', e.target.value);
            }}
            className={`border rounded p-1 text-sm ${getVerificationStatusColor()}`}
          >
            <option value="OK">OK</option>
            <option value="Pending">Pending</option>
            <option value="Failed">Failed</option>
          </select>
          <FontAwesomeIcon
            icon={expanded ? faChevronDown : faChevronRight}
            className="ml-4 cursor-pointer"
            onClick={toggleAccordion}
          />
        </div>
      </div>

      {expanded && (
        <>
          {/* ID - Read-only */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('bridgeLoanDetails.id')}</label>
            <p className="text-gray-800 text-sm">{formData.id}</p>
          </div>

          {/* Purpose */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('bridgeLoanDetails.purpose')}</label>
            <textarea
              value={formData.purpose}
              onChange={(e) => handleChange('purpose', e.target.value)}
              onBlur={() => handleBlur('purpose', formData.purpose)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Nota Simple PDF */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('bridgeLoanDetails.nota_simple_pdf')}</label>
            <input
              type="file"
              onChange={(e) => handleChange('nota_simple_pdf', e.target.files[0])}
              className="w-full border rounded-sm p-2 text-sm"
            />
          </div>

          {/* Nota Simple Text */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('bridgeLoanDetails.nota_simple_text')}</label>
            <textarea
              value={formData.nota_simple_text}
              onChange={(e) => handleChange('nota_simple_text', e.target.value)}
              onBlur={() => handleBlur('nota_simple_text', formData.nota_simple_text)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Business Plan */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('bridgeLoanDetails.business_plan')}</label>
            <textarea
              value={formData.business_plan}
              onChange={(e) => handleChange('business_plan', e.target.value)}
              onBlur={() => handleBlur('business_plan', formData.business_plan)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BridgeLoanDetails;

