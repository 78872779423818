import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const UserOffers = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [referredProjects, setReferredProjects] = useState([]); // Proyectos de usuarios referidos
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedReferredProjectId, setSelectedReferredProjectId] = useState(null); // Para proyectos referidos
  const [offers, setOffers] = useState([]);
  const [referredOffers, setReferredOffers] = useState([]); // Ofertas para proyectos referidos
  const [expandedOfferId, setExpandedOfferId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState(null); // Rol del usuario

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        const userId = localStorage.getItem('userId'); // Obtener el userId desde el localStorage

        setUserRole(role);

        const response = await axios.get('https://api.finanbid.com/api/projects', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setProjects(response.data);

        // Si el usuario es colaborador, cargar proyectos de referidos
        if (role === 'colaborador') {
          const referredResponse = await axios.get(
            `https://api.finanbid.com/api/projects/referred/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setReferredProjects(referredResponse.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const fetchOffers = async (projectId, isReferred = false) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://api.finanbid.com/api/offers?project_id=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (isReferred) {
        setReferredOffers(response.data);
      } else {
        setOffers(response.data);
      }
    } catch (error) {
      console.error('Error fetching offers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectSelect = (projectId, isReferred = false) => {
    if (isReferred) {
      setSelectedReferredProjectId(projectId);
      fetchOffers(projectId, true);
    } else {
      setSelectedProjectId(projectId);
      fetchOffers(projectId, false);
    }
  };

  const toggleOfferDetails = (offerId) => {
    setExpandedOfferId(expandedOfferId === offerId ? null : offerId);
  };

  const handleRejectOffer = async (offerId) => {
    const confirmReject = window.confirm(t('offers.rejectConfirmation'));
    if (!confirmReject) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.finanbid.com/api/offers/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(t('offers.rejectSuccess'));
      if (selectedProjectId) {
        fetchOffers(selectedProjectId, false);
      } else if (selectedReferredProjectId) {
        fetchOffers(selectedReferredProjectId, true);
      }
    } catch (error) {
      console.error('Error rejecting offer:', error);
      alert(t('offers.rejectError'));
    }
  };

  const renderProjectsAndOffers = (projects, offers, isReferred = false) => (
    <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
      {/* Project List */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-4">
          {isReferred ? t('userOffers.referredProjects') : t('userOffers.projects')}
        </h2>
        <ul className="space-y-2">
          {projects.map((project) => (
            <li
              key={project.id}
              onClick={() => handleProjectSelect(project.id, isReferred)}
              className={`p-2 rounded cursor-pointer flex justify-between ${
                (isReferred ? selectedReferredProjectId : selectedProjectId) === project.id
                  ? 'bg-blue-100'
                  : 'hover:bg-gray-100'
              }`}
            >
              <span>{project.name || `${t('userOffers.project')} ${project.id}`}</span>
              <span className="text-gray-500">{project.user_email || t('userOffers.noEmail')}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Offers List */}
      <div className="col-span-2 bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-4">{t('userOffers.offers')}</h2>
        {loading ? (
          <p>{t('common.loading')}</p>
        ) : offers.length > 0 ? (
          <div>
            <div className="grid grid-cols-6 gap-4 font-semibold text-sm bg-gray-200 p-2 rounded">
              <span>{t('offers.headers.date')}</span>
              <span>{t('offers.headers.fundId')}</span>
              <span>{t('offers.headers.interestRate')}</span>
              <span>{t('offers.headers.openingFee')}</span>
              <span>{t('offers.headers.repayment')}</span>
              <span>{t('offers.headers.actions')}</span>
            </div>
            {offers.map((offer) => (
              <div key={offer.id} className="grid grid-cols-6 gap-4 items-center p-2 border-b">
                <span>{new Date(offer.offer_date).toLocaleDateString()}</span>
                <span>{offer.fund_id}</span>
                <span>{offer.interest_rate_monthly}%</span>
                <span>{offer.opening_fee}%</span>
                <span>{offer.interest_repayment}</span>
                <button onClick={() => toggleOfferDetails(offer.id)}>
                  <FontAwesomeIcon
                    icon={expandedOfferId === offer.id ? faChevronDown : faChevronRight}
                  />
                </button>

                {/* Expanded Offer Details */}
                {expandedOfferId === offer.id && (
                  <div className="col-span-6 bg-gray-100 p-4 rounded-lg mt-2">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h3 className="text-md font-semibold mb-2">
                          {t('offers.description')}
                        </h3>
                        <p>{offer.offer_description || t('offers.noDescription')}</p>
                      </div>
                      {!isReferred && (
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleRejectOffer(offer.id)}
                            className="bg-secondary text-white px-4 py-2 text-sm rounded hover:bg-secondarysuave"
                          >
                            {t('buttons.rejectOffer')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>{t('userOffers.noOffers')}</p>
        )}
      </div>
    </div>
  );

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">{t('userOffers.title')}</h1>
      {renderProjectsAndOffers(projects, offers)}
      {userRole === 'colaborador' && renderProjectsAndOffers(referredProjects, referredOffers, true)}
    </div>
  );
};

export default UserOffers;
