import React from 'react';

const LandPurchaseDetails = ({ data }) => {
  if (!data) return <div>No data available for Land Purchase.</div>;

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Land Purchase Details</h2>
      <p><strong>Land Price:</strong> {data.land_price}</p>
      <p><strong>Land Address:</strong> {data.land_address}</p>
      <p><strong>Has License:</strong> {data.has_license ? "Yes" : "No"}</p>
      <p><strong>License PDF:</strong> {data.license_pdf}</p>
      <p><strong>Appraisal Price:</strong> {data.appraisal_price}</p>
      <p><strong>Nota Simple Text:</strong> {data.nota_simple_text}</p>
      <p><strong>Nota Simple PDF:</strong> {data.nota_simple_pdf}</p>
      <p><strong>Sale Contract PDF:</strong> {data.sale_contract_pdf}</p>
      <p><strong>Tipo:</strong> {data.tipo}</p>
      <p><strong>Uso:</strong> {data.uso}</p>
      <p><strong>Superficie:</strong> {data.superficie}</p>
      <p><strong>Edificabilidad:</strong> {data.edificabilidad}</p>
      <p><strong>Numero de Parcelas:</strong> {data.numero_parcelas}</p>
      <p><strong>Calificación:</strong> {data.calificacion}</p>
      <p><strong>Verification Status:</strong> {data.verification_status}</p>
    </div>
  );
};

export default LandPurchaseDetails;
