import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const DueDiligenceEdit = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [dueDiligenceData, setDueDiligenceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDueDiligenceData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.finanbid.com/api/admin/duediligence/${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Convertir valores de objetos en strings JSON para edición
          const parsedData = JSON.parse(data.initial_dd);
          setDueDiligenceData(parsedData);
        } else {
          console.error('Error loading Due Diligence data');
        }
      } catch (error) {
        console.error('Error fetching Due Diligence data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDueDiligenceData();
  }, [projectId]);

  const handleInputChange = (key, value) => {
    setDueDiligenceData(prevData => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/admin/duediligence/${projectId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ initial_dd: JSON.stringify(dueDiligenceData) }), // Convertir a JSON string
      });

      if (response.ok) {
        alert('Due Diligence updated successfully');
        navigate('/admin/reports'); // Redirecciona de vuelta al listado
      } else {
        console.error('Error updating Due Diligence');
      }
    } catch (error) {
      console.error('Error saving Due Diligence data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Edit Due Diligence - Project ID: {projectId}</h2>
      {Object.entries(dueDiligenceData).map(([key, value]) => (
        <div key={key} className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">{key}</label>
          <textarea
            value={typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="w-full p-2 border border-gray-300 rounded resize-none"
            rows={typeof value === 'object' ? 4 : 1}
          />
        </div>
      ))}
      <button
        onClick={handleSave}
        className="mt-4 bg-secondary text-white px-4 py-2 rounded"
      >
        Save Changes
      </button>
    </div>
  );
};

export default DueDiligenceEdit;
