import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Asegúrate de apuntar al puerto correcto
      const response = await axios.post('https://api.finanbid.com/api/login', { email, password });
      const { token, userId, role } = response.data;

      // Guardar el token en el localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('role', role);

      // Verificar si hay redirección pendiente
      const redirectPath = new URLSearchParams(location.search).get('redirect');
      if (redirectPath) {
        navigate(redirectPath); // Redirigir a la página solicitada antes de iniciar sesión
      } else {
        // Redirigir según el rol si no hay redirección pendiente
        if (role === 'promotor') {
          navigate('/dashboard');
        } else if (role === 'fondo') {
          navigate('/fund/dashboard');
        } else if (role === 'colaborador') {
          navigate('/dashboard');
        } else if (role === 'admin') {
          navigate('/admin/dashboard');
        }
      }
    } catch (err) {
      // Mostrar mensaje de error en caso de fallo en la autenticación
      setError(t('login.error'));
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-back">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-secondary">{t('login.title')}</h2>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{t('login.email')}</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700">{t('login.password')}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-secondary text-white py-2 px-4 rounded-md hover:bg-opacity-90"
          >
            {t('login.loginButton')}
          </button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-sm">
            {t('login.noAccount')} <a href="/register" className="text-secondary">{t('login.signup')}</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
