import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const DashboardHome = ({ stats, isCollaborator, collaboratorCode, openCreateProjectModal, openHelpWidget, latestUpdate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateProject = () => {
    navigate("projects", { state: { createNewProject: true } });
};


  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {/* Welcome Header */}
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800">{t('dashboard.welcome')}, {stats?.user_name || t('dashboard.user')}</h1>
        <p className="text-gray-600">{t('dashboard.tagline')}</p>
      </div>

      {/* Main Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
          <h3 className="text-lg font-medium text-gray-700 mb-4">{t('dashboard.projects')}</h3>
          <p className="text-4xl font-bold text-indigo-600">{stats?.project_count || 0}</p>
          <a
            href="https://www.finanbid.com/dashboard/projects"
            className="mt-4 text-sm text-indigo-600 underline hover:no-underline"
          >
            {t('dashboard.seeProjects')}
          </a>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
          <h3 className="text-lg font-medium text-gray-700 mb-4">{t('dashboard.inbox')}</h3>
          <p className="text-4xl font-bold text-orange-600">{stats?.inbox_count || 0}</p>
          <a
            href="/dashboard/inbox"
            className="mt-4 text-sm text-orange-600 underline hover:no-underline"
          >
            {t('dashboard.seeMessages')}
          </a>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
          <h3 className="text-lg font-medium text-gray-700 mb-4">{t('dashboard.recentOffers')}</h3>
          <p className="text-4xl font-bold text-green-600">{stats?.offer_count || 0}</p>
          <a
            href="https://www.finanbid.com/dashboard/offers"
            className="mt-4 text-sm text-green-600 underline hover:no-underline"
          >
            {t('dashboard.seeOffers')}
          </a>
        </div>

        {isCollaborator && (
          <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
            <h3 className="text-lg font-medium text-gray-700 mb-4">{t('dashboard.collaboratorCode')}</h3>
            <p className="text-2xl font-bold text-purple-600">{collaboratorCode || t('dashboard.noCode')}</p>
            <p className="text-sm text-gray-500">{t('dashboard.collaboratorCodeInfo')}</p>
          </div>
        )}
      </div>

      {/* Secondary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
        <div className="bg-gray-800 text-white rounded-xl shadow-lg p-6">
          <h3 className="text-lg font-medium mb-4">{t('dashboard.pendingTasks')}</h3>
          <p>{t('dashboard.noPendingTasks')}</p>
        </div>

        <div className="bg-gray-800 text-white rounded-xl shadow-lg p-6 flex flex-col">
          <h3 className="text-lg font-medium mb-4">{t('dashboard.platformUpdates')}</h3>
          {latestUpdate ? (
            <div className="text-sm">
              <div className="flex justify-between space-x-4">
                <span>
                  {new Date(latestUpdate[0].update_date).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit'
                  })}
                </span>

                <span>
                  <strong>{latestUpdate[0].version}:</strong> {latestUpdate[0].update_text}
                </span>
              </div>
            </div>
          ) : (
            <p>{t('dashboard.noUpdates')}</p>
          )}
        </div>

        <div className="bg-gray-800 text-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
          <h3 className="text-lg font-medium mb-4">{t('dashboard.support')}</h3>
          <p>{t('dashboard.supportDescription')}</p>
          <button
            onClick={openHelpWidget}
            className="mt-4 bg-primary text-white px-4 py-2 rounded-lg hover:bg-opacity-90"
          >
            {t('dashboard.contactSupport')}
          </button>
        </div>

        <div className="bg-gray-800 text-white rounded-xl shadow-lg p-6 flex flex-col justify-between">
          <h3 className="text-lg font-medium mb-4">{t('dashboard.createProject')}</h3>
          <p>{t('dashboard.inviteCreateProject')}</p>
          <button
            onClick={handleCreateProject}
            className="mt-4 bg-secondary text-white px-4 py-2 rounded-lg hover:bg-opacity-90"
          >
            {t('dashboard.createNow')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
