import React, { useState, useEffect } from "react";
import CollateralList from "./CollateralList"; // Componente para listar colaterales
import CollateralForm from "./CollateralForm"; // Componente para crear/editar colaterales
import LTVCards from "./LTVCards"; // Reutilizamos las tarjetas de LTV
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Step4CollateralDetails = ({ collaterals, projectId, ltv, onUpdate, errors }) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false); // Mostrar el formulario de colaterales
  const [editingCollateral, setEditingCollateral] = useState(null); // Colateral que se está editando

  // Guardar un colateral nuevo o editado
  const handleSaveCollateral = (collateral) => {
    const updatedCollaterals = editingCollateral
      ? collaterals.map((c) => (c.id === collateral.id ? collateral : c)) // Editar el colateral existente
      : [...collaterals, collateral]; // Agregar un nuevo colateral
  
  
  
    // Pasar los colaterales actualizados al padre

    onUpdate({ collaterals: updatedCollaterals });
    setEditingCollateral(null);
    setShowForm(false);
  };
 
  // Eliminar un colateral
  const handleDeleteCollateral = async (collateralId) => {
    try {
      // Llamada a la API para eliminar el colateral
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.finanbid.com/api/collaterals/${collateralId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al eliminar el colateral');
      }
  
      // Actualizar el estado local eliminando el colateral
      const updatedCollaterals = collaterals.filter((c) => c.id !== collateralId);
      onUpdate(updatedCollaterals);
    } catch (error) {
      console.error('Error al eliminar el colateral:', error);
      alert('No se pudo eliminar el colateral. Inténtalo nuevamente.');
    }
  };
  
 
  useEffect(() => {

  }, [collaterals]);

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Contenido principal */}
      <div className="w-full lg:w-2/3 space-y-6">
        {/* LTV Cards */}
        {!showForm && <LTVCards ltv={ltv} />}

        {/* Lista de colaterales o formulario */}
        {showForm ? (
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 max-h-[80vh] overflow-y-auto">
            <CollateralForm
              collateral={editingCollateral}
              projectId={projectId} // Pasar el project_id al CollateralForm
              onSave={handleSaveCollateral}
              onCancel={() => {
                setEditingCollateral(null);
                setShowForm(false);
              }}
            />
          </div>
        ) : (
          <CollateralList
            collaterals={collaterals || []}
            onEdit={(collateral) => {
              setEditingCollateral(collateral);
              setShowForm(true);
            }}
            onDelete={handleDeleteCollateral}
            onAddNew={() => setShowForm(true)}
          />
        )}

        {/* Errores */}
        {errors?.collaterals && <p className="text-red-500 text-sm mt-2">{errors.collaterals}</p>}
      </div>

      {/* Consejos */}
      <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
        <h4 className="text-md font-medium text-gray-700 flex items-center">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
          {t("tipTitle")}
        </h4>
        <p className="text-sm text-gray-600 mt-2">
          {t("step4Tip")
            .split("\n")
            .map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </p>
      </div>
    </div>
  );
};

export default Step4CollateralDetails;
