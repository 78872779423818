import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faTimes, faClock } from '@fortawesome/free-solid-svg-icons';

const Collaterals = ({ collaterals, projectId, onAddCollateral }) => {
  const [showModal, setShowModal] = useState(false);

  const handleAddCollateral = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="p-4 align-top">
      <div className="flex justify-between items-center mb-4 ">
        <h3 className="text-lg font-semibold">Garantías adicionales</h3>
        <button
          onClick={handleAddCollateral}
          className="text-secondary bg-white rounded-full p-2 hover:bg-secondary hover:text-white"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="grid gap-4">
        {collaterals.map((collateral, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 border-b text-sm"
          >
            <span className="flex-1">{collateral.tipo_garantia}</span>
            <span className="flex-1 text-right">
              {collateral.valor_total_garantia.toLocaleString('es-ES', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
            <span className="flex items-center ml-2">
              <FontAwesomeIcon
                icon={
                  collateral.verification_status === 'verificado' ? faCheck
                    : collateral.verification_status === 'erroneo' ? faTimes
                      : faClock
                }
                className={
                  collateral.verification_status === 'verificado' ? 'text-green-500'
                    : collateral.verification_status === 'erroneo' ? 'text-red-500'
                      : 'text-orange-500'
                }
              />
            </span>
          </div>
        ))}
      </div>


      {showModal && (
        <AddCollateralModal
          projectId={projectId}
          onClose={closeModal}
          onAdd={onAddCollateral}
        />
      )}
    </div>
  );
};

// Modal para Añadir Garantía
const AddCollateralModal = ({ projectId, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    tipo_garantia: '',
    tipo_activo: '',
    valor_tasacion: '',
    valor_compraventa: '',
    libre_de_cargas: true,
    embargos: '',
    valor_embargos: '',
    numero_hipotecas: '',
    valor_hipotecas: '',
    propietario_id: '',
    tipo_propietario: '',
    numero_acciones: '',
    valor_acciones: '',
    porcentaje_participacion: '',
    pignoracion: '',
    tipo_pignoracion: '',
    derecho_cobro: '',
    tipo_derecho_cobro: '',
    entidad_pagadora: '',
    fecha_estimada_pago: '',
    valor_total_garantia: 0,
    verification_status: 'En verificación',
  });

  const [notaSimplePdf, setNotaSimplePdf] = useState(null);
  const [tasacionPdf, setTasacionPdf] = useState(null);
  const [contratoPdf, setContratoPdf] = useState(null);
  const [justificacionCobroPdf, setJustificacionCobroPdf] = useState(null);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      valor_total_garantia: calculateValorTotalGarantia(),
    }));
  }, [
    formData.valor_tasacion,
    formData.valor_embargos,
    formData.valor_hipotecas,
    formData.valor_acciones,
    formData.pignoracion,
    formData.derecho_cobro
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const calculateValorTotalGarantia = () => {
    if (formData.tipo_garantia === 'Garantía Hipotecaria') {
      return (
        parseFloat(formData.valor_tasacion || 0) -
        parseFloat(formData.valor_embargos || 0) -
        parseFloat(formData.valor_hipotecas || 0)
      );
    } else if (formData.tipo_garantia === 'Acciones') {
      return parseFloat(formData.valor_acciones || 0);
    } else if (formData.tipo_garantia === 'Pignoración') {
      return parseFloat(formData.valor_total_garantia || 0);
    } else if (formData.tipo_garantia === 'Derechos de cobro') {
      return parseFloat(formData.valor_total_garantia || 0);
    }
    return 0;
  };

  const handleAdd = async () => {
    const cleanCollateralData = {
      ...formData,
      project_id: projectId,
      valor_total_garantia: calculateValorTotalGarantia(),
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.finanbid.com/api/add_collateral', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cleanCollateralData),
      });

      if (!response.ok) {
        throw new Error('Error al añadir la garantía');
      }

      const newCollateral = await response.json();
      await handleDocumentUpload(newCollateral.id);
      onAdd(newCollateral);
      onClose();
    } catch (error) {
      console.error('Error al añadir la garantía:', error);
    }
  };

  const handleDocumentUpload = async (collateralId) => {
    const uploadDocument = async (file, documentType) => {
      if (!file) return;

      const formData = new FormData();
      formData.append('collateral_id', collateralId);
      formData.append('document_type', documentType);
      formData.append('document', file);
      const uploadedBy = localStorage.getItem('userId') || 8; 
        formData.append('uploaded_by', uploadedBy);

      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.finanbid.com/api/upload-collateral-document', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Error al subir el documento');
        }
      } catch (error) {
        console.error(`Error al subir el documento (${documentType}):`, error);
      }
    };

    if (formData.tipo_garantia === 'Garantía Hipotecaria') {
      await uploadDocument(notaSimplePdf, 'nota_simple');
      await uploadDocument(tasacionPdf, 'tasacion');
      await uploadDocument(contratoPdf, 'contrato_compraventa');
    } else if (formData.tipo_garantia === 'Derechos de cobro') {
      await uploadDocument(justificacionCobroPdf, 'justificacion_cobro');
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-3xl w-full">
        <h3 className="text-lg font-semibold mb-4">Añadir Garantía</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Tipo de Garantía</label>
            <select
              name="tipo_garantia"
              value={formData.tipo_garantia}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            >
              <option value="">Selecciona</option>
              <option value="Garantía Hipotecaria">Garantía Hipotecaria</option>
              <option value="Acciones">Acciones</option>
              <option value="Pignoración">Pignoración</option>
              <option value="Derechos de cobro">Derechos de cobro</option>
            </select>
          </div>

          {/* Campos para cada tipo de garantía y documentos adicionales */}
          {formData.tipo_garantia === 'Garantía Hipotecaria' && (
            <>
              <div>
                <label className="block text-sm font-medium mb-1">Tipo de Activo</label>
                <select
                  name="tipo_activo"
                  value={formData.tipo_activo}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Selecciona</option>
                  <option value="Vivienda">Vivienda</option>
                  <option value="Unifamiliar">Unifamiliar</option>
                  <option value="Villa">Villa</option>
                  <option value="Edificio">Edificio</option>
                  <option value="Terreno">Terreno</option>
                  <option value="Nave">Nave</option>
                  <option value="Local Comercial">Local Comercial</option>
                  <option value="Otros">Otros</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Valor de Tasación</label>
                <input
                  type="number"
                  name="valor_tasacion"
                  value={formData.valor_tasacion}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="€"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Valor de Compraventa</label>
                <input
                  type="number"
                  name="valor_compraventa"
                  value={formData.valor_compraventa}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="€"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="libre_de_cargas"
                  checked={formData.libre_de_cargas}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label className="text-sm font-medium">Libre de cargas</label>
              </div>
              {!formData.libre_de_cargas && (
                <>
                  <div>
                    <label className="block text-sm font-medium mb-1">Embargos</label>
                    <input
                      type="text"
                      name="embargos"
                      value={formData.embargos}
                      onChange={handleChange}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Valor de Embargos</label>
                    <input
                      type="number"
                      name="valor_embargos"
                      value={formData.valor_embargos}
                      onChange={handleChange}
                      className="w-full p-2 border rounded"
                      placeholder="€"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Hipotecas sobre el activo (número)</label>
                    <input
                      type="number"
                      name="numero_hipotecas"
                      value={formData.numero_hipotecas}
                      onChange={handleChange}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Valor total de las hipotecas</label>
                    <input
                      type="number"
                      name="valor_hipotecas"
                      value={formData.valor_hipotecas}
                      onChange={handleChange}
                      className="w-full p-2 border rounded"
                      placeholder="€"
                    />
                  </div>
                </>
              )}
              {/* Inputs para subir documentos */}
              <div>
                <label className="block text-sm font-medium mb-1">Nota Simple (PDF)</label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => handleFileChange(e, setNotaSimplePdf)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Tasación (PDF)</label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => handleFileChange(e, setTasacionPdf)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Contrato de Compraventa (PDF)</label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => handleFileChange(e, setContratoPdf)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </>
          )}

          {formData.tipo_garantia === 'Acciones' && (
            <>
              <div>
                <label className="block text-sm font-medium mb-1">Número de Acciones</label>
                <input
                  type="number"
                  name="numero_acciones"
                  value={formData.numero_acciones}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Valor Total de las Acciones</label>
                <input
                  type="number"
                  name="valor_acciones"
                  value={formData.valor_acciones}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="€"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Porcentaje de Participación</label>
                <input
                  type="number"
                  name="porcentaje_participacion"
                  value={formData.porcentaje_participacion}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="%"
                />
              </div>
            </>
          )}

          {formData.tipo_garantia === 'Pignoración' && (
            <>
              <div>
                <label className="block text-sm font-medium mb-1">Tipo de Pignoración</label>
                <input
                  type="text"
                  name="tipo_pignoracion"
                  value={formData.tipo_pignoracion}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Descripción del Activo Pignorado</label>
                <input
                  type="text"
                  name="pignoracion"
                  value={formData.pignoracion}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Valor del Activo Pignorado</label>
                <input
                  type="number"
                  name="valor_total_garantia"
                  value={formData.valor_total_garantia}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="€"
                />
              </div>
            </>
          )}

          {formData.tipo_garantia === 'Derechos de cobro' && (
            <>
              <div>
                <label className="block text-sm font-medium mb-1">Sobre qué se realiza el derecho de cobro</label>
                <input
                  type="text"
                  name="derecho_cobro"
                  value={formData.derecho_cobro}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Tipo de Derecho de Cobro</label>
                <input
                  type="text"
                  name="tipo_derecho_cobro"
                  value={formData.tipo_derecho_cobro}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Entidad Pagadora</label>
                <input
                  type="text"
                  name="entidad_pagadora"
                  value={formData.entidad_pagadora}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Fecha Estimada de Pago</label>
                <input
                  type="date"
                  name="fecha_estimada_pago"
                  value={formData.fecha_estimada_pago}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Valor del Derecho de Cobro</label>
                <input
                  type="number"
                  name="valor_total_garantia"
                  value={formData.valor_total_garantia}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="€"
                />
              </div>
              {/* Input para subir documento de justificación */}
              <div>
                <label className="block text-sm font-medium mb-1">Justificación de Cobro (PDF)</label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => handleFileChange(e, setJustificacionCobroPdf)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </>
          )}
        </div>

        <div className="mt-4 flex justify-end">
          <button onClick={onClose} className="text-gray-600 mr-4">Cancelar</button>
          <button onClick={handleAdd} className="bg-blue-500 text-white px-4 py-2 rounded">Añadir</button>
        </div>
      </div>
    </div>
  );
};

export default Collaterals;
