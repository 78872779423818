import React from 'react';
import { useTranslation } from 'react-i18next';

const AcercaDe = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-900 py-16 text-white mt-20">
      {/* Hero Section */}
      <div className="text-center max-w-2xl mx-auto mb-16">
        <h1 className="text-4xl font-bold mb-4">{t('acercaDe.heroTitle')}</h1>
        <p className="text-lg text-gray-300">{t('acercaDe.heroSubtitle')}</p>
      </div>

      {/* Mission Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.missionTitle')}
        </h2>
        <p className="text-lg text-gray-300 leading-relaxed">
          {t('acercaDe.missionDescription')}
        </p>
      </div>

      {/* How It Works Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.howItWorksTitle')}
        </h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.promotersTitle')}</h3>
            <p className="text-gray-300">{t('acercaDe.promotersDescription')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.investorsTitle')}</h3>
            <p className="text-gray-300">{t('acercaDe.investorsDescription')}</p>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.valuesTitle')}
        </h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value1Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value1Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value2Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value2Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value3Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value3Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value4Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value4Description')}</p>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.whyChooseUsTitle')}
        </h2>
        <p className="text-lg text-gray-300 leading-relaxed">
          {t('acercaDe.whyChooseUsDescription')}
        </p>
      </div>
    </section>
  );
};

export default AcercaDe;
