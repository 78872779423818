import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faCheck, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";

const AssetForm = ({ asset, projectId, onSave, onCancel }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    type: "",
    descripcion: "",
    valor_compraventa: "",
    valor_tasacion: "",
    valor_tasacion_final: "",
    licencia: false,
    registro_propiedad: "",
    referencia_catastral: "",
    titular: "",
    finca: "",
    tomo: "",
    folio: "",
    alta: "",
    libre_de_cargas: true,
    embargos: "",
    valor_embargos: "",
    numero_hipotecas: "",
    valor_hipotecas: "",
    documentos: [], // Lista de documentos
  });

  const [newDocument, setNewDocument] = useState({ type: "", file: null });

  const fetchDocuments = async (assetId) => {
    try {
      const response = await fetch(`https://api.finanbid.com/api/assets/${assetId}/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Error al obtener los documentos del activo");
      }
      const data = await response.json();
      setFormData((prev) => ({
        ...prev,
        documentos: data.documents || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (asset) {
      setFormData({
        ...asset,
        alta: asset.alta ? asset.alta.split("T")[0] : "", // Extraer solo la fecha
        documentos: asset.documents || [],
      });
    }
  }, [asset]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = async () => {
    try {
      const requestBody = {
        ...formData,
        project_id: projectId, // Incluir el project_id
      };

      // Crear o actualizar el asset
      const response = await fetch(
        asset
          ? `https://api.finanbid.com/api/assets/${asset.id}`
          : "https://api.finanbid.com/api/assets",
        {
          method: asset ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Error al guardar el activo");
      }

      const savedAsset = await response.json();

      // Subir documentos asociados (solo si hay nuevos documentos con archivo)
      for (const doc of formData.documentos) {
        if (doc.file) {
          const formDataDoc = new FormData();
          formDataDoc.append("document", doc.file);
          formDataDoc.append("document_type", doc.type);
          formDataDoc.append("asset_id", savedAsset.id);

          const docResponse = await fetch(
            "https://api.finanbid.com/api/upload-asset-document",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formDataDoc,
            }
          );

          if (!docResponse.ok) {
            throw new Error("Error al subir un documento");
          }
        }
      }

      // Sincronizar documentos después de guardar
      fetchDocuments(savedAsset.id);

      onSave(savedAsset); // Pasar el activo guardado al padre
    } catch (error) {
      console.error(error);
    }
  };

  const addDocument = () => {
    if (newDocument.file && newDocument.type) {
      setFormData((prev) => ({
        ...prev,
        documentos: [...prev.documentos, { ...newDocument, id: null }], // Añadimos con id nulo hasta que se guarde
      }));
      setNewDocument({ type: "", file: null });
    }
  };

  const removeDocument = async (index) => {
    const docToRemove = formData.documentos[index];

    // Si el documento no tiene un archivo asociado, solo se elimina de la lista
    if (!docToRemove.id) {
      setFormData((prev) => ({
        ...prev,
        documentos: prev.documentos.filter((_, i) => i !== index),
      }));
      return;
    }

    try {
      // Llamada a la API para eliminar el documento
      const response = await fetch(
        `https://api.finanbid.com/api/asset-documents/${docToRemove.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error al eliminar el documento del servidor");
    }

    // Actualizar la lista de documentos en el estado local
    setFormData((prev) => ({
        ...prev,
        documentos: prev.documentos.filter((_, i) => i !== index),
    }));
} catch (error) {
    console.error(error);
    alert(t("errorDeletingDocument")); // Agrega una traducción adecuada para este mensaje
}
};
  

  const renderFloatingLabelInput = (name, label, type = "text", additionalProps = {}) => (
    <div className="relative z-0 w-full group">
      <input
        type={type}
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        className="block py-2.5 px-0 w-full text-sm text-gray-900 border-0 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer focus:bg-gray-50"
        placeholder=" "
        {...additionalProps}
      />
      <label
    htmlFor={name}
    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:text-blue-600 peer-focus:translate-y-[6px] peer-focus:scale-75"
  >
    {label}
  </label>
        
    </div>
  );

  return (
    <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 shadow-md text-sm">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">
        {asset ? t("editAssetTitle") : t("addAssetTitle")}
      </h3>

      {/* Campos principales */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {renderFloatingLabelInput("type", t("assetType"))}
        {renderFloatingLabelInput("descripcion", t("description"), "text", { maxLength: 255 })}
        {renderFloatingLabelInput("valor_compraventa", t("purchaseValue"), "number")}
        {renderFloatingLabelInput("valor_tasacion", t("currentValuation"), "number")}
        {renderFloatingLabelInput("valor_tasacion_final", t("finalValuation"), "number")}
        <div className="flex items-center mt-1">
          <input
            type="checkbox"
            name="licencia"
            checked={formData.licencia}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="text-sm font-medium text-gray-700">{t("licenseStatus")}</label>
        </div>
      </div>
      <div className="flex items-center mt-1">
  <input
    type="checkbox"
    name="libre_de_cargas"
    checked={formData.libre_de_cargas}
    onChange={handleChange}
    className="mr-2 mb-4"
  />
  <label className="text-sm font-medium text-gray-700 mb-4">{t("mortgageFree")}</label>
</div>

{!formData.libre_de_cargas && (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    {renderFloatingLabelInput("embargos", t("embargo"))}
    {renderFloatingLabelInput("valor_embargos", t("embargoValue"), "number")}
    {renderFloatingLabelInput("numero_hipotecas", t("hipotecas"), "number")}
    {renderFloatingLabelInput("valor_hipotecas", t("hipotecasValue"), "number")}
  </div>
)}

      {/* Datos registrales */}
      <h4 className="text-sm font-semibold text-gray-800 mb-4 mt-4">{t("registryData")}</h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderFloatingLabelInput("registro_propiedad", t("propertyRegistry"))}
        {renderFloatingLabelInput("referencia_catastral", t("cadastralReference"))}
        {renderFloatingLabelInput("titular", t("owner"))}
        {renderFloatingLabelInput("finca", t("finca"))}
        {renderFloatingLabelInput("tomo", t("tome"))}
        {renderFloatingLabelInput("folio", t("folio"))}
        {renderFloatingLabelInput("alta", t("high"), "date")}
      </div>

       {/* Lista de documentos */}
       <h4 className="text-sm font-semibold text-gray-800 mb-2 mt-4">{t("documents")}</h4>
      <ul className="space-y-2">
        {formData.documentos.map((doc, index) => (
          <li key={index} className="flex justify-between items-center border-b border-gray-300 py-2">
            <span className="text-gray-700">{doc.document_type || t("documentType")}</span>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => removeDocument(index)}
              className="text-red-500 cursor-pointer hover:text-red-700"
            />
          </li>
        ))}
      </ul>

     {/* Subir documento */}
<div className="bg-gray-100 p-4 rounded-lg mt-4 space-y-2">
  <h5 className="text-sm font-bold text-gray-800">{t("uploadDocument")}</h5>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
    {/* Input para tipo de documento */}
    <input
      type="text"
      placeholder={t("documentType")}
      value={newDocument.type}
      onChange={(e) => setNewDocument({ ...newDocument, type: e.target.value })}
      className="w-full p-2 border border-gray-300 rounded-lg"
    />
    {/* Input para subir archivo */}
    <div className="relative flex items-center">
      <input
        type="file"
        onChange={(e) => setNewDocument({ ...newDocument, file: e.target.files[0] })}
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
      <div className="w-full h-full p-2 border border-gray-300 rounded-lg flex items-center justify-center text-gray-500">
        {newDocument.file ? (
          <FontAwesomeIcon icon={faCheck} className="text-green-500" />
        ) : (
          t("selectFile")
        )}
      </div>
    </div>
  </div>
  {/* Botón para añadir documento */}
  <div className="flex justify-end mt-2">
    <button
      onClick={addDocument}
      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center gap-2"
    >
      <FontAwesomeIcon icon={faUpload} />
      {t("add")}
    </button>
  </div>
</div>
      {/* Botones de acción */}
      <div className="flex justify-end mt-4 gap-4">
        <button
          onClick={onCancel}
          className="flex items-center gap-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
        >
          <FontAwesomeIcon icon={faTimes} />
          {t("cancel")}
        </button>
        <button
          onClick={handleSave}
          className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          <FontAwesomeIcon icon={faSave} />
          {t("save")}
        </button>
      </div>
    </div>
  );
};

export default AssetForm;
