import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openProjectDetails = () => {
    navigate(`/fund/dashboard/project/${project.id}`);
  };

  // Obtener nombres legibles para sector, subsector y tipo de producto
  const sectorName = t(`projectUpload.sectors.${project.sector_id || 'unknown'}`, {
    defaultValue: 'Sector desconocido',
  });
  const subsectorName = t(`projectUpload.subsectors.${project.subsector_id || 'unknown'}`, {
    defaultValue: 'Subsector desconocido',
  });
  const productTypeName = t(`projectUpload.products.${project.product_type || 'unknown'}`, {
    defaultValue: 'Producto desconocido',
  });

  // Definir colores según el sector
  const sectorColors = {
    1: 'bg-blue-500 text-white', // Inmobiliario
    2: 'bg-green-500 text-white', // Empresa
    3: 'bg-orange-500 text-white', // Startup
    4: 'bg-yellow-500 text-black', // Renovables
    unknown: 'bg-gray-500 text-white', // Desconocido
  };

  // Seleccionar el color según el sector
  const sectorColor = sectorColors[project.sector_id] || sectorColors.unknown;

  return (
    <div
      className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300"
      onClick={openProjectDetails}
    >
      {/* Imagen del proyecto */}
      <img
        className="h-48 w-full object-cover"
        src={project.url_imagen_principal}
        alt={project.resumen || 'Imagen del proyecto'}
      />

      {/* Sector */}
      <div className={`py-0.5 text-center text-md font-semibold ${sectorColor}`}>
        {sectorName}
      </div>

      <div className="p-6">
        {/* Subsector y Tipo de Producto */}
        <div className="flex items-baseline justify-between">
          <span className="inline-block bg-teal-200 text-teal-800 py-1 px-4 text-xs rounded-full uppercase font-semibold tracking-wide">
            {subsectorName}
          </span>
          <span className="text-gray-600 text-xs uppercase font-semibold tracking-wide">
            {productTypeName}
          </span>
        </div>

        {/* Cantidad requerida */}
        <h4 className="mt-2 font-semibold text-lg leading-tight truncate">
          {new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          }).format(project.amount_requested)}
        </h4>

        {/* Tabla con LTV */}
        <div className="mt-2">
          <table className="w-full border-t border-b">
            <tbody>
              <tr>
                <td className="text-gray-600 text-sm font-medium py-1">LTV</td>
                <td className="text-teal-600 text-sm font-semibold py-1 text-right">
                  {project.ltv ? `${project.ltv}%` : 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Provincia y País */}
        <div className="mt-2 flex items-center">
          <span className="text-teal-600 font-semibold">
            {project.provincia || 'N/A'}
          </span>
          <span className="text-gray-600 text-sm ml-2">
            {project.pais || 'N/A'}
          </span>
        </div>

        {/* Fecha de creación */}
        <div className="mt-1 text-gray-500 text-sm">
        <span className="text-gray-600 text-sm">
            Id. {project.id || 'N/A'}
          </span>
        </div>
      </div>
    </div>
  );
};

// Definición de los tipos de propiedades para validación
ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url_imagen_principal: PropTypes.string.isRequired,
    sector_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    subsector_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    product_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amount_requested: PropTypes.number.isRequired,
    ltv: PropTypes.number,
    provincia: PropTypes.string,
    pais: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    resumen: PropTypes.string,
  }),
};

export default ProjectCard;
