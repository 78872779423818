import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // i18n para traducciones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Step2LoanDetails = ({ data, onUpdate, errors }) => {
  const { t } = useTranslation(); // Hook para traducciones
  const [formData, setFormData] = useState({
    amount_requested: "",
    capital_invertido: "",
    duracion_prestamo: "",
    devolucion_intereses: "",
    repago: "",
  });

  // Inicializar el formulario con datos del padre
  useEffect(() => {
    if (data) {
      setFormData({
        amount_requested: data.amount_requested || "",
        capital_invertido: data.capital_invertido || "",
        duracion_prestamo: data.duracion_prestamo || "",
        devolucion_intereses: data.devolucion_intereses || "",
        repago: data.repago || "",
      });
    }
  }, [data]);

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    onUpdate(updatedFormData); // Pasar datos actualizados al componente padre
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Formulario */}
      <div className="w-full lg:w-2/3">
        <h3 className="text-2xl font-semibold text-gray-800">{t("step2Title")}</h3>
        <p className="text-sm text-gray-600 mt-2">{t("step2Subtitle")}</p>
        <div className="mt-6 space-y-6">
          {/* Campo: Cantidad Solicitada */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("loanAmountQuestion")} <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="amount_requested"
              value={formData.amount_requested}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.amount_requested ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.amount_requested && (
              <p className="text-sm text-red-500 mt-1">{errors.amount_requested}</p>
            )}
          </div>

          {/* Campo: Capital Propio */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("ownCapitalQuestion")} <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="capital_invertido"
              value={formData.capital_invertido}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.capital_invertido ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.capital_invertido && (
              <p className="text-sm text-red-500 mt-1">{errors.capital_invertido}</p>
            )}
          </div>

          {/* Campo: Duración del Préstamo */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("loanDurationQuestion")} <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="duracion_prestamo"
              value={formData.duracion_prestamo}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.duracion_prestamo ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.duracion_prestamo && (
              <p className="text-sm text-red-500 mt-1">{errors.duracion_prestamo}</p>
            )}
          </div>

          {/* Campo: Flexibilidad en el Pago */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("flexibilityPaymentQuestion")} <span className="text-red-500">*</span>
            </label>
            <select
              name="devolucion_intereses"
              value={formData.devolucion_intereses}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.devolucion_intereses ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            >
              <option value="">{t("selectOption")}</option>
              <option value="Mensual">{t("monthly")}</option>
              <option value="Trimestral">{t("quarterly")}</option>
              <option value="Semestral">{t("semiAnnual")}</option>
              <option value="Bullet">{t("bullet")}</option>
            </select>
            {errors?.devolucion_intereses && (
              <p className="text-sm text-red-500 mt-1">{errors.devolucion_intereses}</p>
            )}
          </div>

          {/* Campo: Repago */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("repaymentQuestion")} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="repago"
              value={formData.repago}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md border ${
                errors?.repago ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200`}
            />
            {errors?.repago && <p className="text-sm text-red-500 mt-1">{errors.repago}</p>}
          </div>
        </div>
      </div>

      {/* Consejos */}
      <div className="w-full lg:w-1/3 bg-blue-50 rounded-lg p-4 border border-blue-200 shadow-sm">
        <h4 className="text-md font-medium text-gray-700 flex items-center">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-2" />
          {t("tipTitle")}
        </h4>
        <p className="text-sm text-gray-600 mt-2">
          {t("step2Tip").split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default Step2LoanDetails;
