import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AdministratorModal = ({ onClose, onSave }) => {
  const [name, setName] = useState('');
  const [documentId, setDocumentId] = useState(''); // Cambiado a documentId
  const [nationality, setNationality] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [fiscalAddress, setFiscalAddress] = useState('');
  const [profession, setProfession] = useState('');
  const [assets, setAssets] = useState('');
  const [debts, setDebts] = useState('');

  const handleSave = () => {
    const adminData = {
      administrator_name: name,
      document_id: documentId, // Cambiado a document_id
      nationality,
      marital_status: maritalStatus,
      fiscal_address: fiscalAddress,
      profession,
      assets,
      debts
    };
    onSave(adminData); // Enviar los datos al componente principal
    onClose(); // Cerrar el modal
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-60">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Añadir Administrador</h3>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="space-y-3">
          <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Nombre" className="p-2 border rounded w-full" />
          <input value={documentId} onChange={(e) => setDocumentId(e.target.value)} placeholder="DNI/NIE/ID" className="p-2 border rounded w-full" />
          <input value={nationality} onChange={(e) => setNationality(e.target.value)} placeholder="Nacionalidad" className="p-2 border rounded w-full" />
          <input value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)} placeholder="Estado Civil" className="p-2 border rounded w-full" />
          <input value={fiscalAddress} onChange={(e) => setFiscalAddress(e.target.value)} placeholder="Dirección Fiscal" className="p-2 border rounded w-full" />
          <input value={profession} onChange={(e) => setProfession(e.target.value)} placeholder="Profesión" className="p-2 border rounded w-full" />
          <textarea value={assets} onChange={(e) => setAssets(e.target.value)} placeholder="Activos" className="p-2 border rounded w-full" rows="2" />
          <textarea value={debts} onChange={(e) => setDebts(e.target.value)} placeholder="Deudas" className="p-2 border rounded w-full" rows="2" />
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default AdministratorModal;
