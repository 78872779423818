import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

const AssetList = ({ assets, onEdit, onDelete, onAddNew }) => {
  const { t } = useTranslation();
  //console.log("Activos recibidos:", assets);
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-2xl font-semibold text-gray-800">{t("assetListTitle")}</h3>
        <button
          onClick={onAddNew}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          <FontAwesomeIcon icon={faPlus} />
          {t("addNewAsset")}
        </button>
      </div>

      {/* Lista de activos */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
  {assets.map((asset) => (
    <div
      key={asset.id}
      className="bg-white shadow-md rounded-lg border border-gray-200 p-4 flex flex-col justify-between"
    >
      <div>
        <h4 className="text-lg font-semibold text-gray-800 mb-2">
          {t("assetType")}: {asset.type || t("unknown")}
        </h4>
        <p className="text-sm text-gray-600">
          {t("description")}: {asset.descripcion || t("noDescription")}
        </p>
        <p className="text-sm text-gray-600">
          {t("purchaseValue")}: {asset.valor_compraventa || t("notProvided")}
        </p>
        <p className="text-sm text-gray-600">
          {t("currentValuation")}: {asset.valor_tasacion || t("notProvided")}
        </p>
        <p className="text-sm text-gray-600">
          {t("finalValuation")}: {asset.valor_tasacion_final || t("notProvided")}
        </p>
        <p className="text-sm text-gray-600">
          {t("licenseStatus")}:{" "}
          {asset.licencia ? t("licenseGranted") : t("licenseNotGranted")}
        </p>
      </div>

      {/* Acciones */}
      <div className="flex justify-end gap-2 mt-4">
        <button
          onClick={() => onEdit(asset)}
          className="flex items-center gap-2 px-3 py-1 bg-yellow-400 text-white rounded-md hover:bg-yellow-500"
        >
          <FontAwesomeIcon icon={faEdit} />
          {t("edit")}
        </button>
        <button
          onClick={() => onDelete(asset.id)}
          className="flex items-center gap-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          <FontAwesomeIcon icon={faTrash} />
          {t("delete")}
        </button>
      </div>
    </div>
  ))}

        {/* Mostrar mensaje si no hay activos */}
        {assets.length === 0 && (
          <div className="col-span-full text-center text-gray-500">
            {t("noAssets")}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetList;
