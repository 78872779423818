import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faMapMarkerAlt,
  faGlobe,
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

const GeneralInfo = ({ project, onSaveProjectDetails }) => {
  const { t } = useTranslation();

  const statusColorMap = {
    Visible: "text-green-500",
    Incompleto: "text-orange-500",
    "En revisión": "text-orange-500",
    default: "text-red-500",
  };

  const [localProjectData, setLocalProjectData] = useState({
    resumen: project.resumen || "",
    direccion: project.direccion || "",
    localidad: project.localidad || "",
    provincia: project.provincia || "",
    pais: project.pais || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalProjectData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (field) => {
    onSaveProjectDetails({ ...localProjectData, field });
  };

  const creationDate = format(new Date(project.created_at), "dd/MM/yyyy");
  const statusColor = statusColorMap[project.status] || statusColorMap.default;

  return (
    <div className="p-6 bg-white rounded-lg space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold">
          <FontAwesomeIcon icon={faEdit} className="text-secondary mr-2" />
          {t(`projectUpload.products.${project.product_type}`)}
        </h2>
      </div>

      <div className="text-sm text-gray-600">
        <p>
          <strong>{t("Fecha de Creación")}:</strong> {creationDate}
        </p>
        <p className={`font-semibold mt-2 ${statusColor}`}>
          <FontAwesomeIcon
            icon={
              project.status === "Visible"
                ? faCheckCircle
                : faExclamationCircle
            }
            className="mr-1"
          />
          {t("Estado")}: {project.status}
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-2">
          <label className="text-sm font-medium text-gray-600">
            {t("projectUpload.step2.description")}
          </label>
          <textarea
            name="resumen"
            className="text-sm w-full p-3 border border-gray-600 rounded-lg resize-none focus:ring-secondary focus:border-secondary"
            rows="7"
            value={localProjectData.resumen}
            placeholder={t("projectUpload.step2.description")}
            onChange={handleChange}
            onBlur={() => handleBlur("resumen")}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-600 flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1 text-secondary" />
            {t("projectUpload.step2.address")}
          </label>
          <input
            type="text"
            name="direccion"
            className="text-sm w-full p-3 border rounded-lg focus:ring-secondary focus:border-secondary"
            value={localProjectData.direccion}
            placeholder={t("projectUpload.step2.address")}
            onChange={handleChange}
            onBlur={() => handleBlur("direccion")}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-600 flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1 text-secondary" />
            {t("projectUpload.step2.city")}
          </label>
          <input
            type="text"
            name="localidad"
            className="text-sm w-full p-3 border rounded-lg focus:ring-secondary focus:border-secondary"
            value={localProjectData.localidad}
            placeholder={t("projectUpload.step2.city")}
            onChange={handleChange}
            onBlur={() => handleBlur("localidad")}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-600 flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1 text-secondary" />
            {t("projectUpload.step2.province")}
          </label>
          <input
            type="text"
            name="provincia"
            className="text-sm w-full p-3 border rounded-lg focus:ring-secondary focus:border-secondary"
            value={localProjectData.provincia}
            placeholder={t("projectUpload.step2.province")}
            onChange={handleChange}
            onBlur={() => handleBlur("provincia")}
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-600 flex items-center">
            <FontAwesomeIcon icon={faGlobe} className="mr-1 text-secondary" />
            {t("País")}
          </label>
          <input
            type="text"
            name="pais"
            className="text-sm w-full p-3 border shadow rounded-lg focus:ring-secondary focus:border-secondary"
            value={localProjectData.pais}
            placeholder={t("País")}
            onChange={handleChange}
            onBlur={() => handleBlur("pais")}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
