import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Reports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchReports = async () => {
        try {
          const response = await fetch('https://api.finanbid.com/api/admin/duediligence', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setReports(data);
          } else if (response.status === 401 || response.status === 403) {
            navigate('/login');
          } else {
            console.error(t('reports.errorLoadingReports'));
          }
        } catch (error) {
          console.error(t('reports.errorLoadingReports'), error);
        }
      };

      fetchReports();
    }
  }, [t, navigate]);

  const handleProjectClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  const handleViewInitialDD = (report) => {
    navigate(`/admin/reports/${report.project_id}/initial-dd`, { state: { initial_dd: report.initial_dd } });
  };

  const handleGenerateDDIA = async (projectId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.finanbid.com/api/projects/${projectId}/due-diligence-ai`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        alert(t('reports.ddiaGeneratedSuccessfully'));
      } else {
        console.error(t('reports.errorGeneratingDDIA'));
      }
    } catch (error) {
      console.error(t('reports.errorGeneratingDDIA'), error);
    }
  };

  const handleGenerateTemplate = async (report) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://api.finanbid.com/api/duediligence/generate-template', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId: report.project_id, // Cambiado a projectId para coincidir con el backend
          json: report.initial_dd,
        }),
      });
  
      if (response.ok) {
        alert('Template generated successfully');
      } else {
        console.error('Error generating template');
      }
    } catch (error) {
      console.error('Error generating template', error);
    }
  };
  

  const handleViewTemplate = (report) => {
    if (report.template) {
      const blob = new Blob([report.template], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } else {
      alert(t('reports.noTemplateFound'));
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-full">
      <h2 className="text-2xl font-bold mb-6">{t('reports.title')}</h2>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                {t('reports.projectId')}
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                {t('reports.initialDD')}
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                {t('reports.generateDDIA')}
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                {t('reports.generateTemplate')}
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                {t('reports.viewTemplate')}
              </th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.project_id} className="hover:bg-gray-100">
                <td
                  className="px-6 py-4 border-b border-gray-200 text-sm text-blue-600 cursor-pointer"
                  onClick={() => handleProjectClick(report.project_id)}
                >
                  {report.project_id}
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button
                    className="bg-secondary text-white px-4 py-1 rounded"
                    onClick={() => handleViewInitialDD(report)}
                  >
                    {t('reports.viewInitialDD')}
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button
                    className="bg-blue-500 text-white px-4 py-1 rounded"
                    onClick={() => handleGenerateDDIA(Number(report.project_id))}
                  >
                    {t('reports.generateDDIA')}
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button
                    className="bg-green-500 text-white px-4 py-1 rounded"
                    onClick={() => handleGenerateTemplate(report)}
                  >
                    {t('reports.generateTemplate')}
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button
                    className="bg-purple-500 text-white px-4 py-1 rounded"
                    onClick={() => handleViewTemplate(report)}
                  >
                    {t('reports.viewTemplate')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reports;

