import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const LandPurchaseLoanDetails = ({ details, onSave, projectId }) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  const initialDetails = {
    land_price: details?.land_price || '',
    has_license: details?.has_license || false,
    appraisal_price: details?.appraisal_price || '',
    superficie: details?.superficie || '',
    tipo: details?.tipo || '',
    uso: details?.uso || '',
    nota_simple_pdf: details?.nota_simple_pdf || '',
    sale_contract_pdf: details?.sale_contract_pdf || ''
  };

  const [localDetails, setLocalDetails] = useState(initialDetails);

  useEffect(() => {
    if (details) {
      setLocalDetails({
        land_price: details.land_price || '',
        has_license: details.has_license || false,
        appraisal_price: details.appraisal_price || '',
        superficie: details.superficie || '',
        tipo: details.tipo || '',
        uso: details.uso || '',
        nota_simple_pdf: details.nota_simple_pdf || '',
        sale_contract_pdf: details.sale_contract_pdf || ''
      });
    }
  }, [details]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    setLocalDetails((prev) => ({
      ...prev,
      [name]: updatedValue
    }));
  };

  const handleBlur = (field) => {
    onSave({ ...localDetails, [field]: localDetails[field] });
  };

  const handleFileChange = async (e, documentType) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || projectId) return;

    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('project_id', projectId);
    formData.append('document_type', documentType);

    try {
      setUploading(true);
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.finanbid.com/api/upload-document', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Error al subir el documento');
      }

      const data = await response.json();
      const documentUrl = data.documentUrl;

      // Actualiza el estado con la URL del documento y guarda en la base de datos
      setLocalDetails((prev) => {
        const updatedDetails = {
          ...prev,
          [documentType]: documentUrl
        };
        onSave(updatedDetails); // Llama a onSave para actualizar la base de datos con la nueva URL
        return updatedDetails;
      });
    } catch (error) {
      console.error('Error al subir el documento:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-md font-semibold mb-4">{t('landPurchaseLoanDetails.title')}</h3>

      {/* Precio del suelo y Precio de tasación en la misma línea */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="text-sm font-medium text-gray-600 mb-1">{t('landPurchaseLoanDetails.landPrice')}</label>
          <input
            type="number"
            name="land_price"
            value={localDetails.land_price}
            onChange={handleChange}
            onBlur={() => handleBlur('land_price')}
            className="w-full border rounded p-2 mb-3"
          />
        </div>

        <div>
          <label className="text-sm font-medium text-gray-600 mb-1">{t('landPurchaseLoanDetails.appraisalPrice')}</label>
          <input
            type="number"
            name="appraisal_price"
            value={localDetails.appraisal_price}
            onChange={handleChange}
            onBlur={() => handleBlur('appraisal_price')}
            className="w-full border rounded p-2 mb-3"
          />
        </div>
      </div>

      {/* Superficie y Licencia en la misma línea */}
      <div className="grid grid-cols-2 gap-4 mb-3">
        <div>
          <label className="text-sm font-medium text-gray-600 mb-1">{t('landPurchaseLoanDetails.superficie')}</label>
          <input
            type="number"
            name="superficie"
            value={localDetails.superficie}
            onChange={handleChange}
            onBlur={() => handleBlur('superficie')}
            className="w-full border rounded p-2"
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="has_license"
            checked={localDetails.has_license}
            onChange={(e) => {
              const newValue = e.target.checked;
              setLocalDetails((prev) => ({
                ...prev,
                has_license: newValue
              }));
              onSave({ ...localDetails, has_license: newValue });
            }}
            className="mr-2"
          />
          <label className="text-sm font-medium text-gray-600">{t('landPurchaseLoanDetails.hasLicense')}</label>
        </div>
      </div>

      <label className="text-sm font-medium text-gray-600 mb-1">{t('landPurchaseLoanDetails.tipo')}</label>
      <select
        name="tipo"
        value={localDetails.tipo}
        onChange={(e) => {
          handleChange(e);
          onSave({ ...localDetails, tipo: e.target.value });
        }}
        className="w-full border rounded p-2 mb-3"
      >
        <option value="Urbano">Urbano</option>
        <option value="Urbanizable">Urbanizable</option>
        <option value="Rustico">Rústico</option>
        <option value="Otros">Otros</option>
      </select>

      {/* Inputs para subir documentos */}
      <div className="flex flex-col space-y-4 mt-4">
        <div className="flex items-center space-x-2" style={{ width: '70%' }}>
          <label className="bg-secondary text-white px-4 py-2 rounded cursor-pointer flex-grow text-center">
            {localDetails.nota_simple_pdf ? t('Cambiar Nota Simple') : t('Añadir Nota Simple')}
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'nota_simple_pdf')}
              className="hidden"
              disabled={uploading}
            />
          </label>
          {localDetails.nota_simple_pdf && <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />}
        </div>

        <div className="flex items-center space-x-2" style={{ width: '70%' }}>
          <label className="bg-secondary text-white px-4 py-2 rounded cursor-pointer flex-grow text-center">
            {localDetails.sale_contract_pdf ? t('Cambiar Contrato') : t('Añadir Contrato')}
            <input
              type="file"
              onChange={(e) => handleFileChange(e, 'sale_contract_pdf')}
              className="hidden"
              disabled={uploading}
            />
          </label>
          {localDetails.sale_contract_pdf && <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />}
        </div>
      </div>
    </div>
  );
};

export default LandPurchaseLoanDetails;
