import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';

const SearchOperations = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        amountRequested: [0, 250000000],
        selectedCountries: [],
        selectedProvinces: [],
        ltv: 0,
        sector: '',
        subsector: '',
        productType: '',
    });
    const [sectors, setSectors] = useState([]);
    const [subsectors, setSubsectors] = useState([]);
    const [products, setProducts] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Fetch sectors
        axios
            .get('https://api.finanbid.com/api/sectors')
            .then((res) => setSectors(res.data))
            .catch((err) => console.error('Error fetching sectors:', err));
    }, []);

    useEffect(() => {
        // Fetch products and subsectors when sector changes
        if (filters.sector) {
            axios
                .get(`https://api.finanbid.com/api/products?sector_id=${filters.sector}`)
                .then((res) => setProducts(res.data))
                .catch((err) => console.error('Error fetching products:', err));

            axios
                .get(`https://api.finanbid.com/api/subsectors?sector_id=${filters.sector}`)
                .then((res) => setSubsectors(res.data))
                .catch((err) => console.error('Error fetching subsectors:', err));
        } else {
            setProducts([]);
            setSubsectors([]);
        }
    }, [filters.sector]);

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => {
            if (field === 'amountRequested') {
                // Actualizar ambos valores del rango
                return {
                    ...prevFilters,
                    amountRequested: value,
                };
            }

            // Para otros campos
            return {
                ...prevFilters,
                [field]: value,
            };
        });
    };

    const fetchProjects = async () => {
        try {
            // Crear un objeto de filtros sin campos vacíos
            const activeFilters = {};
            for (const key in filters) {
                if (
                    filters[key] !== '' && // Excluir cadenas vacías
                    filters[key] !== null &&
                    filters[key].length !== 0 // Excluir arrays vacíos
                ) {
                    activeFilters[key] = filters[key];
                }
            }

            const response = await axios.post('https://api.finanbid.com/api/projects/search', activeFilters);
            setProjects(response.data); // Actualizar el estado con los proyectos filtrados
        } catch (error) {
            console.error('Error al buscar proyectos:', error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            {/* Formulario de búsqueda */}
            <div className="bg-white shadow-md rounded p-6 mb-4">
                <h2 className="text-lg font-semibold mb-4">{t('search.title')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* Filtro de cantidad requerida */}
                    <div>
                        <label className="block text-sm font-medium mb-1">{t('search.amountRequested')}</label>

                        {/* Input "Desde" */}
                        <div className="flex flex-col gap-2">
                            <div>
                                <label className="block text-sm text-gray-700">{t('search.amountFrom')}</label>
                                <input
                                    type="text" // Tipo texto para manejar el formato de moneda
                                    value={filters.amountRequested[0].toLocaleString('es-ES')} // Mostrar número con separación de miles
                                    onFocus={(e) => {
                                        // Quitar el formato al enfocar
                                        e.target.value = filters.amountRequested[0];
                                    }}
                                    onBlur={(e) => {
                                        // Aplicar el formato al salir del input
                                        const value = parseInt(e.target.value.replace(/[^\d]/g, '')) || 0; // Convertir a número
                                        handleFilterChange('amountRequested', [value, filters.amountRequested[1]]);
                                    }}
                                    onChange={(e) => {
                                        // Actualizar estado sin formateo durante la escritura
                                        const value = parseInt(e.target.value.replace(/[^\d]/g, '')) || 0;
                                        handleFilterChange('amountRequested', [value, filters.amountRequested[1]]);
                                    }}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>

                            {/* Input "Hasta" */}
                            <div>
                                <label className="block text-sm text-gray-700">{t('search.amountTo')}</label>
                                <input
                                    type="text" // Tipo texto para manejar el formato de moneda
                                    value={filters.amountRequested[1].toLocaleString('es-ES')} // Mostrar número con separación de miles
                                    onFocus={(e) => {
                                        // Quitar el formato al enfocar
                                        e.target.value = filters.amountRequested[1];
                                    }}
                                    onBlur={(e) => {
                                        // Aplicar el formato al salir del input
                                        const value = parseInt(e.target.value.replace(/[^\d]/g, '')) || 0; // Convertir a número
                                        handleFilterChange('amountRequested', [filters.amountRequested[0], value]);
                                    }}
                                    onChange={(e) => {
                                        // Actualizar estado sin formateo durante la escritura
                                        const value = parseInt(e.target.value.replace(/[^\d]/g, '')) || 0;
                                        handleFilterChange('amountRequested', [filters.amountRequested[0], value]);
                                    }}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                        </div>

                        {/* Texto informativo opcional */}
                        <div className="text-sm text-gray-500 mt-2">
                            {t('search.amountRange', {
                                min: new Intl.NumberFormat('es-ES', {
                                    style: 'currency',
                                    currency: 'EUR',
                                }).format(filters.amountRequested[0]),
                                max: new Intl.NumberFormat('es-ES', {
                                    style: 'currency',
                                    currency: 'EUR',
                                }).format(filters.amountRequested[1]),
                            })}
                        </div>
                    </div>

                    {/* Filtro de país */}
                    <div>
                        <label className="block text-sm font-medium mb-1">{t('search.country')}</label>
                        <select
                            multiple
                            value={filters.selectedCountries}
                            onChange={(e) =>
                                handleFilterChange(
                                    'selectedCountries',
                                    Array.from(e.target.selectedOptions, (option) => option.value)
                                )
                            }
                            className="w-full border rounded"
                        >
                            <option value="España">España</option>
                            <option value="Francia">Francia</option>
                            <option value="Alemania">Alemania</option>
                            <option value="México">México</option>
                            <option value="Argentina">Argentina</option>
                        </select>
                    </div>

                    {/* Filtro de provincias */}
                    {filters.selectedCountries.includes('España') && (
                        <div>
                            <label className="block text-sm font-medium mb-1">{t('search.province')}</label>
                            <select
                                multiple
                                value={filters.selectedProvinces}
                                onChange={(e) =>
                                    handleFilterChange(
                                        'selectedProvinces',
                                        Array.from(e.target.selectedOptions, (option) => option.value)
                                    )
                                }
                                className="w-full border rounded"
                            >
                                <option value="Albacete">Albacete</option>
                                <option value="Alicante">Alicante</option>
                                <option value="Almería">Almería</option>
                                <option value="Asturias">Asturias</option>
                                <option value="Ávila">Ávila</option>
                                <option value="Badajoz">Badajoz</option>
                                <option value="Barcelona">Barcelona</option>
                                <option value="Burgos">Burgos</option>
                                <option value="Cáceres">Cáceres</option>
                                <option value="Cádiz">Cádiz</option>
                                <option value="Cantabria">Cantabria</option>
                                <option value="Castellón">Castellón</option>
                                <option value="Ciudad Real">Ciudad Real</option>
                                <option value="Córdoba">Córdoba</option>
                                <option value="Cuenca">Cuenca</option>
                                <option value="Girona">Girona</option>
                                <option value="Granada">Granada</option>
                                <option value="Guadalajara">Guadalajara</option>
                                <option value="Guipúzcoa">Guipúzcoa</option>
                                <option value="Huelva">Huelva</option>
                                <option value="Huesca">Huesca</option>
                                <option value="Islas Baleares">Islas Baleares</option>
                                <option value="Jaén">Jaén</option>
                                <option value="La Coruña">La Coruña</option>
                                <option value="La Rioja">La Rioja</option>
                                <option value="Las Palmas">Las Palmas</option>
                                <option value="León">León</option>
                                <option value="Lleida">Lleida</option>
                                <option value="Lugo">Lugo</option>
                                <option value="Madrid">Madrid</option>
                                <option value="Málaga">Málaga</option>
                                <option value="Murcia">Murcia</option>
                                <option value="Navarra">Navarra</option>
                                <option value="Ourense">Ourense</option>
                                <option value="Palencia">Palencia</option>
                                <option value="Pontevedra">Pontevedra</option>
                                <option value="Salamanca">Salamanca</option>
                                <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
                                <option value="Segovia">Segovia</option>
                                <option value="Sevilla">Sevilla</option>
                                <option value="Soria">Soria</option>
                                <option value="Tarragona">Tarragona</option>
                                <option value="Teruel">Teruel</option>
                                <option value="Toledo">Toledo</option>
                                <option value="Valencia">Valencia</option>
                                <option value="Valladolid">Valladolid</option>
                                <option value="Vizcaya">Vizcaya</option>
                                <option value="Zamora">Zamora</option>
                                <option value="Zaragoza">Zaragoza</option>
                                <option value="Ceuta">Ceuta</option>
                                <option value="Melilla">Melilla</option>
                                {/* Añade más provincias aquí */}
                            </select>
                        </div>
                    )}

                    {/* Filtro de LTV */}
                    <div>
                        <label className="block text-sm font-medium mb-1">{t('search.ltv')}</label>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            value={filters.ltv}
                            onChange={(e) => handleFilterChange('ltv', parseInt(e.target.value) || 0)}
                            className="w-full border rounded px-2 py-1"
                        />
                        <div className="text-sm text-gray-500 mt-1">
                            {t('search.ltvMax', { max: filters.ltv })}
                        </div>
                    </div>

                    {/* Filtro de sector */}
                    <div>
                        <label className="block text-sm font-medium mb-1">{t('search.sector')}</label>
                        <select
                            value={filters.sector}
                            onChange={(e) => handleFilterChange('sector', e.target.value)}
                            className="w-full border rounded"
                        >
                            <option value="">{t('projectUpload.step2.selectSector')}</option>
                            {sectors.map((sector) => (
                                <option key={sector.id} value={sector.id}>
                                    {sector.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Filtro de subsector */}
                    {filters.sector && (
                        <div>
                            <label className="block text-sm font-medium mb-1">{t('search.subsector')}</label>
                            <select
                                value={filters.subsector}
                                onChange={(e) => handleFilterChange('subsector', e.target.value)}
                                className="w-full border rounded"
                            >
                                <option value="">{t('search.selectSubsector')}</option>
                                {subsectors.map((subsector) => (
                                    <option key={subsector.id} value={subsector.id}>
                                        {subsector.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Filtro de tipo de producto */}
                    <div>
                        <label className="block text-sm font-medium mb-1">{t('search.productType')}</label>
                        <select
                            value={filters.productType}
                            onChange={(e) => handleFilterChange('productType', e.target.value)}
                            className="w-full border rounded"
                            disabled={!filters.sector}
                        >
                            <option value="">{t('projectUpload.step2.selectProductType')}</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Botón de buscar */}
                <button
                    onClick={fetchProjects}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                    {t('search.searchButton')}
                </button>
            </div>

            {/* Resultados */}
            <h3 className="text-lg font-semibold mb-4">{t('search.resultsTitle')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {projects.map((project) => (
                    <ProjectCard
                        key={project.id}
                        project={{
                            ...project,
                            sector: sectors.find((sec) => sec.id === project.sector_id)?.name || t('search.unknownSector'),
                            subsector: subsectors.find((sub) => sub.id === project.subsector_id)?.name || t('search.unknownSubsector'),
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default SearchOperations;
