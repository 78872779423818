import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import socket from '../../utils/socket';

const Inbox = () => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const token = localStorage.getItem('token');
  // Cargar todas las conversaciones
  useEffect(() => {
    const userId = parseInt(localStorage.getItem('userId'), 10);

    const fetchConversations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://api.finanbid.com/api/conversations?userId=${userId}`,
          {
              headers: {
                  Authorization: `Bearer ${token}`, // Agregamos el token como Bearer en los headers
              },
          }
      );
        const updatedConversations = response.data.map((conv) => ({
          ...conv,
          isUnread: false, // Inicializamos el estado de lectura
          messages: [], // Inicializamos los mensajes como un array vacío
        }));
        setConversations(updatedConversations);

        // Suscribirse a eventos de WebSocket para cada conversación
        updatedConversations.forEach((conversation) => {
          socket.on(`conversation_${conversation.conversation_id}`, (newMessage) => {
            console.log(`Nuevo mensaje en conversación ${conversation.conversation_id}:`, newMessage);

            // Actualizar la conversación específica
            setConversations((prev) =>
              prev.map((conv) =>
                conv.conversation_id === newMessage.conversation_id
                  ? {
                    ...conv,
                    isUnread: true, // Marca como no leída
                    last_message: newMessage.message, // Actualiza el último mensaje
                  }
                  : conv
              )
            );

            // Si la conversación está seleccionada, actualizar los mensajes
            setSelectedConversation((prev) => {
              if (prev?.conversation_id === newMessage.conversation_id) {
                return {
                  ...prev,
                  messages: [...(prev.messages || []), newMessage],
                };
              }
              return prev;
            });
          });
        });
      } catch (error) {
        console.error('Error al cargar las conversaciones:', error);
      }
    };

    if (userId) {
      socket.emit('registerUser', userId);
      fetchConversations();
    }

    // Limpiar eventos al desmontar
    return () => {
      conversations.forEach((conversation) => {
        socket.off(`conversation_${conversation.conversation_id}`);
      });
    };
  }, [selectedConversation]);





  // Manejar la selección de una conversación
  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://api.finanbid.com/api/messages?conversationId=${conversation.conversation_id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`, // Agregamos el token como Bearer en los headers
          },
      }
      );

      const messages = response.data;

      // Procesar mensajes para incluir los archivos adjuntos
      const processedMessages = messages.map((msg) => ({
        ...msg,
        fileUrl: msg.file_url || null, // Asignar la URL del archivo si existe
        fileName: msg.file_name || null, // Asignar el nombre del archivo si existe
      }));

      // Extraer recipient_id y recipient_role del primer mensaje donde el usuario no sea el remitente
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const recipientMessage = processedMessages.find((msg) => msg.sender_id !== userId);

      setSelectedConversation((prev) => ({
        ...prev,
        messages: processedMessages,
        recipient_id: recipientMessage?.sender_id || conversation.recipient_id,
        recipient_role: recipientMessage?.sender_role || conversation.recipient_role,
      }));

      // Marca la conversación como leída
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversation_id === conversation.conversation_id
            ? { ...conv, isUnread: false }
            : conv
        )
      );
    } catch (error) {
      console.error('Error al cargar los mensajes:', error);
    }
  };



  // Manejar el envío de un nuevo mensaje
  const handleSendMessage = async () => {
    if (!newMessage.trim() && !attachment) return; // No enviar si no hay mensaje ni archivo

    const userId = parseInt(localStorage.getItem('userId'), 10);
    const senderRole = localStorage.getItem('role');

    const { conversation_id, recipient_id, recipient_role } = selectedConversation || {};

    if (!conversation_id || !recipient_id || !recipient_role) {
      console.error('Faltan conversation_id, recipient_id o recipient_role', {
        conversation_id,
        recipient_id,
        recipient_role,
      });
      return;
    }

    try {
      // Crear un formulario para manejar el archivo y el mensaje
      const formData = new FormData();
      formData.append('conversationId', conversation_id);
      formData.append('senderId', userId);
      formData.append('senderRole', senderRole);
      formData.append('recipientId', recipient_id);
      formData.append('recipientRole', recipient_role);
      if (newMessage.trim()) formData.append('message', newMessage);
      if (attachment) formData.append('attachment', attachment);

      // Enviar el mensaje y el archivo a la API
      const response = await axios.post('https://api.finanbid.com/api/messages', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Actualizar la conversación seleccionada
      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), response.data],
      }));

      // Resetear el estado del mensaje y el archivo
      setNewMessage('');
      setAttachment(null);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.response?.data || error.message);
    }
  };



  return (
    <div className="flex h-full">
      {/* Lista de conversaciones */}
      <div className="w-1/3 bg-gray-100 p-4 border-r overflow-y-auto relative">
        <h2 className="text-xl font-bold mb-4">{t('inbox.conversations')}</h2>
        <div>
          {conversations.length > 0 ? (
            conversations.map((conversation) => (
              <div
                key={conversation.conversation_id}
                onClick={() => handleSelectConversation(conversation)}
                className={`p-3 mb-2 cursor-pointer rounded-lg ${selectedConversation?.conversation_id === conversation.conversation_id
                  ? 'bg-blue-200'
                  : conversation.isUnread
                    ? 'bg-yellow-100' // Color especial para no leídos
                    : 'bg-white hover:bg-gray-200'
                  }`}
              >
                <p className="font-semibold">
                  {conversation.user_id === 8 ? 'Finanbid' : `#Fondo ${conversation.user_id}`}
                </p>
                <p className="text-sm text-gray-600">
                  {conversation.last_message || t('inbox.noMessages')}
                </p>
              </div>
            ))
          ) : (
            <p className="text-gray-600">{t('inbox.noConversations')}</p>
          )}
        </div>
      </div>

      {/* Detalle de la conversación */}
      <div className="w-2/3 p-4 flex flex-col justify-between">
        {selectedConversation ? (
          <>
            <div className="flex-1 overflow-y-auto mb-4">
              <h2 className="text-xl font-bold mb-4">
                {t('inbox.conversationWith')} {selectedConversation.recipient_id === 8 ? 'Finanbid' : `#Fondo ${selectedConversation.recipient_id}`}
              </h2>
              {selectedConversation?.messages?.map((msg, index) => (
                <div
                  key={index}
                  className={`mb-2 flex ${msg.sender_id === parseInt(localStorage.getItem('userId'), 10)
                    ? 'justify-end'
                    : 'justify-start'
                    }`}
                >
                  <div
                    className={`inline-block p-3 rounded-lg ${msg.sender_id === parseInt(localStorage.getItem('userId'), 10)
                      ? 'bg-green-200 text-right'
                      : 'bg-gray-200 text-left'
                      }`}
                  >
                    <p className="text-sm">{msg.message}</p>
                    {/* Manejo de adjuntos */}
                    {msg.file_url && (
                      <a
                        href={msg.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {t('inbox.viewAttachment')}
                      </a>
                    )}
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(msg.fecha_creacion).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* Campo para subir archivos */}
            <div className="flex items-center mb-2">
              <input
                type="file"
                onChange={(e) => setAttachment(e.target.files[0])}
                className="p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Input para escribir mensajes */}
            <div className="flex items-center space-x-2">
  <textarea
    value={newMessage}
    onChange={(e) => setNewMessage(e.target.value)}
    placeholder={t('inbox.typeMessage')}
    rows="4"
    className="flex-1 p-2 border border-gray-300 rounded-lg resize-none"
  />
  <button
    onClick={handleSendMessage}
    className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex-shrink-0"
  >
    {t('inbox.send')}
  </button>
</div>
          </>
        ) : (
          <p className="text-gray-600">{t('inbox.selectConversation')}</p>
        )}
      </div>

    </div>
  );
};



export default Inbox;
