import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AdminOffers = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingOffers, setLoadingOffers] = useState(false);

  useEffect(() => {
    // Cargar todos los proyectos en estado Visible
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.finanbid.com/api/admin/projectsvisible', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoadingProjects(false);
      }
    };

    fetchProjects();
  }, []);

  const fetchOffers = async (projectId) => {
    setLoadingOffers(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api.finanbid.com/api/offers?project_id=${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffers(response.data);
    } catch (error) {
      console.error('Error fetching offers:', error);
    } finally {
      setLoadingOffers(false);
    }
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate); // Crear objeto de fecha a partir del string ISO
    const day = String(date.getDate()).padStart(2, '0'); // Extraer día y asegurarse de que tenga 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Extraer mes (0-indexado) y asegurarse de que tenga 2 dígitos
    const year = String(date.getFullYear()).slice(-2); // Extraer los últimos 2 dígitos del año
    return `${day}/${month}/${year}`; // Formatear como dd/mm/yy
  };
  const toggleProject = (projectId) => {
    if (selectedProjectId === projectId) {
      setSelectedProjectId(null);
      setOffers([]);
    } else {
      setSelectedProjectId(projectId);
      fetchOffers(projectId);
    }
  };

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold mb-6">{t('adminProjects.title')}</h1>
      {loadingProjects ? (
        <p>{t('adminProjects.loading')}</p>
      ) : projects.length === 0 ? (
        <p>{t('adminProjects.noProjects')}</p>
      ) : (
        <div className="space-y-4">
          {projects.map((project) => (
            <div key={project.id} className="bg-white rounded-lg shadow-md">
              {/* Cabecera del proyecto */}
              <div className="flex justify-between items-center p-4 border-b border-gray-200">
                <div>
                  <p className="font-medium text-gray-800">{t('adminProjects.id')}: {project.id}</p>
                  <p className="text-sm text-gray-600">{t('adminProjects.productType')}: {project.product_name}</p>
                  <p className="text-sm text-gray-600">{t('adminProjects.requestedAmount')}: €{project.amount_requested}</p>
                </div>
                <div>
                <p className="font-medium text-gray-800">{t('adminProjects.offers')}: {project.offers}</p>
                </div>    
                <button
                  onClick={() => toggleProject(project.id)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <FontAwesomeIcon icon={selectedProjectId === project.id ? faChevronDown : faChevronRight} />
                </button>
              </div>

              {/* Listado de ofertas */}
              {selectedProjectId === project.id && (
                <div className="p-4">
                  {loadingOffers ? (
                    <p>{t('adminProjects.loadingOffers')}</p>
                  ) : offers.length === 0 ? (
                    <p>{t('adminProjects.noOffers')}</p>
                  ) : (
                    <div>
                      <h3 className="font-semibold mb-4">{t('adminProjects.offersReceived')}</h3>
                      <div className="grid grid-cols-7 gap-4 font-semibold text-sm bg-gray-200 p-2 rounded">
                      <span>{t('offers.headers.fecha')}</span>
                        <span>{t('offers.headers.id')}</span>
                        <span>{t('offers.headers.interestRate')}</span>
                        <span>{t('offers.headers.openingFee')}</span>
                        <span>{t('offers.headers.repayment')}</span>
                        <span>{t('offers.headers.accepted')}</span>
                      </div>
                      {offers.map((offer) => (
                        <div
                          key={offer.id}
                          className="grid grid-cols-7 gap-4 p-2 border-b border-gray-200 text-sm items-center"
                        >
                          <span>{formatDate(offer.offer_date)}</span>
                          <span>{offer.id}</span>
                          <span>{offer.interest_rate_monthly}%</span>
                          <span>{offer.opening_fee}%</span>
                          <span>{offer.interest_repayment}</span>
                          <span>
  {offer.accepted ? (
    <FontAwesomeIcon icon={faCheck} className="mr-2 text-green" />
  ) : (
    <FontAwesomeIcon icon={faClock} className="mr-2 text-orange" />
  )}
</span>
                          <button
                            onClick={() => window.open(offer.offer_document_url, '_blank')}
                            className="text-blue-500 hover:underline"
                          >
                            {t('buttons.viewDocument')}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminOffers;
