import React from 'react';

const TerminosYCondiciones = () => {
  return (
    <div className="bg-back text-white w-11/12 md:w-8/12 lg:w-7/12 mx-auto mt-24 p-6 rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Términos y Condiciones para Fondos</h1>
      <p className="mb-4">
        <strong>Última actualización:</strong> Noviembre 2024
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">1. Objeto del Acuerdo</h2>
      <p className="mb-4">
        El presente acuerdo regula los términos y condiciones bajo los cuales el fondo, familiy office y cualquier empresa que preste servicios de financiación, en adelante "El Fondo", accede a la plataforma Finanbid. Al aceptar estos términos, El Fondo se compromete a respetar las condiciones establecidas para el acceso y uso de la información confidencial proporcionada en relación con los proyectos de financiación presentados en la plataforma.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">2. Confidencialidad de la Información</h2>
      <p className="mb-4">
        El Fondo reconoce que la información contenida en la plataforma es confidencial y está protegida por la legislación aplicable, incluida la Ley Orgánica de Protección de Datos y Garantía de los Derechos Digitales (LOPDGDD) y el Reglamento General de Protección de Datos (RGPD).
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>
          El Fondo se compromete a no divulgar, transferir o compartir la información recibida a terceros sin la previa autorización expresa y por escrito de Finanbid.
        </li>
        <li>
          La información confidencial será utilizada exclusivamente para el análisis y evaluación de los proyectos presentados en la plataforma.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">3. Registro y Almacenamiento de Datos</h2>
      <p className="mb-4">
        Finanbid procederá a registrar y almacenar la dirección IP y los datos del Fondo que accede a la información confidencial de los proyectos. Este registro será utilizado para garantizar la trazabilidad y seguridad del acceso, así como para fines legales, en caso de incumplimiento de los presentes términos y condiciones.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">4. Cesión y Protección de Datos</h2>
      <p className="mb-4">
        Los datos personales y de proyecto que se comparten en Finanbid han sido facilitados por los promotores con su consentimiento expreso para ser traspasados exclusivamente a los fondos interesados en analizarlos. El Fondo asume la obligación de:
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>Mantener la confidencialidad de dichos datos en todo momento.</li>
        <li>
          Cumplir con la normativa de protección de datos aplicable, comprometiéndose a no utilizar la información para fines distintos a los establecidos en este acuerdo.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">5. Reconocimiento de Captación y Éxito de la Operación</h2>
      <p className="mb-4">
        El Fondo acepta que, al visualizar y evaluar un proyecto en la plataforma, cualquier operación que concluya con éxito como resultado de dicho análisis será atribuida a la captación realizada por Finanbid.
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>El Fondo reconoce los servicios de intermediación realizados por Finanbid.</li>
        <li>
          El Fondo se compromete a cumplir con las obligaciones económicas establecidas en el acuerdo broker-fondo previamente firmado.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">6. Exclusión de Responsabilidad de Finanbid</h2>
      <p className="mb-4">
        Finanbid no se hace responsable de la veracidad, exactitud o integridad de la información proporcionada por los promotores en la plataforma. El Fondo asume la responsabilidad de realizar las verificaciones necesarias para evaluar la viabilidad de cada proyecto.
      </p>
      <p className="mb-4">
        Además, Finanbid no será responsable de ningún daño, pérdida o perjuicio derivado del uso de la información proporcionada en la plataforma.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">7. Aceptación de los Términos y Condiciones</h2>
      <p className="mb-4">
        Al aceptar estos términos y condiciones, El Fondo reconoce haber leído, comprendido y aceptado íntegramente las cláusulas aquí contenidas, quedando vinculado a las mismas. La aceptación se registra de forma automática al hacer clic en el botón correspondiente, quedando almacenada la fecha, hora y dirección IP del Fondo como prueba legal de su consentimiento.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">8. Ley Aplicable y Jurisdicción</h2>
      <p className="mb-4">
        El presente acuerdo se rige por la legislación española. Para cualquier controversia que surja en relación con los presentes términos, ambas partes acuerdan someterse a los juzgados y tribunales de Malaga.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">9. Modificación de los Términos y Condiciones</h2>
      <p className="mb-4">
        Finanbid se reserva el derecho a modificar estos términos y condiciones en cualquier momento, notificando los cambios a través de la plataforma. El uso continuado de la misma por parte del Fondo tras la notificación implicará la aceptación de las modificaciones.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">10. Contacto</h2>
      <p className="mb-4">
        Para cualquier consulta relativa a estos términos y condiciones, El Fondo puede contactar con Finanbid a través del correo electrónico{' '}
        <a href="mailto:admin@finanbid.com" className="text-blue-500 underline">
          admin@finanbid.com
        </a>.
      </p>
    </div>
  );
};

export default TerminosYCondiciones;
