import React from "react";

const FloatingLabelInput = ({ label, value, onChange, onBlur, type = "text", className = "" }) => {
  return (
    <div className={`relative w-full ${className}`}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder=" "
        className="block w-full px-2.5 pb-2 pt-4 text-sm text-gray-900 bg-transparent border rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 peer"
      />
      <label
  className="relative text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 z-1 origin-[0] bg-white px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2.5 peer-focus:scale-75 peer-focus:-translate-y-6 left-0"
>
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
