import React, { useState } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectImages = ({ images, onDeleteImage, onImageUpload, projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageType, setImageType] = useState("imagen");
  const [imageToDelete, setImageToDelete] = useState(null);
  
  const categorizedImages = {
    imagen: images.filter((img) => (img.image_type || "").toLowerCase() === "imagen"),
    render: images.filter((img) => (img.image_type || "").toLowerCase() === "render"),
    localizacion: images.filter((img) => (img.image_type || "").toLowerCase() === "localizacion"),
    comparable: images.filter((img) => (img.image_type || "").toLowerCase() === "comparable"),
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    setSelectedFile(null);
    setImageType("imagen");
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Selecciona una imagen para subir.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("project_id", projectId);
    formData.append("image_type", imageType);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch('https://api.finanbid.com/api/upload-image', {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error al subir la imagen");
      }

      const data = await response.json();
      onImageUpload(data); // Actualiza las imágenes en el estado padre
      closeUploadModal();
    } catch (error) {
      console.error("Error al subir la imagen:", error);
    }
  };

  const handleDeleteClick = (imageId) => {
    setImageToDelete(imageId);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!imageToDelete) return;

    try {
      await onDeleteImage(imageToDelete);
      setImageToDelete(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error al eliminar la imagen:", error);
    }
  };

  const cancelDelete = () => {
    setImageToDelete(null);
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      {["imagen", "render", "localizacion", "comparable"].map((type) => (
  <div key={type} className="mb-6">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-lg font-semibold capitalize">{type}</h3>
      <button
        onClick={openUploadModal}
        className="text-blue-500 bg-white rounded-full p-2 hover:bg-blue-500 hover:text-white"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
    <Slider {...sliderSettings} key={type}>
      {categorizedImages[type].map((image) => (
        <div key={image.id} className="relative p-2">
          <img
            src={image.image_url}
            alt={`${type}`}
            className="rounded-lg w-full h-[200px] object-cover cursor-pointer hover:opacity-90"
            onClick={() => window.open(image.image_url, "_blank")}
          />
          <button
            className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-1 hover:bg-red-500 hover:text-white"
            onClick={() => handleDeleteClick(image.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}
    </Slider>
  </div>
))}

      {/* Modal de Confirmación de Eliminación */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Confirmar eliminación</h3>
            <p>¿Estás seguro de que deseas eliminar esta imagen?</p>
            <div className="mt-4 flex justify-end">
              <button onClick={cancelDelete} className="text-gray-600 mr-4">
                Cancelar
              </button>
              <button onClick={confirmDelete} className="bg-red-500 text-white px-4 py-2 rounded">
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Subida de Imagen */}
      {uploadModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Subir Imagen</h3>
            <input type="file" onChange={handleFileChange} className="mb-4" />
            <label className="block text-sm font-medium mb-2">Tipo de Imagen</label>
            <select
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            >
              <option value="imagen">Imagen</option>
              <option value="render">Render</option>
              <option value="localizacion">Localización</option>
              <option value="comparable">Comparable</option>
            </select>
            <div className="mt-4 flex justify-end">
              <button onClick={closeUploadModal} className="text-gray-600 mr-4">
                Cancelar
              </button>
              <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded">
                Subir
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectImages;
