import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Renovables = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">

      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            
            {/* Etiqueta PROYECTOS RENOVABLES */}
            <span className="rounded-full uppercase bg-verde px-3 py-0.5 text-sm font-semibold leading-5 text-white mb-4 inline-block">
              {t('renovables.hero.tag')}
            </span>

            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('renovables.hero.title')}
            </h1>

            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('renovables.hero.subtitle')}
            </p>

            <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('renovables.hero.description')}
            </p>

            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="/subir-proyecto" className="inline-flex items-center justify-center px-5 py-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-opacity-90 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700">
                {t('renovables.hero.uploadProject')}
              </a>

              <a href="/contact" className="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                {t('renovables.hero.contactUs')}
              </a>
            </div>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/herorenovables.png" alt={t('renovables.hero.imgAlt')} />
          </div>
        </div>
      </section>

      {/* Tipos de Financiación para Proyectos Renovables */}
      <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('renovables.products.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            
            {/* Financiación 1 */}
            <div className="p-6 bg-verde rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('renovables.products.product1.title')}
              </h3>
              <p className="text-gray-300">
                {t('renovables.products.product1.description')}
              </p>
            </div>
            
            {/* Financiación 2 */}
            <div className="p-6 bg-verde rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('renovables.products.product2.title')}
              </h3>
              <p className="text-gray-300">
                {t('renovables.products.product2.description')}
              </p>
            </div>

            {/* Bonos Verdes */}
            <div className="p-6 bg-verde rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('renovables.products.product3.title')}
              </h3>
              <p className="text-gray-300">
                {t('renovables.products.product3.description')}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Características de la Financiación Verde */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('renovables.features.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {['feature1', 'feature2', 'feature3', 'feature4'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`renovables.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`renovables.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            {['feature5', 'feature6', 'feature7', 'feature8'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`renovables.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`renovables.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="py-16 bg-secondary text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            {t('renovables.cta.title')}
          </h2>
          <p className="text-lg mb-8">
            {t('renovables.cta.description')}
          </p>
          <button 
            onClick={() => navigate('/subir-proyecto')} 
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
          >
            {t('renovables.cta.button')}
          </button>
        </div>
      </section>

    </div>
  );
};

export default Renovables;
