import React from 'react';

const AdminHome = () => {
  return (
    <div>
      <h2 className="text-xl font-bold text-gray-700">Perfil de Usuario</h2>
      {/* Aquí puedes mostrar y editar la información del perfil del usuario */}
    </div>
  );
}

export default AdminHome;
