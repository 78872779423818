import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Carousel } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faBan, faFileAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons';

const ProjectDetails = () => {
    const { t } = useTranslation();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [projectDetails, setProjectDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isFollowing, setIsFollowing] = useState(false);
    const [hasOffer, setHasOffer] = useState(false);
    const [fundId, setFundId] = useState(null);
    const [showFollowupPopup, setShowFollowupPopup] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        const fetchFundId = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token no encontrado');
                }

                const response = await axios.get(`https://api.finanbid.com/api/fund-users/fund`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFundId(response.data.fund_id);
            } catch (error) {
                if (error.response && error.response.status === 401 || error.response.status === 403) {
                    // Token inválido, redirige a login con la redirección de vuelta aquí
                    navigate(`/login?redirect=/fund/dashboard/project/${projectId}`);
                } else {
                    console.error('Error al obtener el fundId:', error);
                }
            }
        };

        const fetchProjectDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token no encontrado');
                }

                const projectResponse = await axios.get(`https://api.finanbid.com/api/admin/projects/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setProjectDetails(projectResponse.data);

                if (fundId) {
                    // Comprobar seguimiento
                    const followupResponse = await axios.get(`https://api.finanbid.com/api/followups/${fundId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const isFollowingProject = followupResponse.data.some(
                        (followup) => followup.project_id === parseInt(projectId)
                    );
                    setIsFollowing(isFollowingProject);

                    // Comprobar oferta
                    const offerResponse = await axios.get(
                        `https://api.finanbid.com/api/offers?project_id=${projectId}&fund_id=${fundId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setHasOffer(offerResponse.data.length > 0);
                }
            } catch (error) {
                if (error.response && error.response.status === 401 || error.response.status === 403) {
                    // Token inválido, redirige a login con la redirección de vuelta aquí
                    navigate(`/login?redirect=/fund/dashboard/project/${projectId}`);
                } else {
                    console.error('Error al obtener los detalles del proyecto:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchFundId();
    }, [projectId, navigate]);

    useEffect(() => {
        if (fundId) {
            const fetchProjectDetails = async () => {
                try {
                    const token = localStorage.getItem('token');
                    if (!token) {
                        throw new Error('Token no encontrado');
                    }

                    const projectResponse = await axios.get(`https://api.finanbid.com/api/admin/projects/${projectId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    setProjectDetails(projectResponse.data);

                    // Comprobar seguimiento
                    const followupResponse = await axios.get(`https://api.finanbid.com/api/followups/${fundId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const isFollowingProject = followupResponse.data.some(
                        (followup) => followup.project_id === parseInt(projectId)
                    );
                    setIsFollowing(isFollowingProject);

                    // Comprobar oferta
                    const offerResponse = await axios.get(
                        `https://api.finanbid.com/api/offers?project_id=${projectId}&fund_id=${fundId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setHasOffer(offerResponse.data.length > 0);
                } catch (error) {
                    if (error.response && error.response.status === 401 || error.response.status === 403) {
                        // Token inválido, redirige a login con la redirección de vuelta aquí
                        navigate(`/login?redirect=/fund/dashboard/project/${projectId}`);
                    } else {
                        console.error('Error al obtener los detalles del proyecto:', error);
                    }
                } finally {
                    setLoading(false);
                }
            };

            fetchProjectDetails();
        }
    }, [fundId, projectId, navigate]);

    const handleFollowup = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.finanbid.com/api/followups`,
                { project_id: projectId, fund_id: fundId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsFollowing(true);
            setShowFollowupPopup(false);
        } catch (error) {
            console.error('Error al crear el seguimiento:', error);
        }
    };

    const handleRemoveFollowup = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://api.finanbid.com/api/followups/${projectId}/${fundId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsFollowing(false);
        } catch (error) {
            console.error('Error al eliminar el seguimiento:', error);
        }
    };

    if (loading) {
        return <div className="text-center text-gray-500">{t('loading.projectDetails')}</div>;
    }

    if (!projectDetails) {
        return <div className="text-center text-red-500">{t('error.projectDetails')}</div>;
    }

    const { project, images, loanDetails, collaterals, documents, assets, legalEntity } = projectDetails;

    const getLtvColor = (ltv) => {
        if (ltv === null || ltv === undefined) return 'bg-gray-100';
        if (ltv <= 60) return 'bg-green-100';
        if (ltv <= 75) return 'bg-orange-100';
        if (ltv >= 90) return 'bg-red-100';
        return 'bg-red-100';
    };

    const productTypeName = t(`projectUpload.products.${project.product_type || 'unknown'}`, {
        defaultValue: 'Producto desconocido',
    });

    return (
        <div className="p-4">
            {/* Título y botones */}
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">
                    {productTypeName || 'Proyecto sin título'} Id. {project.id || 'unknown'}
                </h1>
                <div className="flex space-x-2">
                    {isFollowing ? (
                        <button
                            onClick={handleRemoveFollowup}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            {t('followups.removeFollowup')}
                        </button>
                    ) : (
                        <button
                            onClick={() => setShowFollowupPopup(true)}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            {t('followups.addFollowup')}
                        </button>
                    )}
                    {hasOffer ? (
                        <>
                            <button className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
                                {t('offers.improveOffer')}
                            </button>
                            <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                {t('offers.removeOffer')}
                            </button>
                        </>
                    ) : (
                        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                            {t('offers.makeOffer')}
                        </button>
                    )}
                </div>
            </div>

            {/* Carrusel de Imágenes */}
            <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 mb-6">
                <Carousel>
                    {images && images.length > 0 ? (
                        images
                            .filter((image) => image.image_type === 'render' || image.image_type === 'imagen') // Filtrar imágenes por tipo
                            .map((image, index) => (
                                <img
                                    key={index}
                                    src={image.image_url}
                                    alt={`Imagen ${index + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            ))
                    ) : (
                        <img
                            src={project.url_imagen_principal || 'https://via.placeholder.com/1200'}
                            alt="Imagen principal del proyecto"
                            className="w-full h-full object-cover"
                        />
                    )}
                </Carousel>


            </div>

            {/* Información General */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="grid grid-cols-2 gap-4 group">
                    {/* Información del Proyecto */}
                    <div className="bg-white p-4 shadow-md rounded-lg">
                        <h2 className="text-lg font-semibold mb-2">Resumen</h2>
                        <p>
                            <strong>Capital Solicitado:</strong> €{project.amount_requested || 'No disponible'}
                        </p>
                        {/* Dirección con efecto blur */}
                        <p>
                            <strong>Tipo de préstamo:</strong> {productTypeName || 'No disponible'}
                        </p>
                        <p>
                            <strong>Provincia:</strong> {project.provincia || 'No disponible'}
                        </p>
                        <p className={`${!isFollowing ? 'filter blur-sm select-none' : ''} transition duration-300`}>
                            <strong>Localidad:</strong> {project.localidad || 'No disponible'}
                        </p>
                        <p className={`${!isFollowing ? 'filter blur-sm select-none' : ''} transition duration-300`}>
                            <strong>País:</strong> {project.pais || 'No disponible'}
                        </p>
                    </div>

                    {/* LTV del proyecto */}
                    <div className={`rounded-lg p-4 ${getLtvColor(loanDetails.ltv)} shadow`}>
                        <div className="flex justify-between items-center bg-gray-200 p-2 rounded-t-lg">
                            <h3 className="text-sm font-semibold">LTV</h3>
                        </div>
                        <div className="mt-4 text-center text-2xl font-semibold">
                            {loanDetails.ltv ? `${loanDetails.ltv}%` : '-'}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-4 shadow-md rounded-lg">
                    <h2 className="text-lg font-semibold mb-2">Detalles del Préstamo</h2>
                    <p><strong>Valor de Tasación:</strong> €{loanDetails?.tasacion_valor || 'No disponible'}</p>
                    <p><strong>Duración:</strong> {loanDetails?.duracion_prestamo || 'No disponible'} meses</p>
                    <p><strong>Repago:</strong> {loanDetails?.repago || 'No disponible'}</p>
                </div>
            </div>
            <div className="bg-white p-4 mb-2 shadow-md rounded-lg">
                <p className="text-lg font-semibold mb-2">Resumen del proyecto</p>
                <p className={`${!isFollowing ? 'filter blur-sm select-none' : ''} transition duration-300`}>{project.resumen || 'Proyecto sin título'}</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white p-4 mb-2 shadow-md rounded-lg">
                <div classname="px-6">
                    <p className="text-lg font-semibold mb-2">Localización</p>
                    <p className={`${!isFollowing ? 'filter blur-sm select-none' : ''} transition duration-300`}>{project.ubicacion_descripcion || 'No registrada'}</p>
                </div>
                <div className={`${!isFollowing ? 'filter blur-sm select-none pointer-events-none' : ''} transition duration-300 px-4 h-56 sm:h-64 xl:h-80 2xl:h-96 mb-6`}>
                    <Carousel>
                        {images && images.length > 0 ? (
                            images
                                .filter((image) => image.image_type === 'localizacion') // Filtrar imágenes por tipo
                                .map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.image_url}
                                        alt={`Imagen ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                ))
                        ) : (
                            <img
                                src={project.url_imagen_principal || 'https://via.placeholder.com/1200'}
                                alt="Imagen principal del proyecto"
                                className="w-full h-full object-cover"
                            />
                        )}
                    </Carousel>
                </div>
            </div>
            {/* Activos */}
            <div className="bg-white p-4 shadow-md rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-4">Activo del proyecto</h2>
                {assets.length > 0 ? (
                    assets.map((assets) => (
                        <div
                            key={assets.id}
                            className={`grid grid-cols-1 md:grid-cols-2 gap-4 border-b border-gray-200 pb-4 mb-4 ${!isFollowing ? 'filter blur-sm select-none' : ''
                                } transition duration-300`}
                        >
                            {/* Izquierda: Detalles de la garantía */}
                            <div>
                                <p>
                                    <strong>Tipo:</strong> {assets.type || 'No disponible'}
                                </p>
                                <p>
                                    <strong>Descripción:</strong> {assets.descripcion || 'No disponible'}
                                </p>


                                <p>
                                    <strong>Valor de tasación:</strong> €{assets.valor_tasacion || 'No disponible'}
                                </p>
                                <br></br>
                                <div>
                                    <h3 className="text-md font-semibold mb-2">Documentos</h3>
                                    {assets.documents && assets.documents.length > 0 ? (
                                        <ul>
                                            {assets.documents.map((doc, index) => (
                                                <li key={index} className="flex items-center mb-2">
                                                    <span className="mr-2 text-gray-700">{doc.document_type || 'Documento sin título'}</span>
                                                    {doc.document_url ? (
                                                        <a
                                                            href={doc.document_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 hover:underline"
                                                        >
                                                            <FontAwesomeIcon icon={faFileDownload} className="w-5 h-5" />
                                                        </a>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faFileAlt} className="w-5 h-5 text-gray-400" />
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-gray-500">No hay documentos asociados.</p>
                                    )}
                                </div>

                            </div>

                            {/* Derecha: Documentos asociados */}
                            <table className="table-auto w-full">
                                <tbody>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Registro de Propiedad</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.registro_propiedad || 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Referencia Catastral</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.referencia_catastral || 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Finca</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.finca || 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Tomo</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.tomo || 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Folio</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.folio || 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Alta</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.alta ? new Date(assets.alta).toLocaleDateString() : 'No disponible'}</td>
                                    </tr>
                                    <tr className="border-b border-gray-300">
                                        <td className="px-4 py-1 font-bold text-gray-700">Titular</td>
                                        <td className="px-4 py-1 bg-gray-50 text-gray-600 font-light">{assets.titular || 'No disponible'}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    ))
                ) : (
                    <p>No se encontraron garantías asociadas a este proyecto.</p>
                )}
            </div>

            <div className="bg-white p-4 shadow-md rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-4">Garantías</h2>
                {collaterals.length > 0 ? (
                    collaterals.map((collateral) => (
                        <div
                            key={collateral.id}
                            className={`grid grid-cols-1 md:grid-cols-2 gap-4 border-b border-gray-200 pb-4 mb-4 ${!isFollowing ? 'filter blur-sm select-none' : ''
                                } transition duration-300`}
                        >
                            {/* Izquierda: Detalles de la garantía */}
                            <div>
                                {Object.entries(collateral)
                                    .filter(
                                        ([key, value]) =>
                                            value !== null &&
                                            value !== "" &&
                                            ![
                                                "id",
                                                "project_id",
                                                "fecha_creacion",
                                                "fecha_actualizacion",
                                                "estado",
                                                "verification_status",
                                                "document_id",
                                                "documents"
                                            ].includes(key) // Excluir campos no deseados
                                    )
                                    .map(([key, value]) => (
                                        <p key={key}>
                                            <strong>{key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}:</strong>{" "}
                                            {typeof value === "boolean" ? (value ? "Sí" : "No") : value}
                                        </p>
                                    ))}
                            </div>

                            {/* Derecha: Documentos asociados */}
                            <div>
                                <h3 className="text-md font-semibold mb-2">Documentos</h3>
                                {collateral.documents && collateral.documents.length > 0 ? (
                                    <ul>
                                        {collateral.documents.map((doc, index) => (
                                            <li key={index} className="flex items-center mb-2">
                                                <span className="mr-2 text-gray-700">{doc.document_type || 'Documento sin título'}</span>
                                                {doc.document_url && (
                                                    <a
                                                        href={doc.document_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 hover:underline"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="w-5 h-5"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M13.828 10.172a4 4 0 015.656 5.656l-6.657 6.657a4 4 0 01-5.656 0l-6.657-6.657a4 4 0 010-5.656l6.657-6.657a4 4 0 015.656 0l6.657 6.657"
                                                            />
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M15 15l-4-4m0 0l4-4m-4 4h9"
                                                            />
                                                        </svg>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500">No hay documentos asociados.</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No se encontraron garantías asociadas a este proyecto.</p>
                )}
            </div>


            {/* Documentos */}
            <div className="bg-white p-4 shadow-md rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-4">Documentos del Proyecto</h2>
                {documents.length > 0 ? (
                    <ul>
                        {documents.map((doc) => (
                            <li key={doc.id} className={`${!isFollowing ? 'filter blur-sm select-none pointer-events-none' : ''} transition duration-300 mb-2`}>
                                <a
                                    href={doc.document_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:underline"
                                >
                                    {doc.document_type || 'Documento sin título'}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No hay documentos disponibles para este proyecto.</p>
                )}
            </div>

            {/* Entidad Legal */}
<div className="bg-white p-4 shadow-md rounded-lg space-y-6">
  <h2 className="text-lg font-semibold mb-4">Entidad Legal</h2>

  {/* Card superior: Datos de la Empresa */}
  <div className={`${!isFollowing ? 'filter blur-sm select-none pointer-events-none' : ''} transition duration-300 grid grid-cols-1 md:grid-cols-2 gap-4 p-4 border rounded-lg shadow`}>
    <div>
      <p><strong>Nombre:</strong> {legalEntity?.business_name || 'No disponible'}</p>
      <p><strong>CIF:</strong> {legalEntity?.business_cif || 'No disponible'}</p>
      <p><strong>Dirección:</strong> {legalEntity?.business_address || 'No disponible'}</p>
      <p><strong>Email de Contacto:</strong> {legalEntity?.contact_email || 'No disponible'}</p>
    </div>
    <div>
      <p><strong>Año de Fundación:</strong> {legalEntity?.year_of_incorporation || 'No disponible'}</p>
      <p><strong>Teléfono de Contacto:</strong> {legalEntity?.contact_phone || 'No disponible'}</p>
      <p><strong>Capital Social:</strong> {legalEntity?.business_capital || 'No disponible'}</p>
      
    </div>
  </div>

  {/* Bloque inferior dividido */}
  <div className={`${!isFollowing ? 'filter blur-sm select-none pointer-events-none' : ''} transition duration-300 grid grid-cols-1 md:grid-cols-2 gap-4`}>
    {/* Izquierda */}
    <div className="space-y-4">
      {/* Card Accionistas */}
      <div className="p-4 border rounded-lg shadow">
        <h3 className="text-md font-semibold mb-4">Accionistas</h3>
        {legalEntity?.shareholders?.length > 0 ? (
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Nombre</th>
                <th className="text-right py-2">Participación (%)</th>
              </tr>
            </thead>
            <tbody>
              {legalEntity.shareholders.map((shareholder) => (
                <tr key={shareholder.id} className="border-b">
                  <td className="py-2">{shareholder.shareholder_name || 'No disponible'}</td>
                  <td className="py-2 text-right">{shareholder.participation || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay accionistas disponibles.</p>
        )}
      </div>

      {/* Card Administrador */}
      <div className="p-4 border rounded-lg shadow">
        <h3 className="text-md font-semibold mb-4">Administrador</h3>
        {legalEntity?.administrators?.length > 0 ? (
          <div>
            <p><strong>Nombre:</strong> {legalEntity.administrators[0].administrator_name || 'No disponible'}</p>
            <p><strong>Dirección Fiscal:</strong> {legalEntity.administrators[0].fiscal_address || 'No disponible'}</p>
            <p><strong>Nacionalidad:</strong> {legalEntity.administrators[0].nationality || 'No disponible'}</p>
            <p><strong>Estado Civil:</strong> {legalEntity.administrators[0].marital_status || 'No disponible'}</p>
            <p><strong>Profesión:</strong> {legalEntity.administrators[0].profession || 'No disponible'}</p>
            <p><strong>Activos:</strong> {legalEntity.administrators[0].assets || 'No disponible'}</p>
            <p><strong>Deudas:</strong> {legalEntity.administrators[0].debts || 'No disponible'}</p>
          </div>
        ) : (
          <p>No hay datos del administrador disponibles.</p>
        )}
      </div>
    </div>

    {/* Derecha */}
    <div className="p-4 border rounded-lg shadow">
      <h3 className="text-md font-semibold mb-4">Documentos</h3>
      {legalEntity?.documents?.length > 0 ? (
        <table className="w-full text-sm">
          <thead>
            <tr className="border-b">
              <th className="text-left py-2">Tipo</th>
              <th className="text-center py-2">Año</th>
              <th className="text-right py-2">Acción</th>
            </tr>
          </thead>
          <tbody>
            {legalEntity.documents.map((doc) => (
              <tr key={doc.id} className="border-b">
                <td className="py-2">{doc.document_type || 'No disponible'}</td>
                <td className="py-2 text-center">{doc.year || 'No disponible'}</td>
                <td className="py-2 text-right">
                  {doc.document_pdf ? (
                    <a
                      href={doc.document_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      Ver
                    </a>
                  ) : (
                    <span className="text-gray-500">No disponible</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No hay documentos disponibles.</p>
      )}
    </div>
  </div>

  {/* Card inferior: CV Partners e Historial */}
  <div className={`${!isFollowing ? 'filter blur-sm select-none pointer-events-none' : ''} transition duration-300 grid grid-cols-1 md:grid-cols-2 gap-4 p-4 border rounded-lg shadow`}>
    <div>
      <h3 className="text-md font-semibold mb-2">CV Partners</h3>
      <p>{legalEntity?.partners_cv || 'No disponible'}</p>
    </div>
    <div>
      <h3 className="text-md font-semibold mb-2">Historial de la Empresa</h3>
      <p>{legalEntity?.promotions_history || 'No disponible'}</p>
    </div>
  </div>
</div>
            {/* Popup para confirmar seguimiento */}
            {showFollowupPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <h2 className="text-lg font-semibold mb-4">{t('followups.confirmTitle')}</h2>
                        <p className="mb-4">{t('followups.confirmMessage')}</p>
                        <div className="flex items-center mb-4">
                            <input
                                id="terms"
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                                className="mr-2"
                            />
                            <label htmlFor="terms" className="text-sm text-blue-500 hover:underline">
                                <a href="https://www.finanbid.com/terms-followups" target="_blank" rel="noopener noreferrer">
                                    {t('followups.terms')}
                                </a>
                            </label>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setShowFollowupPopup(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                            >
                                {t('common.cancel')}
                            </button>
                            <button
                                onClick={handleFollowup}
                                disabled={!termsAccepted}
                                className={`px-4 py-2 rounded ${termsAccepted ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-300 text-gray-500'
                                    }`}
                            >
                                {t('common.ok')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectDetails;
