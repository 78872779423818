import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ShareholderModal = ({ onClose, onSave }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [participation, setParticipation] = useState('');
  const [role, setRole] = useState('');
  const [type, setType] = useState('Persona Física'); // Estado para el tipo de accionista

  const handleSave = () => {
    onSave({
      shareholder_name: name,
      participation: parseFloat(participation),
      shareholder_role: role,
      type: type, // Añadir el tipo al objeto de accionista
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{t('Añadir Accionista')}</h3>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('Nombre/Razón Social')}
          className="w-full p-1 border-b mb-3"
        />
        <input
          value={participation}
          onChange={(e) => setParticipation(e.target.value)}
          placeholder={t('Porcentaje de participación')}
          className="w-full p-1 border-b mb-3"
        />
        <input
          value={role}
          onChange={(e) => setRole(e.target.value)}
          placeholder={t('Rol')}
          className="w-full p-1 border-b mb-3"
        />

        {/* Dropdown para seleccionar el tipo de accionista */}
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full p-1 border-b mb-3"
        >
          <option value="Persona Física">{t('Persona Física')}</option>
          <option value="Persona Jurídica">{t('Persona Jurídica')}</option>
        </select>

        <div className="mt-4 flex justify-end">
          <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">
            {t('Guardar')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareholderModal;
