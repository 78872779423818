import React from "react";
import { useTranslation } from "react-i18next";

const Congratulations = ({ onSendForReview, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        {t("congratulations.title")}
      </h2>
      <p className="text-gray-600 mb-6">{t("congratulations.message")}</p>
      <div className="flex justify-center space-x-4">
        <button
          onClick={onSendForReview}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
        >
          {t("congratulations.sendReviewButton")}
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition"
        >
          {t("congratulations.closeButton")}
        </button>
      </div>
    </div>
  );
};

export default Congratulations;
