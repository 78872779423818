import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Project from './ProjectNew';
import CreateProjectModal from './CreateProjectModal'; // Importa el modal de creación
import CreateProjectWithEntityModal from './CreateProjectWithEntityModal'; // Nuevo modal

const UserProjects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [referredProjects, setReferredProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState(false);
  const location = useLocation();
  const createNewProject = location.state?.createNewProject || false;

  useEffect(() => {
    if (createNewProject) {
        // Lógica para activar el formulario de crear proyecto
        setIsCreateModalOpen(true);
        // Aquí puedes actualizar un estado local para abrir el formulario
    }
}, [createNewProject]);

  useEffect(() => {
    const fetchUserInfoAndProjects = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }
  
        // Obtener información del usuario desde el token
        const userInfoResponse = await fetch('https://api.finanbid.com/api/user/info', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!userInfoResponse.ok) {
          if (userInfoResponse.status === 401 || userInfoResponse.status === 403) {
            navigate('/login');
          } else {
            console.error(t('userProjects.errorFetchingUserInfo'));
          }
          return;
        }
  
        const { userId, role } = await userInfoResponse.json();
        setIsCollaborator(role === 'colaborador');
  
        // Cargar proyectos del usuario
        const projectsResponse = await fetch('https://api.finanbid.com/api/projects', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (projectsResponse.ok) {
          const projectsData = await projectsResponse.json();
          setProjects(projectsData);
        } else {
          console.error(t('userProjects.errorLoadingProjects'));
        }
  
        // Si el usuario es colaborador, cargar proyectos referidos
        if (role === 'colaborador') {
          const referredResponse = await fetch(
            `https://api.finanbid.com/api/projects/referred/${userId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          if (referredResponse.ok) {
            const referredData = await referredResponse.json();
            setReferredProjects(referredData);
          } else {
            console.error(t('userProjects.errorLoadingReferredProjects'));
          }
        }
      } catch (error) {
        console.error(t('userProjects.errorLoadingProjects'), error);
      }
    };
  
    fetchUserInfoAndProjects();
  }, [t, navigate]);

  const handleProjectClick = (projectId, isReferred) => {
    // if (isCollaborator && isReferred) {
    //   alert(t('userProjects.cannotEditReferredProject'));
    //   return;
    // }
    setSelectedProjectId(projectId);
  };

  const handleBackToProjects = () => {
    setSelectedProjectId(null);
  };

  const getProductTypeName = (productTypeId) => {
    return t(`projectUpload.products.${productTypeId}`, { defaultValue: productTypeId });
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  if (selectedProjectId) {
    return <Project projectId={selectedProjectId} onBack={handleBackToProjects} />;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-full relative">
      <h2 className="text-2xl font-bold mb-6">{t('userProjects.title')}</h2>
      <button
        onClick={handleOpenCreateModal}
        className="absolute top-6 font-bold right-6 bg-secondary text-white rounded-lg px-4 py-2 hover:bg-back"
      >
        {t('userProjects.createNewProject')}
      </button>
  
      {/* Modal para Crear Nuevo Proyecto */}
      {isCreateModalOpen && (
        isCollaborator ? (
          <CreateProjectWithEntityModal onClose={handleCloseCreateModal} />
        ) : (
          <CreateProjectModal onClose={handleCloseCreateModal} />
        )
      )}
  
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.id')}</th>
              <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.date')}</th>
              {isCollaborator && (
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.userName')}</th>
              )}
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.productType')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.requestedAmount')}</th>
              <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.followUps')}</th>
              <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.offersReceived')}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">{t('userProjects.status')}</th>
            </tr>
          </thead>
          <tbody>
            {[...projects, ...referredProjects].map((project) => (
              <tr
                key={project.id}
                className={`cursor-pointer hover:bg-gray-100 ${referredProjects.includes(project) ? 'bg-secondarysuave' : ''}`}
                onClick={() => handleProjectClick(project.id, referredProjects.includes(project))}
              >
                <td className="px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center">
                    {project.image && (
                      <img
                        src={project.image}
                        alt="Project"
                        className="h-10 w-10 rounded-full mr-4 object-cover"
                      />
                    )}
                    <span className="text-sm font-medium text-gray-700">{project.id}</span>
                  </div>
                </td>
                <td className="px-4 py-4 border-b border-gray-200">
                  <span className="text-sm text-gray-700">
                    {new Date(project.created_at).toLocaleDateString()}
                  </span>
                </td>
                {isCollaborator && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <span className="text-sm text-gray-700">{project.user_name || t('userProjects.unknownUser')}</span>
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <span className="text-sm text-gray-700">{getProductTypeName(project.product_type)}</span>
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <span className="text-sm text-gray-700">€{project.amount_requested.toLocaleString()}</span>
                </td>
                <td className="px-4 py-4 border-b border-gray-200">
                  <span className="text-sm text-gray-700">0</span>
                </td>
                <td className="px-4 py-4 border-b border-gray-200">
                  <span className="text-sm text-gray-700">0</span>
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <span
                      className={`text-sm font-semibold ${project.status === 'pending'
                          ? 'text-yellow-500'
                          : project.status === 'Visible'
                            ? 'text-green-500'
                            : project.status === 'En Revisión'
                            ? 'text-orange-500'
                            : project.status === 'En Modificación'
                            ? 'text-primary'
                            : 'text-red-800'
                        }`}
                    >
                      {project.status === 'pending' && <i className="fas fa-clock mr-2"></i>}
                      {project.status === 'Visible' && <i className="fas fa-check-circle mr-2"></i>}
                      {project.status === 'rejected' && <i className="fas fa-times-circle mr-2"></i>}
                      {t(`userProjects.statuses.${project.status}`)}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

export default UserProjects;
