import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Fondos = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    fundName: '',
    phone: '',
    email: ''
  });
  const formRef = useRef(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envía un correo electrónico a andresalen@gmail.com con los datos del formulario
      const response = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'andresalen@gmail.com',
          subject: 'Nuevo Fondo Interesado',
          html: `
            <p>Se ha suscrito un nuevo fondo a la lista de espera con los siguientes datos:</p>
            <ul>
              <li><strong>Nombre del fondo:</strong> ${formData.fundName}</li>
              <li><strong>Teléfono:</strong> ${formData.phone}</li>
              <li><strong>Email:</strong> ${formData.email}</li>
            </ul>
          `,
        }),
      });

      if (response.ok) {
        alert(t('fondos.successMessage'));
        setFormData({
          fundName: '',
          phone: '',
          email: ''
        });
      } else {
        alert(t('fondos.errorMessage'));
      }
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      alert(t('fondos.errorMessage'));
    }
  };

  return (
    <section className="bg-back text-white py-16 lg:mt-10 mt-24" ref={formRef}>
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="lg:flex lg:items-start lg:space-x-12">
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <h2 className="text-4xl font-bold mb-6">{t('fondos.title')}</h2>
            <p className="text-lg text-gray-300 mb-12">
              {t('fondos.description')}
            </p>
          </div>

          <div className="lg:w-1/2 bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-semibold mb-4">{t('fondos.formTitle')}</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg mb-2" htmlFor="fundName">{t('fondos.fundNameLabel')}</label>
                <input
                  type="text"
                  id="fundName"
                  name="fundName"
                  value={formData.fundName}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-400 rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg mb-2" htmlFor="phone">{t('fondos.phoneLabel')}</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-400 rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg mb-2" htmlFor="email">{t('fondos.emailLabel')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-400 rounded text-black"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-secondary text-white py-2 px-4 rounded-md hover:bg-opacity-90"
              >
                {t('fondos.submitButton')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Fondos;
