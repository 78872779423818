import React, { useState } from "react";
import FloatingLabelInput from "./FloatingLabelInput";

const DocumentUpload = ({ onClose, setDocuments, legalEntityId }) => {
    const [documentType, setDocumentType] = useState("");
    const [year, setYear] = useState("");
    const [documentFile, setDocumentFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        setDocumentFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!documentType || !year || !documentFile) {
            alert("Por favor, completa todos los campos antes de subir el documento.");
            return;
        }

        const formData = new FormData();
        formData.append("document_type", documentType);
        formData.append("year", year);
        formData.append("document", documentFile);
        formData.append("entity_id", legalEntityId);

        setLoading(true);

        try {
            const response = await fetch("https://api.finanbid.com/api/upload-entity-document", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error al subir el documento");
            }

            const newDocument = await response.json();
            setDocuments((prevDocs) => [...prevDocs, newDocument]); // Actualiza el listado en Step7
            onClose(); // Cierra el modal después de subir
        } catch (error) {
            console.error("Error al subir el documento:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[600]">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg">
                <h3 className="text-lg font-semibold mb-4">Subir Documento</h3>
                <div className="space-y-4">
                    <FloatingLabelInput
                        label="Tipo de Documento"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                        placeholder="Tipo de Documento"
                    />
                    <FloatingLabelInput
                        label="Año"
                        type="number"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        placeholder="Año"
                    />
                    <div>
                        <label className="block text-sm font-medium mb-2">Archivo</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="p-2 border rounded w-full"
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-6">
                    <button
                        className={`px-4 py-2 rounded ${
                            loading ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
                        }`}
                        onClick={handleUpload}
                        disabled={loading}
                    >
                        {loading ? "Subiendo..." : "Subir"}
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-300 rounded ml-2"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentUpload;
