// socket.js
import { io } from 'socket.io-client';

const socket = io('https://api.finanbid.com', {
  path: '/socket.io',
  secure: true,
  reconnection: true, // Intenta reconectar automáticamente
  reconnectionAttempts: 5, // Intenta 5 veces antes de fallar
});

export default socket;
