import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Newsletter = () => {
  const { t } = useTranslation(); // Hook de traducción
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(''); // Nuevo estado para mostrar mensajes al usuario

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Enviar el correo con el template al usuario que se suscribe
      const userResponse = await fetch('https://api.finanbid.com/api/projects/send-email-with-template', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: email,
          templateId: 2, // ID del template para la suscripción al newsletter
          variables: {} // Si se necesitan variables, se pueden añadir aquí
        }),
      });

      if (!userResponse.ok) throw new Error('Error al enviar el correo al usuario');

      // Enviar el correo de notificación a andresalen@gmail.com sin template
      const adminResponse = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'andresalen@gmail.com',
          subject: 'Nuevo suscriptor al newsletter',
          html: `<p>El usuario con el correo ${email} se ha suscrito al newsletter.</p>`
        }),
      });

      if (adminResponse.ok) {
        setMessage('¡Te has suscrito exitosamente al newsletter!');
      } else {
        throw new Error('Error al enviar el correo de notificación');
      }
    } catch (error) {
      console.error('Error al suscribirse al newsletter:', error);
      setMessage('Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente.');
    }

    setIsSubmitting(false);
    setEmail(''); // Opcional: limpiar el campo de email
  };

  return (
    <div className="px-3 py-6 bg-black text-white">
      <div className="mx-auto flex flex-col items-center justify-between gap-6 sm:flex-row max-w-screen-lg">
        <div className="sm:w-7/12">
          <div className="text-3xl font-bold">
            {t('newsletter.subscribeTitle')}{' '}
            <span className="text-secondary">
              {t('newsletter.newsletter')}
            </span>
          </div>
          <p className="mt-3 text-gray-300">
            {t('newsletter.description')}
          </p>
        </div>
        <div className="w-full sm:w-5/12">
          <form
            onSubmit={handleSubmit}
            className="flex rounded-full bg-slate-800 px-4 py-2 focus-within:ring-2 focus-within:ring-secondary hover:ring-2 hover:ring-secondary"
          >
            <input
              type="email"
              placeholder={t('newsletter.placeholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full appearance-none border-0 bg-slate-800 focus:outline-none text-white"
              required
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="ml-2 shrink-0 rounded-full bg-secondary px-3 py-1 text-sm font-medium hover:from-sky-700 hover:to-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600/50"
            >
              {isSubmitting ? 'Enviando...' : t('newsletter.subscribeButton')}
            </button>
          </form>
          {message && <p className="mt-2 text-green-500">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
