import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BridgeLoanDetails = ({ projectId, onSave, onNextStep }) => {
  const { t } = useTranslation();
  const [purpose, setPurpose] = useState('');
  const [repaymentMethod, setRepaymentMethod] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [ownFunds, setOwnFunds] = useState('');
  const [notaSimplePdfUrl, setNotaSimplePdfUrl] = useState('');
  const [businessPlanFileUrl, setBusinessPlanFileUrl] = useState('');
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [renders, setRenders] = useState([]);

  const handleDocumentUpload = async (file, documentType) => {
    const formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('document_type', documentType);
    formData.append('document', file);

    try {
      setUploading(true);
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.finanbid.com/api/upload-document', {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data.documentUrl;
      } else {
        const errorData = await response.json();
        setError(t('errors.uploadDocument') + errorData.message);
        return '';
      }
    } catch (error) {
      setError(t('errors.connectionError'));
      return '';
    } finally {
      setUploading(false);
    }
  };

  const handleNotaSimpleChange = async (e) => {
    const file = e.target.files[0];
    const url = await handleDocumentUpload(file, 'nota_simple_pdf');
    setNotaSimplePdfUrl(url);
  };

  const handleBusinessPlanChange = async (e) => {
    const file = e.target.files[0];
    const url = await handleDocumentUpload(file, 'business_plan');
    setBusinessPlanFileUrl(url);
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'imagen');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedImages.push(data.imageUrl);
        } else {
          setError('Error al subir la imagen');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
      }
    }

    setImages([...images, ...uploadedImages]);
  };

  const handleRenderUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedRenders = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('project_id', projectId);
      formData.append('image', file);
      formData.append('image_type', 'render');

      try {
        const response = await fetch('https://api.finanbid.com/api/upload-image', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedRenders.push(data.imageUrl);
        } else {
          setError('Error al subir el render');
        }
      } catch (error) {
        setError('Error en la conexión al servidor');
      }
    }

    setRenders([...renders, ...uploadedRenders]);
  };

  const handleSaveDetails = async () => {
    if (!purpose || !repaymentMethod || !loanTerm || !ownFunds) {
      setError(t('errors.fillRequiredFields'));
      return;
    }

    // Save bridge loan details and loan request details
    await saveBridgeLoanDetails();
    await saveLoanRequestDetails();

    if (onSave) onSave();
    if (onNextStep) onNextStep();
  };

  return (
    <div className="flex space-x-4 bg-back">
      {/* Panel izquierdo para subir imágenes */}
      <div className="w-1/2">
        <h3 className="text-lg font-semibold mb-4">{t('bridgeLoanDetails.uploadImages')}</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {images.map((image, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={image}
                alt={`Proyecto ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'imagen')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>

        <h3 className="text-lg font-semibold mb-4 mt-8">{t('bridgeLoanDetails.uploadRenders')}</h3>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleRenderUpload}
          className="mb-4"
        />
        <div className="grid grid-cols-2 gap-4">
          {renders.map((render, index) => (
            <div key={index} className="relative w-24 h-24">
              <img
                src={render}
                alt={`Render ${index}`}
                className="w-full h-full object-cover rounded-md"
              />
              <button
                onClick={() => handleImageDelete(index, 'render')}
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full p-1"
              >
                ❌
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Panel derecho con el formulario */}
      <div className="w-1/2 mx-auto">
        <h3 className="text-2xl font-bold mb-4">{t('bridgeLoanDetails.title')}</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.purpose')}</label>
          <textarea
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
            rows="4"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.notaSimple')}</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleNotaSimpleChange}
            className="mt-2"
            disabled={uploading}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.businessPlan')}</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleBusinessPlanChange}
            className="mt-2"
            disabled={uploading}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.repaymentMethod')}</label>
          <input
            type="text"
            value={repaymentMethod}
            onChange={(e) => setRepaymentMethod(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.loanTerm')}</label>
          <input
            type="text"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">{t('bridgeLoanDetails.ownFunds')}</label>
          <input
            type="number"
            value={ownFunds}
            onChange={(e) => setOwnFunds(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-black"
          />
        </div>

        <button
          onClick={handleSaveDetails}
          className="w-full bg-primary text-white py-2 rounded-md hover:bg-opacity-90"
          disabled={uploading}
        >
            {t('bridgeLoanDetails.saveButton')}
        </button>
      </div>
    </div>
  );
};

export default BridgeLoanDetails;
