import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrestamoFlipping = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">
      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:pt-28">
          <div className="place-self-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('prestamoFlipping.hero.title')}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoFlipping.hero.subtitle')}
            </p>
            <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoFlipping.hero.description')}
            </p>
            <button
              onClick={() => navigate('/subir-proyecto')}
              className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
            >
              {t('prestamoFlipping.hero.button')}
            </button>
          </div>
        </div>
      </section>

      {/* Principales Usos del Préstamo Flipping */}
      <section className="px-4 py-16 mx-auto sm:max-w-xl bg-gray-200 mb-12 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 rounded-xl shadow-lg transform transition-transform hover:translate-y-[-10px]">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-sky-500 text-white">
              {t('prestamoFlipping.uses.tag')}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-back sm:text-4xl md:mx-auto">
            {t('prestamoFlipping.uses.title')}
          </h2>
          <p className="text-base text-secondary md:text-lg">
            {t('prestamoFlipping.uses.subtitle')}
          </p>
        </div>

        <div className="grid max-w-md gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
          {/* Section for quick renovations */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-back">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoFlipping.uses.renovation.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.renovation.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.renovation.description2')}
              </p>
            </div>
          </div>
          {/* Section for purchase and resell */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-back">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoFlipping.uses.purchaseResell.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.purchaseResell.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.purchaseResell.description2')}
              </p>
            </div>
          </div>
          {/* Section for adding value */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-back">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoFlipping.uses.valueAddition.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.valueAddition.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoFlipping.uses.valueAddition.description2')}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Características del Préstamo Flipping */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('prestamoFlipping.features.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {['feature1', 'feature2', 'feature3'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`prestamoFlipping.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`prestamoFlipping.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            {t('flipping.cta.title')}
          </h2>
          <p className="text-lg mb-8">
            {t('flipping.cta.description')}
          </p>
          <button
            onClick={() => navigate('/subir-proyecto')}
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
          >
            {t('flipping.cta.button')}
          </button>
        </div>
      </section>
    </div>
  );
};

export default PrestamoFlipping;
