import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DeveloperLoanDetails = ({ data, onUpdateField }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ ...data });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setFormData({ ...data });
  }, [data]);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleBlur = async (field, value) => {
    if (!formData.project_id) {
      console.error('Project ID is undefined.');
      return;
    }

    if (onUpdateField) {
      await onUpdateField(field, value);
    } else {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.finanbid.com/api/admin/projects/${formData.project_id}/developer_loan/field`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ field, value }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error en la API: ${errorText}`);
        }

        setFormData((prevData) => ({ ...prevData, [field]: value }));
      } catch (error) {
        console.error(`Error al actualizar el campo ${field}:`, error);
      }
    }
  };

  const propertyTypes = [
    'Unifamiliar',
    'Adosado',
    'Chalet',
    'Villa',
    'Piso',
    'Duplex',
    'Local Comercial',
    'Suelo',
    'Nave industrial',
    'Edificio de viviendas',
    'Edificio comercial',
    'Otros',
  ];

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">{t('developerLoanDetails.title')}</h2>
        <FontAwesomeIcon
          icon={expanded ? faChevronDown : faChevronRight}
          className="ml-4 cursor-pointer"
          onClick={toggleAccordion}
        />
      </div>

      {expanded && (
        <>
          {/* Project ID - Read-only */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.project_id')}</label>
            <p className="text-gray-800 text-sm">{formData.project_id}</p>
          </div>

          {/* Preventas */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.preventas')}</label>
            <textarea
              value={formData.preventas}
              onChange={(e) => handleChange('preventas', e.target.value)}
              onBlur={() => handleBlur('preventas', formData.preventas)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Arquitecto */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.arquitecto')}</label>
            <textarea
              value={formData.arquitecto}
              onChange={(e) => handleChange('arquitecto', e.target.value)}
              onBlur={() => handleBlur('arquitecto', formData.arquitecto)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Comercializadora */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.comercializadora')}</label>
            <textarea
              value={formData.comercializadora}
              onChange={(e) => handleChange('comercializadora', e.target.value)}
              onBlur={() => handleBlur('comercializadora', formData.comercializadora)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Purpose */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.purpose')}</label>
            <textarea
              value={formData.purpose}
              onChange={(e) => handleChange('purpose', e.target.value)}
              onBlur={() => handleBlur('purpose', formData.purpose)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>

          {/* Tipo de Inmueble */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.tipo_inmueble')}</label>
            <select
              value={formData.tipo_inmueble}
              onChange={(e) => handleChange('tipo_inmueble', e.target.value)}
              onBlur={() => handleBlur('tipo_inmueble', formData.tipo_inmueble)}
              className="w-full border rounded-sm p-2 text-sm"
            >
              {propertyTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Coste Activo */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.coste_activo')}</label>
            <input
              type="number"
              value={formData.coste_activo || ''}
              onChange={(e) => handleChange('coste_activo', e.target.value)}
              onBlur={() => handleBlur('coste_activo', formData.coste_activo)}
              className="w-full border rounded-sm p-2 text-sm"
            />
          </div>

          {/* Coste Obra */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.coste_obra')}</label>
            <input
              type="number"
              value={formData.coste_obra || ''}
              onChange={(e) => handleChange('coste_obra', e.target.value)}
              onBlur={() => handleBlur('coste_obra', formData.coste_obra)}
              className="w-full border rounded-sm p-2 text-sm"
            />
          </div>

          {/* Otros Costes */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.otros_costes')}</label>
            <input
              type="number"
              value={formData.otros_costes || ''}
              onChange={(e) => handleChange('otros_costes', e.target.value)}
              onBlur={() => handleBlur('otros_costes', formData.otros_costes)}
              className="w-full border rounded-sm p-2 text-sm"
            />
          </div>

          {/* Estudio de Mercado */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">{t('developerLoanDetails.estudio_mercado')}</label>
            <textarea
              value={formData.estudio_mercado}
              onChange={(e) => handleChange('estudio_mercado', e.target.value)}
              onBlur={() => handleBlur('estudio_mercado', formData.estudio_mercado)}
              className="w-full border rounded-sm p-2 text-sm"
              rows="4"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DeveloperLoanDetails;

