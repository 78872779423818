import React, { useState } from 'react';
import StepTermsAndConditions from '../StepTermsAndConditions';
import Step2ProjectDetails from '../Step2ProjectDetails';
import Step3Details from '../Step3Details';

const CreateProjectModal = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [projectId, setProjectId] = useState(null);

    // Función para manejar el avance al siguiente paso
    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    // Función para manejar el retroceso al paso anterior
    const previousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    // Guardar la aceptación de términos y condiciones
    const saveTermsAcceptance = () => {
        setFormData((prevData) => ({
            ...prevData,
            termsAccepted: true,
        }));
    };

    // Guardar el ID del proyecto cuando se crea en el backend
    const saveProjectId = (id) => {
        setProjectId(id);
    };

    // Renderizar el paso actual basado en el valor de `currentStep`
    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <StepTermsAndConditions
                        nextStep={nextStep}
                        saveTermsAcceptance={saveTermsAcceptance}
                    />
                );
            case 2:
                return (
                    <Step2ProjectDetails
                        onNextStep={nextStep}
                        onPreviousStep={previousStep}
                        formData={formData}
                        saveProjectId={saveProjectId}
                    />
                );
            case 3:
                return (
                    <Step3Details
                        projectId={projectId}
                        nextStep={nextStep}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 bg-back text-white">
        <div className="bg-back rounded-lg p-6 w-full max-w-5xl lg:w-4/5 relative">
            <button
                onClick={onClose}
                className="absolute top-4 right-4 text-white bg-gray-600 hover:bg-gray-800 rounded-full w-8 h-8 flex justify-center items-center"
            >
                <span className="text-lg font-bold">×</span>
            </button>
            {renderStep()}
        </div>
    </div>
    );
};

export default CreateProjectModal;
