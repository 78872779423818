import React from 'react';
import { useTranslation } from 'react-i18next';


const HeroFondos = () => {
  const { t } = useTranslation();

  return (
    <section
      className="bg-white"
      style={{
        backgroundImage: 'url("/fondo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="url(#gradientHeroFondos)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="gradientHeroFondos">
                <stop stopColor="#3B82F6" />
                <stop offset="1" stopColor="#10B981" />
              </radialGradient>
            </defs>
          </svg>

          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('heroFondos.title')}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {t('heroFondos.description1')}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {t('heroFondos.description2')}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              
              <a href="#" className="text-sm font-semibold leading-6 text-white">
                {t('heroFondos.moreInfoButton')} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>

          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="/fondos1.png"
              alt={t('heroFondos.imageAlt')}
              width="1824"
              height="1080"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroFondos;
