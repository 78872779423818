import React, { useState } from 'react';

const CTA = () => {
  const [phone, setPhone] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSendPhoneNumber = async () => {
    if (!phone) {
      setError('Por favor, introduce un número de teléfono válido.');
      return;
    }

    try {
      const response = await fetch('https://api.finanbid.com/api/projects/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'admin@finanbid.com',
          subject: 'Solicitud de llamada',
          html: `<p><strong>Número de teléfono:</strong> ${phone}</p>`,
        }),
      });

      if (response.ok) {
        setPhone('');
        setIsSubmitted(true);
        setError('');
      } else {
        console.error('Error al enviar el número de teléfono.');
        setError('Hubo un error al enviar tu solicitud. Intenta de nuevo más tarde.');
      }
    } catch (error) {
      console.error('Error al enviar el número de teléfono:', error);
      setError('Hubo un error al enviar tu solicitud. Intenta de nuevo más tarde.');
    }
  };

  return (
    <div className="bg-gray-900 py-6 sm:py-24 lg:py-12">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block">¿Hablamos?</h2>
          <p className="inline sm:block text-2xl lg:inline xl:block">
            Déjanos tu teléfono y te llamamos.
          </p>
        </div>
        <form
          className="w-full max-w-md lg:col-span-5 lg:pt-2"
          onSubmit={(e) => {
            e.preventDefault();
            handleSendPhoneNumber();
          }}
        >
          <div className="flex gap-x-4">
            <label htmlFor="phone" className="sr-only">
              Teléfono
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
              placeholder="Introduce tu teléfono"
            />
            <button
              type="submit"
              className="flex rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondarysuave focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
            >
              Enviar
            </button>
          </div>
          {isSubmitted && (
            <p className="mt-4 text-sm leading-6 text-green-500">
              ¡Gracias! Nos pondremos en contacto contigo pronto.
            </p>
          )}
          {error && (
            <p className="mt-4 text-sm leading-6 text-red-500">{error}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default CTA;
