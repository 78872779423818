import React from 'react';
import { useTranslation } from 'react-i18next';

const CallToAction = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-4xl text-white font-extrabold tracking-tight text-neutral-900 sm:text-4xl">
        <span className="block text-white">{t('cta.ready')}</span>
        <span className="block bg-primary bg-clip-text text-transparent">{t('cta.uploadNow')}</span>
      </h2>
      <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
        <a
          href="https://www.finanbid.com/subir-proyecto"
          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
        >
          {t('cta.buttonText')}
        </a>
      </div>
    </div>
  );
};

export default CallToAction;

